import React, { useState } from 'react';
import styles from 'styles/components/shared/warning-bar.module.scss';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icons/Icon';
import closeButtonIcon from 'assets/img/close-icon-white.png';

export enum WarningLevel {
  IMPORTANT = 'important',
  WARNING = 'warning',
}

type Props = {
  message: string;
  level: WarningLevel;
  isClosable: boolean;
  isGlobal?: boolean;
  link?: string;
};

function WarningBar({ message, isGlobal = false, level, link, isClosable }: Props) {
  const { t } = useTranslation('navigation');
  const [isDisplayed, setIsDisplayed] = useState(true);

  const { bar, globalBar, closeIcon, importantBar, warningBar, barLink } = styles;

  return isDisplayed ? (
    <div className={`${bar} ${isGlobal ? globalBar : ''} ${level === 'important' ? importantBar : warningBar}`}>
      <Icon name="warning" />
      <span>
        {t('importantMessage')}
        {message}
        {link && (
          <a className={barLink} href={link} target="_blank" rel="noopener noreferrer">
            {`> ${t('clickHere')}`}
          </a>
        )}
        {isClosable && (
          <img className={closeIcon} src={closeButtonIcon} onClick={() => setIsDisplayed(false)} alt="Close icon"></img>
        )}
      </span>
    </div>
  ) : null;
}

export default WarningBar;
