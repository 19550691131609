import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'styles/domain/calendar/contest-section.module.scss';
import ContestSectionHeader from 'domain/calendar/components/contest/ContestSectionHeader';
import ContestSectionItem from 'domain/calendar/components/contest/ContestSectionItem';
import { MeetEvent } from 'types/calendar';
import useDeleteEvent from 'domain/calendar/hooks/useDeleteEvent';
import { useShowConfirmationModal } from 'hooks/useShowConfirmationModal';

type Props = {
  events: MeetEvent[];
};

export default function ContestSection({ events }: Props) {
  const { t } = useTranslation('calendar');
  const [isCollapsed, setCollapse] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);
  const { showConfirmationModal } = useShowConfirmationModal();
  const { mutate } = useDeleteEvent();

  const onShowDeleteConfirmation = (id: string) => {
    showConfirmationModal({
      message: t('deleteContestConfirmationMessage', { ns: 'forms' }),
      onSubmit: () => mutate(id),
      submitText: t('delete', { ns: 'common' }),
    });
  };

  // Need hack to re-initialize track index when events get changed in parent (switching region)
  useEffect(() => {
    setTrackIndex(0);
  }, [events]);

  const { contest, collapsed, slider, track } = styles;

  return events.length > 0 ? (
    <>
      <div className={`${contest} ${isCollapsed ? collapsed : ''}`}>
        <ContestSectionHeader
          isCollapsed={isCollapsed}
          setCollapse={setCollapse}
          trackIndex={trackIndex}
          onSlideClick={setTrackIndex}
          max={events?.length ? events.length - 1 : 0}
        />
        <div className={slider}>
          <div className={track} style={{ transform: `translateX(-${100 * trackIndex}%)` }}>
            {events?.map((contestEvent, i) => (
              <ContestSectionItem
                key={i}
                contestEvent={contestEvent}
                onDeleteContest={onShowDeleteConfirmation}
              ></ContestSectionItem>
            ))}
          </div>
        </div>
      </div>
    </>
  ) : null;
}
