import { callApi } from 'utils/httpClient';

export type BidActionEdit = {
  id: string;
  concurrencyId: string;
  email: string;
};

const gcServiceUrl = `${process.env.REACT_APP_GC_SERVICE_URL}`;

async function editBidAction({ id, concurrencyId, email }: BidActionEdit) {
  return callApi('put', gcServiceUrl, `bidAction/${id}`, { concurrencyId, configuration: email });
}

export default editBidAction;
