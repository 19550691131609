import { callApi } from 'utils/httpClient';
import { equisoftTypeSchema } from 'types/equisoftType';

const gcServiceUrl = `${process.env.REACT_APP_GC_SERVICE_URL}`;

async function getEquisoftType() {
    const { data } = await callApi('get', gcServiceUrl, '/equisoft/type');
    return data ? equisoftTypeSchema.parse(data) : data;
}

export default getEquisoftType;
