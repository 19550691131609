import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useGetRecentDocuments from 'domain/favorites/hooks/useGetRecentDocuments';
import useGetFavoriteDocuments from 'domain/favorites/hooks/useGetFavoriteDocuments';
import styles from 'styles/components/layout/favoritesBar/favorites-bar.module.scss';
import DocumentsGroup from 'components/layouts/favoritesSidebar/DocumentsGroup';

function FavoritesMenu() {
  const { t } = useTranslation('navigation');
  const { data: recentDocuments, isLoading: isRecentDocumentsLoading } = useGetRecentDocuments();
  const { data: favoriteDocuments, isLoading: isFavoriteDocumentsLoading } = useGetFavoriteDocuments();

  return (
    <div className={styles.documentGroups}>
      <DocumentsGroup
        title={t('favorites')}
        documents={favoriteDocuments}
        type={'favorite'}
        noDocumentsLabel={t('noFavorites')}
        isLoading={isFavoriteDocumentsLoading}
      />
      <DocumentsGroup
        title={t('recentlyConsulted')}
        documents={recentDocuments}
        type={'recent'}
        noDocumentsLabel={t('noRecentlyConsulted')}
        isLoading={isRecentDocumentsLoading}
      />
    </div>
  );
}

export default FavoritesMenu;
