import { RouterLink } from 'components/navigation';
import mainLogo from 'assets/img/main-icon.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'styles/components/layout/sidebar/sidebar-header.module.scss';

function NavigationSidebarHeader() {
  const { t } = useTranslation('common');

  const { header, title } = styles;

  return (
    <RouterLink to={'/'}>
      <div className={header}>
        <img src={mainLogo} alt="GC logo"></img>
        <h2 className={title}>{t('appName')}</h2>
      </div>
    </RouterLink>
  );
}

export default NavigationSidebarHeader;
