import React from 'react';
import styles from 'styles/components/inputs/text-area-input.module.scss';
import { ErrorMessage } from 'components/typography/ErrorMessage';

type TextAreaInputProps = {
  label: string;
  errorMessage?: string;
} & React.InputHTMLAttributes<HTMLTextAreaElement>;

const TextAreaInput = React.forwardRef<HTMLTextAreaElement, TextAreaInputProps>(
  ({ label, errorMessage, ...props }, ref) => {
    return (
      <div className={styles.wrapper}>
        <label className={styles.label}>{label}</label>
        <textarea className={styles.input} ref={ref} {...props} />
        <ErrorMessage errorMessage={errorMessage} />
      </div>
    );
  },
);

TextAreaInput.displayName = 'TextInput';

export type { TextAreaInputProps };
export default TextAreaInput;
