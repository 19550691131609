import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'styles/components/shared/item-grid.module.scss';
import titleStyles from 'styles/components/pages.module.scss';
import ItemCard from 'components/shared/ItemCard';
import { Outlet } from 'react-router-dom';
import companyItems from 'domain/company/data/companyItems';

function MyCompanyPage() {
  const { t } = useTranslation('company');

  const { title, titleNoBorder } = titleStyles;
  const { grid } = styles;

  return (
    <>
      <h1 className={`${title} ${titleNoBorder}`}>{t('myCompany')}</h1>
      <div className={grid}>
        {companyItems?.map((tool, i) => (
          <ItemCard key={i} item={tool} />
        ))}
      </div>
      <Outlet />
    </>
  );
}

export default MyCompanyPage;
