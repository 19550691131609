import { z } from 'zod';
import { Errors } from 'types/errors';

export enum PresenceTypeEnum {
  IN_PERSON = 'inPerson',
  HYBRID = 'hybrid',
  ONLINE = 'online',
}

export const meetSchema = z.object({
  publicId: z.string().uuid().optional(),
  concurrencyId: z.string().optional(),
  scheduledStartDatetimeUtc: z.string(),
  scheduledEndDatetimeUtc: z.string(),
  isVisible: z.boolean().optional(),
  isWholeDay: z.boolean().optional(),
  title: z.string(),
  content: z.string(),
  eventType: z.string(),
  imageUrl: z.string(),
  externalInformationUrl: z.string().url(Errors.INVALID).optional().or(z.literal('')),
  presenceType: z.nativeEnum(PresenceTypeEnum),
  venueId: z.string().uuid(),
  businessUnitIds: z.array(z.string().uuid()),
});

export type MeetEvent = z.infer<typeof meetSchema>;

const eventCategorySchema = z.object({ value: z.string(), label: z.string(), publicId: z.string().uuid() });

const presenceTypeSchema = z.object({
  value: z.nativeEnum(PresenceTypeEnum),
  label: z.string(),
});

const eventTypeSchema = z.object({
  value: z.string(),
  label: z.string(),
});

const regionSchema = z.object({ value: z.string(), label: z.string(), publicId: z.string().uuid() });

export const meetInputFormSchema = z
  .object({
    dateRange: z.array(z.date()).length(2, Errors.REQUIRED),
    title: z.string().min(1, Errors.REQUIRED),
    eventType: eventTypeSchema,
    eventCategory: eventCategorySchema,
    region: regionSchema,
    presenceType: presenceTypeSchema,
    eventSrc: z.string().min(1, Errors.REQUIRED).url(Errors.INVALID),
    content: z.string().optional(),
    image: z.instanceof(File, Errors.INVALID).optional(),
  })
  .refine(({ content, eventType }) => eventType.value === 'event' || !!content, {
    message: Errors.REQUIRED,
    path: ['content'],
  })
  .refine(({ image, eventType }) => eventType.value === 'event' || !!image, {
    message: Errors.REQUIRED,
    path: ['image'],
  });

export type MeetInputForm = z.infer<typeof meetInputFormSchema>;
