import jwt_decode, { JwtPayload } from 'jwt-decode';
import { AuthTokens, UserRole } from 'types/auth';
import axios from 'axios';

const securityServiceUrl = `${process.env.REACT_APP_SECURITY_SERVICE_URL}`;
const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const ROLE_CLAIM_SCHEMA = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

//Lock used so only one HTTP call can fetch the refetch token at once
const createLock = () => {
  let lock = false;

  const release = () => {
    lock = false;
  };

  const acquire = () => {
    if (lock) return false;
    lock = true;
    return true;
  };

  return {
    lock,
    acquire,
    release,
  };
};

const lock = createLock();

export const getAccessTokenWithLock = async (): Promise<string | void> => {
  if (lock.acquire()) {
    const accessToken = await getAccessToken();
    lock.release();
    return accessToken;
  } else {
    await new Promise(resolve => setTimeout(resolve, 250));
    return getAccessTokenWithLock();
  }
};

const getAccessToken = async (): Promise<string | void> => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (accessToken) {
    try {
      const parsedToken = JSON.parse(accessToken);
      if (isJwtExpired(parsedToken)) {
        return await generateNewJwt(parsedToken);
      } else {
        return parsedToken;
      }
    } catch (e) {
      lock.release();
      logoutUser();
    }
  }
};

const isJwtExpired = (token: string) => {
  const currentTime = new Date().getTime() / 1000;
  const payload = jwt_decode<JwtPayload>(token);
  return payload.exp ? currentTime > payload.exp : true;
};

const generateNewJwt = async (accessToken: string) => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  if (refreshToken) {
    try {
      const parsedRefreshToken = JSON.parse(refreshToken);
      await generateJwtWithRefreshToken(accessToken, parsedRefreshToken);
      const token = localStorage.getItem(ACCESS_TOKEN_KEY);
      if (token) {
        return JSON.parse(token);
      }
    } catch (e) {
      logoutUser();
    }
  }
};

const generateJwtWithRefreshToken = async (accessToken: string, refreshToken: string) => {
  const response = await refreshJwtAsync({
    expiredAccessToken: accessToken,
    refreshToken: refreshToken,
  });
  persistJwt(response);
};

const persistJwt = ({ refreshToken, accessToken }: AuthTokens) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(accessToken));
  localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(refreshToken));
};

export const logoutUser = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  const win: Window = window;
  win.location = '/signIn';
};

export const refreshJwtAsync = async (body: unknown) => {
  const axiosInstance = axios.create({
    baseURL: securityServiceUrl,
    headers: { 'Content-Type': 'application/json' },
  });
  const { data } = await axiosInstance.post('/Connect/refreshToken', body);
  return data;
};

type MyToken = {
  [key: string]: UserRole;
};

export const getUserRoleFromToken = (token: string) => {
  const payload = jwt_decode<MyToken>(token);
  return payload[ROLE_CLAIM_SCHEMA];
};
