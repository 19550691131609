import { useMutation, useQueryClient } from 'react-query';
import addFavoriteDocument from 'domain/favorites/api/addFavoriteDocument';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTranslation } from 'react-i18next';

function useAddFavoriteDocument() {
  const queryClient = useQueryClient();
  const { t } = useTranslation('genericItem');
  const { showSnackbar } = useSnackbarContext();

  return useMutation(addFavoriteDocument, {
    onSuccess: () => {
      showSnackbar({ text: t('addFavoriteMessage'), severity: 'success' });
      return queryClient.invalidateQueries(['favoriteDocuments']);
    },
    onError: () => showSnackbar({ text: t('failedToAddFavoriteMessage'), severity: 'danger' }),
  });
}

export default useAddFavoriteDocument;
