import individualInsuranceImg from 'assets/img/genericItem/icon-assurance-vie.png';
import mortgageImg from 'assets/img/genericItem/icon-hypotheque.png';
import investmentsImg from 'assets/img/genericItem/icon-investissements.png';
import groupInsuranceImg from 'assets/img/genericItem/icon-assurance-collective.png';
import advisorServicesImg from 'assets/img/genericItem/icon-service-conseillers.png';
import advancedSolutionsImg from 'assets/img/genericItem/icon-solutions-avancées.png';
import { RessourceCategoryEnum } from 'types/ressources';

type RessourceSection = {
  category: RessourceCategoryEnum;
  title: string;
  subTitle: string;
  imageSrc: string;
};

type DirectionRessourceSection = Omit<RessourceSection, 'subTitle' | 'imageSrc'>;

const lifeInsurance: RessourceSection = {
  category: RessourceCategoryEnum.INDIVIDUAL_INSURANCE,
  imageSrc: individualInsuranceImg,
  subTitle: 'sectionSubTitle.individualInsurance',
  title: 'individualInsurance',
};

const investmentsRetirement: RessourceSection = {
  category: RessourceCategoryEnum.INVESTMENTS_RETIREMENT,
  imageSrc: investmentsImg,
  subTitle: 'sectionSubTitle.investmentsRetirement',
  title: 'investmentsRetirement',
};

const mortgage: RessourceSection = {
  category: RessourceCategoryEnum.MORTGAGE,
  imageSrc: mortgageImg,
  subTitle: 'sectionSubTitle.mortgage',
  title: 'mortgage',
};

const groupAnnuities: RessourceSection = {
  category: RessourceCategoryEnum.GROUP_ANNUITIES,
  imageSrc: groupInsuranceImg,
  subTitle: 'sectionSubTitle.groupAnnuities',
  title: 'groupAnnuities',
};

const advancedSolutions: RessourceSection = {
  category: RessourceCategoryEnum.ADVANCED_SOLUTION,
  imageSrc: advancedSolutionsImg,
  subTitle: 'sectionSubTitle.advancedSolutions',
  title: 'advancedSolutions',
};

const advisorsServices: RessourceSection = {
  category: RessourceCategoryEnum.ADVISOR_SERVICES,
  imageSrc: advisorServicesImg,
  subTitle: 'sectionSubTitle.advisorsServices',
  title: 'advisorsServices',
};

const highDirection: DirectionRessourceSection = {
  category: RessourceCategoryEnum.HIGH_DIRECTION,
  title: 'highDirection',
};

const generalDirection: DirectionRessourceSection = {
  category: RessourceCategoryEnum.GENERAL_DIRECTION,
  title: 'generalDirection',
};

const adminServicesDirection: DirectionRessourceSection = {
  category: RessourceCategoryEnum.ADMIN_SERVICES_DIRECTION,
  title: 'adminServicesDirection',
};

const ressourceSections = [
  lifeInsurance,
  investmentsRetirement,
  groupAnnuities,
  advisorsServices,
  mortgage,
  advancedSolutions,
];

const directionRessourceSections = [highDirection, generalDirection, adminServicesDirection];

export { ressourceSections, directionRessourceSections };
