import React, { useEffect, useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  Column,
  ColumnFiltersState,
} from '@tanstack/react-table';
import styles from 'styles/components/shared/table.module.scss';
import Button from 'components/inputs/Button';
import { useTranslation } from 'react-i18next';
import TableMenuColumn from 'components/shared/Table/TableMenuColumn';
import RSelect, { SingleValue } from 'react-select';

//Leave second type as any, we want the cell content to be anything
// eslint-disable-next-line
export type ColumnType<T> = ColumnDef<T, any>;

type PageSizeType = { value: number; label: string };

type TableProps<T> = {
  data: T[];
  columns: ColumnType<T>[];
  className: string;
  usePagination?: boolean;
  useSorting?: boolean;
  sortingDefault?: SortingState;
  useFiltering?: boolean;
};

function Table<T>({
  data,
  columns,
  className,
  usePagination = false,
  useSorting = false,
  sortingDefault = [],
  useFiltering = false,
}: TableProps<T>) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const defaultPageSize: SingleValue<PageSizeType> = { value: 10, label: '10' };
  const optionsPageSize = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  const {
    getRowModel,
    getHeaderGroups,
    previousPage,
    setPageIndex,
    setPageSize,
    getCanPreviousPage,
    nextPage,
    getCanNextPage,
    getPageCount,
    getState,
    getPreFilteredRowModel,
    resetColumnFilters,
    resetSorting,
  } = useReactTable({
    data,
    initialState: {
      pagination: { pageSize: defaultPageSize.value },
      sorting: sortingDefault,
    },
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    sortDescFirst: true,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  useEffect(() => {
    return () => {
      resetColumnFilters();
      resetSorting();
    };
  }, [data]);

  const { t } = useTranslation('common');
  const { paginationFooter, button, paginationText, tableFooter, pageSizeDropdown, pageSizeLabel } = styles;

  const setNewPageSize = (newValue: SingleValue<PageSizeType>) => {
    if (newValue) {
      setPageSize(newValue.value);
    } else {
      setPageSize(defaultPageSize.value);
    }
  };

  const setAriaSort = (column: Column<T>) => {
    if (!useSorting || !column.getCanSort()) {
      return undefined;
    }
    return column.getIsSorted()
      ? getState().sorting.find(e => e.id === column.id)?.desc
        ? 'descending'
        : 'ascending'
      : 'none';
  };

  return (
    <>
      <table className={`${className} `}>
        <thead>
          {getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} aria-sort={setAriaSort(header.column)}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  {((useFiltering && header.column.getCanFilter()) || (useSorting && header.column.getCanSort())) && (
                    <TableMenuColumn
                      useFiltering={useFiltering}
                      useSorting={useSorting}
                      column={header.column}
                      firstValue={getPreFilteredRowModel().flatRows[0]?.getValue(header.column.id)}
                      totalResult={Object.keys(getRowModel().rowsById).length}
                      getState={getState}
                    ></TableMenuColumn>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {usePagination && (
        <div className={tableFooter}>
          {
            <p>
              {t('totalDemande')} {Object.keys(getRowModel().rowsById).length} /{' '}
              {getPreFilteredRowModel().flatRows.length}
            </p>
          }

          <div className={paginationFooter}>
            <div>
              <label className={pageSizeLabel} htmlFor="pageSizeSelect">
                {t('pageSize')}
              </label>
              <RSelect
                inputId="pageSizeSelect"
                className={pageSizeDropdown}
                options={optionsPageSize}
                onChange={setNewPageSize}
                defaultValue={defaultPageSize}
                isClearable={false}
                isSearchable={false}
                isMulti={false}
                menuPlacement="top"
              />
            </div>
            <Button className={button} onClick={() => setPageIndex(0)} disabled={!getCanPreviousPage()}>
              {'<<'}
            </Button>
            <Button className={button} onClick={() => previousPage()} disabled={!getCanPreviousPage()}>
              {'<'}
            </Button>
            <Button className={button} onClick={() => nextPage()} disabled={!getCanNextPage()}>
              {'>'}
            </Button>
            <Button className={button} onClick={() => setPageIndex(getPageCount() - 1)} disabled={!getCanNextPage()}>
              {'>>'}
            </Button>
            <span className={paginationText}>
              {`Page ${getState().pagination.pageIndex + 1} ${t('of')} ${getPageCount()}`}
            </span>
          </div>
        </div>
      )}
    </>
  );
}
export default Table;
