import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Column } from '@tanstack/react-table';
import styles from 'styles/components/shared/table.module.scss';

function TableFilter({
  column,
  firstValue,
  totalResult,
  setOpenMenu,
}: {
  // eslint-disable-next-line
  column: Column<any, unknown>;
  firstValue: unknown;
  totalResult: number;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
}) {
  const { t, i18n } = useTranslation('common');
  const { numberInput } = styles;
  const columnFilterValue = column.getFilterValue();
  const sortedUniqueValues = React.useMemo(
    () => (typeof firstValue === 'number' ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort()),
    [column.getFacetedUniqueValues()],
  );

  switch (typeof firstValue) {
    case 'number':
      return (
        <>
          <input
            type="number"
            className={numberInput}
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={(columnFilterValue as [number, number])?.[0] ?? ''}
            onChange={event => column.setFilterValue((old: [number, number]) => [event.target.value, old?.[1]])}
            placeholder={`${t('min')} ${
              column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
            }`}
          />
          <input
            type="number"
            className={numberInput}
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={(columnFilterValue as [number, number])?.[1] ?? ''}
            onChange={event => column.setFilterValue((old: [number, number]) => [old?.[0], event.target.value])}
            placeholder={`${t('max')} ${
              column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
            }`}
          />
        </>
      );
    case 'string':
      return (
        <>
          <datalist id={column.id + 'list'}>
            {sortedUniqueValues.slice(0, 5000).map((value: string, index: number) => (
              <option value={value} key={index} />
            ))}
          </datalist>
          <input
            type="text"
            value={(columnFilterValue ?? '') as string}
            onChange={event => column.setFilterValue(event.target.value)}
            placeholder={`${t('search')}...`}
            list={column.id + 'list'}
          />
        </>
      );
    case 'object': {
      type ValuePiece = Date | string;
      type Value = ValuePiece | [ValuePiece, ValuePiece];

      const minDate = new Date(column.getFacetedMinMaxValues()?.[0] ?? '');
      const maxDate = new Date(column.getFacetedMinMaxValues()?.[1] ?? '');

      const [rangeDate, setRangeDate] = React.useState<Value>('');

      useEffect(() => {
        return () => {
          setRangeDate('');
        };
      }, []);

      const onChangeDate = (value: Value) => {
        if (value === null) {
          setRangeDate('');
          column.setFilterValue('');
        } else {
          setRangeDate(value);
          column.setFilterValue(() => (value ? value : undefined));
        }

        //Little hack to keep the select open after dates are selected
        setTimeout(() => {
          setOpenMenu(true);
        }, 10);
      };
      return (
        <>
          {firstValue instanceof Date && (
            <>
              <DateRangePicker
                locale={i18n.language}
                value={rangeDate}
                disableCalendar={totalResult === 0}
                minDate={minDate}
                maxDate={maxDate}
                onChange={value => onChangeDate(value)}
              ></DateRangePicker>
            </>
          )}
        </>
      );
    }
    default:
      return <div />;
  }
}
export default TableFilter;
