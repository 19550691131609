import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from 'styles/components/pages.module.scss';
import NewsCardList from 'domain/home/components/NewsCardList';
import useGetNewsFeed from 'domain/home/hooks/useGetNewsFeed';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';
import { UserRole } from 'types/auth';
import Checkbox from 'components/shared/CheckBox';
import styles from 'styles/domain/home/components/home-page.module.scss';

function HomePage() {
  const { t } = useTranslation('home');
  const [showExpiredNewsArticles, setShowExpiredNewsArticles] = useState(false);
  const { data: newsArticles, isFetching } = useGetNewsFeed(showExpiredNewsArticles);
  const { title, titlePadded } = style;
  const { wrapper, pageTitleContainer } = styles;

  const { userRole } = useAuthContext();

  return (
    <>
      <div className={pageTitleContainer}>
        <h1 className={`${title} ${titlePadded}`}>{t('homepage')}</h1>
        {userRole === UserRole.ADMIN && (
          <Checkbox
            label={t('showHiddenNews')}
            isChecked={showExpiredNewsArticles}
            onChange={() => setShowExpiredNewsArticles(!showExpiredNewsArticles)}
          />
        )}
      </div>
      {newsArticles && <NewsCardList newsArticles={newsArticles} showExpiredNewsArticles={showExpiredNewsArticles} />}
      {isFetching && (
        <div className={wrapper}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

export default HomePage;
