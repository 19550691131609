import React from 'react';
import styles from 'styles/components/typography/required-marker.module.scss';

type Props = {
  isRequired?: boolean;
};

export default function RequiredMarker({ isRequired }: Props) {
  return <>{isRequired === true && <span className={styles.red}>*</span>}</>;
}
