import React, { ReactElement, useState } from 'react';
import styles from 'styles/components/navigation/search-tabs.module.scss';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'components/shared/LoadingSpinner';

export type SearchTabsType = {
  label: string;
  index: number;
  numberOfResult: number;
  hasNextPage: boolean;
  Component: ReactElement;
};

type Props = {
  tabs: SearchTabsType[];
  selectedTabIndex?: number;
  isDocumentLoading: boolean[];
  onTabChange: (tab: string) => void;
};

function SearchTabs({ tabs, selectedTabIndex, onTabChange, isDocumentLoading }: Props) {
  const [selectedTab, setSelectedTab] = useState(selectedTabIndex ?? 0);
  const { Component } = tabs[selectedTab];
  const { wrapper, tabsList, activeButton, tabButton, resultNumber, spinnerContainer } = styles;

  const { t } = useTranslation('search');

  const onSelectTab = (label: string, index: number) => {
    setSelectedTab(index);
    onTabChange(label);
  };

  return (
    <div className={wrapper}>
      <div className={tabsList}>
        {tabs.map(({ index, label, numberOfResult }) => (
          <button
            className={`${tabButton} ${selectedTab === index ? activeButton : ''}`}
            onClick={() => onSelectTab(label, index)}
            key={index}
          >
            {t(label)}
            {isDocumentLoading[index] && (
              <div className={spinnerContainer}>
                <LoadingSpinner isMini />
              </div>
            )}
            {!isDocumentLoading[index] && <p className={resultNumber}>{numberOfResult}</p>}
          </button>
        ))}
      </div>
      {Component}
    </div>
  );
}

export default SearchTabs;
