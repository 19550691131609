import { BidActions, bidActionsSchema } from 'types/bidActions';
import { z } from 'zod';
import { callApi } from 'utils/httpClient';

const gcServiceUrl = `${process.env.REACT_APP_GC_SERVICE_URL}`;

async function getBidActions(): Promise<BidActions[]> {
  const { data } = await callApi('get', gcServiceUrl, 'bidaction', {});
  return z.array(bidActionsSchema).parse(data);
}

export default getBidActions;
