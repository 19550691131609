import styles from 'styles/domain/calendar/contest-section.module.scss';
import eventAndNewsStyles from 'styles/components/shared/event-and-news-label.module.scss';
import mockImage from 'assets/img/contest-example-image.png';
import React from 'react';
import Icon from 'components/icons/Icon';
import { useTranslation } from 'react-i18next';
import { MeetEvent } from 'types/calendar';
import useGetBusinessUnit from 'domain/businessUnit/hooks/useGetBusinessUnit';
import { UserRole } from 'types/auth';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';

type Props = {
  contestEvent: MeetEvent;
  onDeleteContest: (id: string) => void;
};
export default function ContestSectionItem({ contestEvent, onDeleteContest }: Props) {
  const { t } = useTranslation('calendar');

  const { main, textContainer, contestTitle, contestImg, contestDescription, learnMoreButton, deleteBtn } = styles;

  const eventTypeText: Record<string, string> = {
    peopleInsurance: t('peopleInsurance', { ns: 'common' }),
    investments: t('investments', { ns: 'common' }),
    groupInsuranceAndAnnuities: t('groupInsuranceAndAnnuities', { ns: 'common' }),
    mortgage: t('mortgage', { ns: 'common' }),
    contestAndTraining: t('contestAndTraining', { ns: 'common' }),
    general: t('general', { ns: 'common' }),
  };

  const eventTypeClassName: Record<string, string> = {
    default: eventAndNewsStyles.peopleInsurance,
    peopleInsurance: eventAndNewsStyles.peopleInsurance,
    investments: eventAndNewsStyles.investments,
    groupInsuranceAndAnnuities: eventAndNewsStyles.groupInsurance,
    mortgage: eventAndNewsStyles.mortgage,
    contestAndTraining: eventAndNewsStyles.contest,
    general: eventAndNewsStyles.general,
  };

  const { data: businessUnits } = useGetBusinessUnit();

  const { userRole } = useAuthContext();

  const businessUnitName = businessUnits?.find(unit => unit.publicId === contestEvent.businessUnitIds[0])?.name;

  return (
    <div className={main}>
      <img className={contestImg} src={contestEvent.imageUrl ? contestEvent.imageUrl : mockImage} alt="Contest img" />
      <div className={textContainer}>
        {businessUnitName && (
          <div className={`${eventAndNewsStyles.newLabel} ${eventTypeClassName[businessUnitName]}`}>
            {eventTypeText[businessUnitName]}
          </div>
        )}
        <h2 className={contestTitle}>{contestEvent.title}</h2>
        <p className={contestDescription}>{contestEvent.content}</p>
        {contestEvent.externalInformationUrl && (
          <button className={learnMoreButton}>
            <Icon name={'rightArrowGreen'} />
            <a href={contestEvent.externalInformationUrl} target="_blank" rel="noreferrer">
              {t('learnMore')}
            </a>
          </button>
        )}
        {userRole === UserRole.ADMIN && (
          <button
            className={deleteBtn}
            aria-label="delete-button"
            onClick={() => onDeleteContest(contestEvent.publicId ?? '')}
          >
            <Icon name="remove" />
            <p>{t('deleteContest', { ns: 'admin' })}</p>
          </button>
        )}
      </div>
    </div>
  );
}
