import Icon from 'components/icons/Icon';
import React from 'react';
import type { Severity } from 'types/severity';
import styles from 'styles/components/feedback/output-message.module.scss';

type OutputProps = {
  readonly text: string;
  readonly severity: Severity;
  readonly note?: string;
};

export default function OutputMessage({ text, severity, note }: OutputProps) {
  const getIcon = () => {
    switch (severity) {
      case 'success':
        return 'successSeverity';
      case 'info':
        return 'infoSeverity';
      case 'warning':
        return 'warningSeverity';
      case 'danger':
        return 'dangerSeverity';
      default:
        return 'dangerSeverity';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <Icon name={getIcon()} />
      </div>
      <p className={styles.text}>{text}</p>
      {note && (<p className={styles.text}>{note}</p>)}
    </div>
  );
}
