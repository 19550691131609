import React from 'react';
import useGetRates from 'domain/sellingTools/hooks/useGetRates';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import styles from 'styles/components/shared/rate-list.module.scss';
import { createColumnHelper } from '@tanstack/react-table';
import { Rate } from 'types/rates';
import Table from 'components/shared/Table/Table';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ReactComponent as HypothecaLogo } from 'assets/img/logo-hypotheca.svg';

type Props = {
  ratesKey: string;
};

function RateList({ ratesKey }: Props) {
  const { data: rates, isLoading } = useGetRates(ratesKey);
  const columnHelper = createColumnHelper<Rate>();
  const { t } = useTranslation('genericItem');

  const columns = [
    columnHelper.accessor('term', {
      header: () => t('term'),
      cell: info => (info.getValue() === 0 ? t('variable') : info.getValue()),
    }),
    columnHelper.accessor('marketRate', {
      header: () => t('marketRate'),
      cell: info => `${info.getValue()} %`,
    }),
    columnHelper.accessor('bestRate', {
      header: () => t('bestRate'),
      cell: info => `${info.getValue()} %`,
    }),
  ];

  const { wrapper, table, spinnerContainer, infoMessage, tableHeader } = styles;
  return (
    <div className={wrapper}>
      {isLoading && (
        <div className={spinnerContainer}>
          <LoadingSpinner />
        </div>
      )}
      <div className={tableHeader}>
        {`${t('asOf')} ${moment().format('YYYY-MM-DD')}`}
        <HypothecaLogo />
      </div>
      {rates && <Table data={rates} columns={columns} className={table} />}
      <p className={infoMessage}>{t('ratesInfoMessage')}</p>
    </div>
  );
}

export default RateList;
