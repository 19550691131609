import SearchBar from 'domain/search/components/SearchBar';
import React, { memo } from 'react';
import styles from 'styles/components/layout/header.module.scss';
import FilterProvider from 'domain/search/contexts/FilterProvider';

function Header() {
  const { header } = styles;
  return (
    <div className={header}>
      <FilterProvider>
        <SearchBar />
      </FilterProvider>
    </div>
  );
}

export default memo(Header);
