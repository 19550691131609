import React from 'react';
import styles from 'styles/components/navigation/language-button.module.scss';
import { useTranslationContext } from 'contexts/TranslationProvider';
import { en, fr } from 'locales/config';
import { trackEvent } from 'utils/trackEvent';

type Props = {
  isMobile?: boolean;
};

function LanguageButton({ isMobile }: Props) {
  const { button, buttonMobile } = styles;
  const { language, switchLanguage } = useTranslationContext();

  const onLangSwitch = () => {
    switchLanguage();
    trackEvent({ category: 'Language', action: 'Switching lang', label: language });
  };

  return (
    <button className={`${button} ${isMobile ? buttonMobile : ''}`} onClick={onLangSwitch}>
      {language === fr ? en : fr}
    </button>
  );
}

export default LanguageButton;
