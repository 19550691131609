import getFilteredDocuments, { SearchDocumentQuery } from 'domain/search/api/getFilteredDocuments';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { PaginatedDocuments } from 'types/document';

function useDocumentSearch(
  query: SearchDocumentQuery,
  isSearchActive: boolean,
): UseInfiniteQueryResult<PaginatedDocuments> {
  return useInfiniteQuery(
    ['searchDocument', query],
    async ({ pageParam = 1 }) => {
      return getFilteredDocuments({ ...query, currentPage: pageParam });
    },
    {
      enabled: isSearchActive,
      keepPreviousData: true,
      getPreviousPageParam: firstPage => firstPage.previousPage ?? undefined,
      getNextPageParam: lastPage => {
        if (lastPage.data.results.length === query.pageSize) {
          return lastPage.nextPage;
        }
        return undefined;
      },
    },
  );
}

export default useDocumentSearch;
