import { z } from 'zod';
import { Errors } from 'types/errors';

export const addRessourceInputSchema = z.object({
  firstname: z.string().min(1, Errors.REQUIRED),
  title: z.string().optional(),
  lastname: z.string().min(1, Errors.REQUIRED),
  email: z.string().email().optional(),
  phone: z.string().optional(),
  description: z.string().optional(),
  fax: z.string().optional(),
  tollFreePhone: z.string().optional(),
});

export type AddRessourceInput = z.infer<typeof addRessourceInputSchema>;
