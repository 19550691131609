import { z } from 'zod';

export const equisoftTypeSchema = z.array(z.object({
    label: z.string(),
    value: z.string().optional().nullable(),
    options: z.array(z.object({
        label: z.string(),
        value: z.string()
    })).optional()
  }));

export type EquisoftType = z.infer<typeof equisoftTypeSchema>;