import React, { PropsWithChildren } from 'react';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';
import { Navigate } from 'react-router-dom';
import { UserRole } from 'types/auth';

function RequireAdmin({ children }: PropsWithChildren<unknown>) {
  const { userRole } = useAuthContext();

  return userRole === UserRole.ADMIN ? <>{children}</> : <Navigate to="/home" />;
}

export default RequireAdmin;
