import React from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import TextInput, { TextInputProps } from 'components/inputs/TextInput';
import useGetErrorMessage from 'components/forms/hooks/useGetErrorMessage';

type TextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextInputProps & UseControllerProps<TFieldValues, TName>;

function TextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, ...props }: TextFieldProps<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });
  const getErrorMessage = useGetErrorMessage(props.label);
  const errorMessage = error ? getErrorMessage(error.message) : undefined;

  return <TextInput errorMessage={errorMessage} {...field} {...props} />;
}

export default TextField;
