import React, { lazy } from 'react';
import { GenericDocument } from 'types/document';
import DocumentElement from 'components/layouts/favoritesSidebar/DocumentElement';
import styles from 'styles/components/layout/favoritesBar/documents-group.module.scss';

const LoadingSpinner = lazy(() => import('components/shared/LoadingSpinner'));

type Props = {
  title: string;
  documents?: GenericDocument[];
  type: 'favorite' | 'recent';
  noDocumentsLabel: string;
  isLoading: boolean;
};

function DocumentsGroup({ documents, title, type, noDocumentsLabel, isLoading }: Props) {
  const { documentsGroup, wrapper } = styles;

  return (
    <div className={documentsGroup}>
      <h1>{title}</h1>
      {isLoading ? (
        <LoadingSpinner isMini />
      ) : !documents?.length ? (
        <p>{noDocumentsLabel}</p>
      ) : (
        <div className={wrapper}>
          {documents?.map((document, i) => (
            <DocumentElement key={i} document={document} type={type} />
          ))}
        </div>
      )}
    </div>
  );
}

export default DocumentsGroup;
