import React from 'react';
import styles from 'styles/components/inputs/date-picker-input.module.scss';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import DateTimeRangePicker, { DateTimeRangePickerProps } from '@wojtekmaj/react-datetimerange-picker';

type DateTimeRangeInputProps = {
  value: [Date, Date];
  label: string;
  errorMessage?: string;
} & Omit<DateTimeRangePickerProps, 'value'>;

function DateTimeRangeInput({ label, errorMessage, ...props }: DateTimeRangeInputProps) {
  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>{label}</label>
      <DateTimeRangePicker className={styles.datePicker} {...props} />
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
}

export type { DateTimeRangeInputProps };
export default DateTimeRangeInput;
