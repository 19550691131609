import { FinancialCenter } from 'types/FinancialCenter';
import { callApi } from 'utils/httpClient';
import { Ressource, ressourceSchema } from 'types/ressources';
import { z } from 'zod';

const gcServiceUrl = `${process.env.REACT_APP_GC_SERVICE_URL}`;

async function getFinancialCenterResources(financialCenter: FinancialCenter): Promise<Ressource[]> {
  const { data } = await callApi('get', gcServiceUrl, `contact/byFinancialCenter/${financialCenter}`);
  return z.array(ressourceSchema).parse(data);
}

export default getFinancialCenterResources;
