import { useQuery } from 'react-query';
import getRecentDocuments from 'domain/favorites/api/getRecentDocuments';

export const getRecentDocumentsQueryKey = 'recentDocuments';

function useGetRecentDocuments() {
  return useQuery(getRecentDocumentsQueryKey, getRecentDocuments);
}

export default useGetRecentDocuments;
