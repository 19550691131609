import { z } from 'zod';

export const searchFilterTypeSchema = z.object({
  description: z.string(),
  isChecked: z.boolean().default(false),
  key: z.string(),
  type: z.string(),
});

export type SearchFilterType = z.infer<typeof searchFilterTypeSchema>;

export const classificationSchema = z.object({
  sections: z.array(searchFilterTypeSchema),
  providers: z.array(searchFilterTypeSchema),
  categories: z.array(searchFilterTypeSchema),
  languages: z.array(searchFilterTypeSchema),
});

export type ClassificationType = z.infer<typeof classificationSchema>;
