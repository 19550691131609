import React from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import MultiFileInput, { type MultiFileInputProps } from 'components/inputs/MultiFileInput';
import useGetErrorMessage from 'components/forms/hooks/useGetErrorMessage';

type MultiFileFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<MultiFileInputProps, 'onChange' | 'value'> & UseControllerProps<TFieldValues, TName>;

function MultiFileField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, ...props }: MultiFileFieldProps<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });
  const getErrorMessage = useGetErrorMessage(props.label);

  const errorMessage = error ? getErrorMessage(error.message) : undefined;

  return <MultiFileInput errorMessage={errorMessage} {...props} {...field} />;
}

export default MultiFileField;
