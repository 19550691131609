import { z } from 'zod';
import { selectOptionSchema } from 'schemas/selectOption';
import { Errors } from 'types/errors';
import { DocType } from 'domain/sellingTools/hooks/useGetDocTypes';

const addDocumentInputSchema = z
  .object({
    fileName: z.string().min(1, Errors.REQUIRED),
    selectedCategory: selectOptionSchema,
    type: selectOptionSchema,
    file: z.instanceof(File, Errors.REQUIRED).optional(),
    link: z.string().optional(),
  })
  .refine(({ link, type }) => type.value !== DocType.LINK || link, {
    message: Errors.REQUIRED,
    path: ['link'],
  })
  .refine(({ file, type }) => type.value !== DocType.DOCUMENT || file, {
    message: Errors.REQUIRED,
    path: ['file'],
  });

type AddDocumentInput = z.infer<typeof addDocumentInputSchema>;

export type { AddDocumentInput };
export { addDocumentInputSchema };
