import axios from 'axios';
import fileDownload from 'js-file-download';
import { useQuery } from 'react-query';
// eslint-disable-next-line no-restricted-imports
import { getAccessTokenWithLock } from './jwt';

function useDownload() {
  const downloadFile = async (url: string, filename: string, isAuthenticationHeaderNeeded: boolean) => {
    const accessToken = await getAccessTokenWithLock();

    const { data } = await axios.get(url, {
      responseType: 'blob',
      headers: isAuthenticationHeaderNeeded
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : {},
    });

    await fileDownload(data, filename);
  };

  const handleDownload = (documentId: string, url: string, filename: string, isAuthenticationHeaderNeeded = false) => {
    return useQuery(`download-${documentId}`, () => downloadFile(url, filename, isAuthenticationHeaderNeeded), {
      enabled: false,
      cacheTime: 0,
    });
  };

  return {
    handleDownload,
  };
}

export default useDownload;
