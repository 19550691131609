import React from 'react';
import styles from 'styles/components/navigation/search-filter.module.scss';
import { useTranslation } from 'react-i18next';
import SearchFilterItem from 'domain/search/components/SearchFilterItem';
import { SearchFilterType } from 'types/classification';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { useFilterContext } from 'domain/search/contexts/FilterProvider';
import SearchFilterLanguage from 'domain/search/components/SearchFilterLanguage';

type Props = {
  isLoading: boolean;
};

function SearchFilter({ isLoading }: Props) {
  const { grid, wrapper } = styles;

  const {
    sectionsFilter,
    categoriesFilter,
    providersFilter,
    languagesFilter,
    setSectionsFilter,
    setProvidersFilter,
    setCategoriesFilter,
    setLanguagesFilter,
  } = useFilterContext();

  const { t } = useTranslation('search');

  return (
    <>
      {isLoading ? (
        <div className={wrapper}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={grid}>
          <SearchFilterItem
            title={t('sectionsFilter')}
            filters={sectionsFilter}
            onSubmitFilters={allSectionsFilters => setSectionsFilter(allSectionsFilters)}
            onSelectFilter={newFilter =>
              setSectionsFilter(
                sectionsFilter.map((filter: SearchFilterType) =>
                  filter.key === newFilter.key ? { ...filter, isChecked: !newFilter.isChecked } : filter,
                ),
              )
            }
          />
          <SearchFilterItem
            title={t('providersFilter')}
            filters={providersFilter}
            onSubmitFilters={allProviderFilters => setProvidersFilter(allProviderFilters)}
            onSelectFilter={newFilter =>
              setProvidersFilter(
                providersFilter.map((filter: SearchFilterType) =>
                  filter.key === newFilter.key ? { ...filter, isChecked: !newFilter.isChecked } : filter,
                ),
              )
            }
          />
          <SearchFilterItem
            title={t('categoriesFilter')}
            filters={categoriesFilter}
            onSubmitFilters={allCategoriesFilters => setCategoriesFilter(allCategoriesFilters)}
            onSelectFilter={newFilter =>
              setCategoriesFilter(
                categoriesFilter.map((filter: SearchFilterType) =>
                  filter.key === newFilter.key ? { ...filter, isChecked: !newFilter.isChecked } : filter,
                ),
              )
            }
          />
          <SearchFilterLanguage
            title={t('languages')}
            filters={languagesFilter}
            onSelectFilter={newFilter =>
              setLanguagesFilter(
                languagesFilter.map((filter: SearchFilterType) =>
                  filter.key === newFilter.key ? { ...filter, isChecked: !newFilter.isChecked } : filter,
                ),
              )
            }
          />
        </div>
      )}
    </>
  );
}

export default SearchFilter;
