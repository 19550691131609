import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icons/Icon';
import styles from 'styles/components/navigation/display-more.module.scss';

type Props = {
  onMoreClick: () => void;
};

function DisplayMore({ onMoreClick }: Props) {
  const { t } = useTranslation('navigation');

  const { wrapper, label } = styles;

  return (
    <div className={wrapper} onClick={onMoreClick}>
      <Icon name={'rightArrow'} />
      <p className={label}>{t('displayMore')}</p>
    </div>
  );
}

export default DisplayMore;
