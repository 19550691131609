import GroupRadioInput from 'components/inputs/GroupRadioInput';
import Table, { ColumnType } from 'components/shared/Table/Table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EquisoftDocument, EquisoftFile } from '../schemas/equisoftDocumentSchema';
import { Column, ColumnHelper, createColumnHelper } from '@tanstack/react-table';
import { Control } from 'react-hook-form/dist/types';
import { SendToGCInput } from 'domain/sendToGc/schemas/sendToGCInput';
import TextField from 'components/forms/TextField';
import CheckBoxField from 'components/forms/CheckBoxField';
import SelectField from 'components/forms/SelectField';
import { SelectOption } from 'schemas/selectOption';
import styles from 'styles/domain/equisoft/form-document-equisoft.module.scss';
import { EquisoftClient } from 'types/equisoftClient';
import useGetEquisoftType from '../hooks/useGetEquisoftType';

export const FormatDoc = {
  //One Document containing an array with all the files
  OneDoc: 'oneDoc',
  // Multiple Document containing an array with only one file
  MultipleDoc: 'multipleDoc',
};

export default function EquisoftFormDocument({
  files,
  setEquisoftDocument,
  control,
  equisoftDocuments,
  equisoftClient,
}: {
  files: File[] | undefined;
  setEquisoftDocument: (tempsEquisoftDocument: EquisoftDocument[]) => void;
  control: Control<SendToGCInput>;
  equisoftDocuments: EquisoftDocument[];
  equisoftClient: EquisoftClient;
}) {
  const { t } = useTranslation('sendToGc');
  const { tableContainer, oneDocContainer, noDoc, tableWrapper, requiredCol, tableField, checkboxTableField } = styles;
  const { data: equisoftType } = useGetEquisoftType();

  useEffect(() => {
    //Clean up the empty option array for the RSelect
    if (equisoftType) {
      for (let i = 0; i < equisoftType.length; i++) {
        if (equisoftType[i].options?.length === 0) {
          delete equisoftType[i].options;
        }
      }
    }
  }, [equisoftType]);

  useEffect(() => {
    if (equisoftClient) {
      equisoftClient.sort(function (a, b) {
        var labelA = a.label.toUpperCase();
        var labelB = b.label.toUpperCase();

        //Number and special character
        var specialOrNumberRegex = /^[^A-Za-z]/;
        var isLabelAStartingSpecialOrNumber = specialOrNumberRegex.test(labelA);
        var isLabelBStartingSpecialOrNumber = specialOrNumberRegex.test(labelB);

        if (isLabelAStartingSpecialOrNumber && !isLabelBStartingSpecialOrNumber) {
          return 1;
        }
        if (!isLabelAStartingSpecialOrNumber && isLabelBStartingSpecialOrNumber) {
          return -1;
        }

        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
    }
  }, [equisoftClient]);

  const [formatDoc, setFormatDoc] = React.useState(FormatDoc.OneDoc);
  const inputs = [
    { id: 'OneDoc', value: FormatDoc.OneDoc, label: t('createOneDoc') },
    { id: 'multipleDoc', value: FormatDoc.MultipleDoc, label: t('createMultipleDoc') },
  ];
  const asFiles = files && files.length > 0;

  const defaultEquisoftFileObj: EquisoftFile = {
    file: undefined,
    synchronize: true,
  };
  const defaultEquisoftDocumentObj: EquisoftDocument = {
    formatDoc: formatDoc,
    equisoftDocName: '',
    description: '',
    type: undefined as SelectOption | undefined,
    sendTo: [] as SelectOption[],
    files: [],
  };

  const columnHelperFile = createColumnHelper<EquisoftFile>();
  const columnHelperDocument = createColumnHelper<EquisoftDocument>();

  const [columnsFile, setColumnsFile] = useState<ColumnType<EquisoftFile>[]>([]);
  const [columnsDocument, setColumnsDocument] = useState<ColumnType<EquisoftDocument>[]>([]);

  useEffect(() => {
    const columnsFile2 = [
      columnHelperFile.accessor('synchronize', {
        header: () => t('synchronise'),
        cell: info => (
          <CheckBoxField
            control={control}
            name={`equisoftDocuments.${0}.files.${Number(info.cell.row.id)}.synchronize`}
            label=""
            id={`sync${Number(info.cell.row.id)}`}
          />
        ),
      }),
      columnHelperFile.accessor('file', {
        header: () => t('fileName'),
        cell: info => info.getValue()?.name,
      }),
    ];
  
    const columnsDocument2 = [
      columnHelperDocument.accessor('files', {
        id: 'synchroniseFiles',
        header: () => t('synchronise'),
        cell: info => (
          <div className={checkboxTableField}>
          <CheckBoxField
            control={control}
            name={`equisoftDocuments.${Number(info.cell.row.id)}.files.${0}.synchronize`}
            label=""
            id={`sync${Number(info.cell.row.id)}`}
          />
          </div>
        ),
      }),
      columnHelperDocument.accessor('files', {
        id: 'nameFiles',
        header: () => t('fileName'),
        cell: info => info.getValue()[0].file?.name,
      }),
      columnHelperDocument.accessor('equisoftDocName', {
        header: () => (
          <span>
            {t('docName')}
            <span className={requiredCol}> * </span>
          </span>
        ),
        cell: info => (
          <div className={tableField}>
            <TextField
              control={control}
              name={`equisoftDocuments.${Number(info.cell.row.id)}.equisoftDocName`}
              label=""
            />
          </div>
        ),
      }),
      columnHelperDocument.accessor('description', {
        header: () => t('description'),
        cell: info => (
          <div className={tableField}>
            <TextField control={control} name={`equisoftDocuments.${Number(info.cell.row.id)}.description`} label="" />
          </div>
        ),
      }),
      columnHelperDocument.accessor('type', {
        header: () => (
          <span>
            {t('type')}
            <span className={requiredCol}> * </span>
          </span>
        ),
        cell: info => (
          <div className={tableField}>
            <SelectField
              control={control}
              name={`equisoftDocuments.${Number(info.cell.row.id)}.type`}
              options={equisoftType}
              group
              label=""
              menuPosition="fixed"
              menuPlacement="top"
            />
          </div>
        ),
      }),
      columnHelperDocument.accessor('sendTo', {
        header: () => t('sendTo'),
        cell: info => (
          <div className={tableField}>
            <SelectField
              control={control}
              name={`equisoftDocuments.${Number(info.cell.row.id)}.sendTo`}
              options={equisoftClient}
              label=""
              isMulti
              menuPosition="fixed"
              menuPlacement="top"
            />
          </div>
        ),
      }),
    ];

    setColumnsFile(columnsFile2);
    setColumnsDocument(columnsDocument2)
    var tempsEquisoftDocument = [] as EquisoftDocument[];
    if (asFiles) {
      if (formatDoc === FormatDoc.OneDoc) {
        tempsEquisoftDocument[0] = { ...defaultEquisoftDocumentObj } as EquisoftDocument;
        tempsEquisoftDocument[0].formatDoc = formatDoc;
        tempsEquisoftDocument[0].equisoftDocName = files[0].name;

        for (let index = 0; index < files.length; index++) {
          tempsEquisoftDocument[0].files[index] = { ...defaultEquisoftFileObj } as EquisoftFile;
          tempsEquisoftDocument[0].files[index].file = files[index];
        }
      } else if (formatDoc === FormatDoc.MultipleDoc) {
        for (let index = 0; index < files.length; index++) {
          tempsEquisoftDocument[index] = { ...defaultEquisoftDocumentObj } as EquisoftDocument;
          tempsEquisoftDocument[index].formatDoc = formatDoc;
          tempsEquisoftDocument[index].files = [{ ...defaultEquisoftFileObj }];
          tempsEquisoftDocument[index].files[0].file = files[index];
          tempsEquisoftDocument[index].equisoftDocName = files[index].name;
        }
      }
    }
    setEquisoftDocument(tempsEquisoftDocument as EquisoftDocument[]);
  }, [files, formatDoc]);

  return (
    <div>
      <GroupRadioInput
        inputs={inputs}
        name="formatDoc"
        label={t('sendToEquisoft')}
        stateValue={formatDoc}
        setStateValue={setFormatDoc}
        isRequired
      />

      {!files || (files.length === 0 && <p className={noDoc}>{t('noDoc')}</p>)}

      {asFiles && formatDoc === FormatDoc.OneDoc && equisoftDocuments.length > 0 && (
        <>
          <div className={oneDocContainer}>
            <TextField
              control={control}
              name={`equisoftDocuments.${0}.equisoftDocName`}
              label={t('docName')}
              isRequired
            />

            <TextField control={control} name={`equisoftDocuments.${0}.description`} label={t('description')} />

            <SelectField
              control={control}
              name={`equisoftDocuments.${0}.type`}
              options={equisoftType}
              group
              label="Type"
              isRequired

            />
            <SelectField
              control={control}
              name={`equisoftDocuments.${0}.sendTo`}
              options={equisoftClient}
              label={t('sendTo')}
              isMulti
            />
          </div>
          <div className={tableWrapper}>
            <Table data={equisoftDocuments[0].files} columns={columnsFile} className={tableContainer} />
          </div>
        </>
      )}

      {asFiles && formatDoc === FormatDoc.MultipleDoc && equisoftDocuments.length > 0 && (
        <div className={tableWrapper}>
          <Table data={equisoftDocuments} columns={columnsDocument} className={tableContainer} />
        </div>
      )}
    </div>
  );
}
