import { NewsArticle, newsArticleSchema } from 'types/news';
import { callApi } from 'utils/httpClient';
import { z } from 'zod';

const wcmServiceUrl = `${process.env.REACT_APP_WCM_SERVICE_URL}`;

async function getNewsFeed(): Promise<NewsArticle[]> {
  const { data } = await callApi('get', wcmServiceUrl, 'news', {});
  return z.array(newsArticleSchema).parse(data);
}

export default getNewsFeed;
