import React from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import useGetErrorMessage from 'components/forms/hooks/useGetErrorMessage';
import TextAreaInput, { TextAreaInputProps } from 'components/inputs/TextAreaInput';

type TextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextAreaInputProps & UseControllerProps<TFieldValues, TName>;

function TextAreaField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, ...props }: TextFieldProps<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });
  const getErrorMessage = useGetErrorMessage(props.label);

  const errorMessage = error ? getErrorMessage(error.message) : undefined;

  return <TextAreaInput errorMessage={errorMessage} {...field} {...props} />;
}

export default TextAreaField;
