import { callApi } from 'utils/httpClient';
import { userProfileSchema } from 'types/userProfile';

const userProfileUrl = `${process.env.REACT_APP_USER_PROFILE_SERVICE_URL}`;

async function getProfileInfo() {
  const { data } = await callApi('get', userProfileUrl, 'me/profile');
  return userProfileSchema.parse(data);
}

export default getProfileInfo;
