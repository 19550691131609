import React from 'react';
import Icon from 'components/icons/Icon';
import { useNavigate } from 'react-router-dom';
import styles from 'styles/components/shared/back-arrow.module.scss';

export default function BackArrow() {
  const navigate = useNavigate();

  const { arrow } = styles;

  return (
    <div className={arrow} onClick={() => navigate(-1)}>
      <Icon name={'arrowBackCircle'} />
    </div>
  );
}
