import React, { memo } from 'react';
import styles from 'styles/components/layout/mobile-header.module.scss';
import mainLogo from 'assets/img/main-icon.png';
import { RouterLink } from 'components/navigation';
import { useTranslation } from 'react-i18next';

type HeaderProps = {
  onAppHeaderClick: () => void;
};

function MobileHeader({ onAppHeaderClick }: HeaderProps) {
  const { t } = useTranslation('common');

  const { header, routerWrapper, title } = styles;
  return (
    <div className={header}>
      <div onClick={onAppHeaderClick}>
        <RouterLink to="/" className={routerWrapper}>
          <img src={mainLogo} alt="GC logo"></img>
          <h2 className={title}>{t('appName')}</h2>
        </RouterLink>
      </div>
    </div>
  );
}

export default memo(MobileHeader);
