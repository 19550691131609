import React, { useState } from 'react';
import styles from 'styles/components/navigation/display-more-filters-modal.module.scss';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/shared/CheckBox';
import { SearchFilterType } from 'types/classification';
import Button from 'components/inputs/Button';

type Props = {
  filters: SearchFilterType[];
  onSubmitFilters: (filters: SearchFilterType[]) => void;
  hideModal: () => void;
};

export default function DisplayMoreFiltersModalContent({ filters, onSubmitFilters, hideModal }: Props) {
  const { grid, selectFilters } = styles;
  const { t } = useTranslation('search');

  const [modalFilters, setModalFilters] = useState(filters);

  const onSelectFilter = (newFilter: SearchFilterType) => {
    setModalFilters(
      modalFilters.map((filter: SearchFilterType) =>
        filter.key === newFilter.key ? { ...filter, isChecked: !newFilter.isChecked } : filter,
      ),
    );
  };

  const onSubmitModalFilters = () => {
    onSubmitFilters(modalFilters);
    hideModal();
  };

  return (
    <>
      <h2 className={selectFilters}>{t('selectFilters')}</h2>
      <div className={grid}>
        {modalFilters.map((filter, i) => (
          <Checkbox
            label={filter.description}
            isChecked={filter.isChecked ?? false}
            onChange={() => onSelectFilter(filter)}
            key={i}
          />
        ))}
      </div>
      <Button type="submit" onClick={onSubmitModalFilters}>
        {t('submit', { ns: 'common' })}
      </Button>
    </>
  );
}
