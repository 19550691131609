import React, { ReactElement } from 'react';
import styles from 'styles/domain/sendToGc/admin/send-to-gc-admin-table.module.scss';
import Table from 'components/shared/Table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import useGetBidActions from 'domain/sendToGc/hooks/useGetBidActions';
import Button from 'components/inputs/Button';
import { useModalContext } from 'hooks/useModalContext';
import SendToGcEditBidActionForm from 'domain/sendToGc/components/admin/SendToGcEditBidActionForm';
import LoadingSpinner from 'components/shared/LoadingSpinner';

type BidActionTable = {
  businessUnitKey: string;
  categoryKey: string;
  financialCenterKey: string;
  configuration: string;
  button: ReactElement;
};

function SendToGcAdminTable() {
  const { table, spinnerContainer } = styles;
  const { t } = useTranslation('admin');
  const { data: bidActions, isLoading } = useGetBidActions();
  const { showModal } = useModalContext();

  const tableBidActions = bidActions?.map(b => ({
    ...b,
    button: <Button onClick={() => onEditBidAction(b.publicId, b.concurrencyId, b.configuration)}>{t('edit')}</Button>,
  }));

  const onEditBidAction = (id: string, concurrencyId: string, email: string) => {
    showModal({
      createComponent: () => <SendToGcEditBidActionForm id={id} concurrencyId={concurrencyId} email={email} />,
      cancellable: false,
    });
  };

  const columnHelper = createColumnHelper<BidActionTable>();

  const columns = [
    columnHelper.accessor('businessUnitKey', {
      header: () => t('businessDivision', { ns: 'sendToGc' }),
      cell: info => t(info.getValue(), { ns: 'genericItem' }),
    }),
    columnHelper.accessor('categoryKey', {
      header: () => t('category', { ns: 'sendToGc' }),
      cell: info => t(info.getValue(), { ns: 'common' }),
    }),
    columnHelper.accessor('financialCenterKey', {
      header: () => t('financialCenter', { ns: 'sendToGc' }),
      cell: info => t(info.getValue(), { ns: 'financialCenter' }),
    }),
    columnHelper.accessor('configuration', {
      header: () => t('email', { ns: 'sendToGc' }),
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('button', {
      header: '',
      cell: info => info.getValue(),
    }),
  ];

  return isLoading ? (
    <div className={spinnerContainer}>
      <LoadingSpinner />
    </div>
  ) : (
    <Table columns={columns} data={tableBidActions ?? []} className={table} usePagination />
  );
}

export default SendToGcAdminTable;
