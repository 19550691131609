import React, { PropsWithChildren } from 'react';
import styles from 'styles/components/shared/tooltip.module.scss';

type TooltipProps = {
  title: string;
};

function Tooltip({ children, title }: PropsWithChildren<TooltipProps>) {
  const { tooltipText, tooltipContainer } = styles;
  return (
    <div className={tooltipContainer}>
      {children}
      <span className={tooltipText}>{title}</span>
    </div>
  );
}

export default Tooltip;
