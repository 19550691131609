import React from 'react';
import styles from 'styles/domain/sellingTools/selling-tools-item.module.scss';
import { GenericSectionItem } from 'types/tools';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icons/Icon';
import RouterLink from 'components/navigation/RouterLink';

type Props = {
  item: GenericSectionItem;
};

function ItemCard({ item }: Props) {
  const { t } = useTranslation('genericItem');

  const { tool, title, description, descriptionWarning, subSectionWrapper, subSectionLabel } = styles;

  return (
    <div className={tool}>
      <img src={item.image} alt="" />
      <h1 className={title}>{t(item.title)}</h1>
      {item.description && (
        <p className={`${description} ${item.isDescriptionWarning ? descriptionWarning : ''}`}>{t(item.description)}</p>
      )}
      {item.subSections.map((subSection, i) =>
        item.externalLink ? (
          <a href={item.externalLink} target="_blank" key={i} rel="noopener noreferrer">
            <div className={subSectionWrapper}>
              <Icon name={'link'} />
              <p className={subSectionLabel}>{t(subSection.title)}</p>
            </div>
          </a>
        ) : (
          <RouterLink to={item.title} param={{ ...item, selectedTab: i }} key={i}>
            <div className={subSectionWrapper}>
              <Icon name={'rightArrow'} />
              <p className={subSectionLabel}>{t(subSection.title)}</p>
            </div>
          </RouterLink>
        ),
      )}
    </div>
  );
}

export default ItemCard;
