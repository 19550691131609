import { useMockContext } from 'domain/contexts/MockProvider';
import { useQuery } from 'react-query';

function useGetClientsItems() {
  const { getClientsItems } = useMockContext();

  return useQuery(['clientsItems'], getClientsItems);
}

export default useGetClientsItems;
