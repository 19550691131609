import React, { PropsWithChildren } from 'react';
import styles from 'styles/components/inputs/button.module.scss';

type Props = {
  className?: string;
  btnType?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const buttonType: Record<string, string> = {
  primary: styles.primary,
  secondary: styles.secondary,
};

function Button({ className, btnType, children, ...props }: PropsWithChildren<Props>) {
  const { button } = styles;
  return (
    <button className={`${button} ${buttonType[btnType ?? 'primary']} ${className ?? ''}`} {...props}>
      {children}
    </button>
  );
}

export default Button;
