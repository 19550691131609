import { callApi } from 'utils/httpClient';
import { z } from 'zod';
import { Note, noteSchema } from 'types/note';

const wcmServiceUrl = `${process.env.REACT_APP_WCM_SERVICE_URL}`;

async function getNotes(): Promise<Note[]> {
  const { data } = await callApi('get', wcmServiceUrl, 'note', {});
  return z.array(noteSchema).parse(data);
}

export default getNotes;
