import React, { memo, useMemo } from 'react';
import styles from 'styles/components/layout/sidebar/sidebar.module.scss';
import { useTranslation } from 'react-i18next';
import { RoutesPath } from 'router/routes';
import { useLocation } from 'react-router-dom';
import { NavigationGroup } from 'components/navigation';
import Icon from 'components/icons/Icon';
import { Link } from 'types/navigation';
import NavigationSidebarHeader from 'components/layouts/navigationSideBar/NavigationSidebarHeader';
import SettingsMenu from 'components/layouts/favoritesSidebar/SettingsMenu';
import useIsResizing from 'components/layouts/hooks/useIsResizing';
import ToggleMenuButton from 'components/navigation/menu/ToggleMenuButton';
import { UserRole } from 'types/auth';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';

type Props = {
  isOpen: boolean;
  onMenuLinkClick: () => void;
  setSidebarOpen: () => void;
};

function NavigationSidebar({ isOpen, onMenuLinkClick, setSidebarOpen }: Props) {
  const { t } = useTranslation('navigation');
  const location = useLocation();
  const { userRole } = useAuthContext();

  const isResizing = useIsResizing();

  const isActive = (path: string) => location.pathname.startsWith(`/${path}`);

  const topLinks = useMemo<Link[]>(
    () => [
      {
        to: RoutesPath.SEND_TO_GC,
        text: t('sendToGc'),
        isActive: isActive(RoutesPath.SEND_TO_GC),
        icon: <Icon name="folders" />,
      },
      {
        to: RoutesPath.TRANSACTIONS,
        text: t('inProgressTransactions'),
        isActive: isActive(RoutesPath.TRANSACTIONS),
        icon: <Icon name="transaction" />,
      },
      {
        to: RoutesPath.MY_CLIENTS,
        text: t('clientsGestion'),
        isActive: isActive(RoutesPath.MY_CLIENTS),
        icon: <Icon name="clients" />,
      },
      {
        to: RoutesPath.SELLING_TOOLS,
        text: t('sellingTools'),
        isActive: isActive(RoutesPath.SELLING_TOOLS),
        icon: <Icon name="toolbox" />,
      },
      {
        to: RoutesPath.MY_COMPANY,
        text: t('myCompany'),
        isActive: isActive(RoutesPath.MY_COMPANY),
        icon: <Icon name="company" />,
      },
      {
        to: RoutesPath.CALENDAR,
        text: t('calendar'),
        isActive: isActive(RoutesPath.CALENDAR),
        icon: <Icon name="calendar" />,
      },
    ],
    [location, t],
  );

  const adminLinks = useMemo(
    () =>
      userRole === UserRole.ADMIN
        ? [
            {
              to: RoutesPath.ADMIN,
              text: t('admin'),
              isActive: isActive(RoutesPath.ADMIN),
              icon: <Icon name="eye" />,
            },
          ]
        : [],
    [location, t, userRole],
  );

  const bottomLinks = useMemo(
    () => [
      ...adminLinks,
      {
        to: RoutesPath.SUPPORT,
        text: t('contactUs'),
        isActive: isActive(RoutesPath.SUPPORT),
        icon: <Icon name="contactUs" />,
      },
    ],
    [location, t, adminLinks],
  );

  const {
    sidebar,
    sidebarOpen,
    sidebarResizing,
    settingsMenuContainer,
    navigationListsContainer,
    navigationListsContainerClosed,
  } = styles;

  return (
    <div className={`${sidebar} ${isOpen ? sidebarOpen : ''} ${isResizing ? sidebarResizing : ''}`}>
      <NavigationSidebarHeader />
      <div className={settingsMenuContainer}>
        <SettingsMenu isMobileDisplay={true} onProfileClick={onMenuLinkClick} />
      </div>
      <div className={`${navigationListsContainer} ${!isOpen ? navigationListsContainerClosed : ''}`}>
        <NavigationGroup links={topLinks} onMenuItemClick={onMenuLinkClick} isMenuOpen={isOpen} />
        <NavigationGroup links={bottomLinks} onMenuItemClick={onMenuLinkClick} isMenuOpen={isOpen} isAtBottom />
        <ToggleMenuButton setMenuOpen={setSidebarOpen} isMenuOpen={isOpen} />
      </div>
    </div>
  );
}

export default memo(NavigationSidebar);
