import React, { PropsWithChildren } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';

function RequireAuth({ children }: PropsWithChildren<unknown>) {
  const { accessToken, refreshToken } = useAuthContext();
  const location = useLocation();

  return !accessToken || !refreshToken ? <Navigate to="/signIn" state={{ from: location }} replace /> : <>{children}</>;
}

export default RequireAuth;
