import React from 'react';
import chevronLeft from 'assets/img/chevron-left.png';
import styles from 'styles/domain/calendar/calendar-toolbar.module.scss';
import { NavigateAction, ToolbarProps } from 'react-big-calendar';
import { trackEvent } from 'utils/trackEvent';

export default function CalendarToolbar(props: ToolbarProps) {
  const { toolBar, toolBarMain, leftChevron, rightChevron, monthLabel, backLabel, leftMiniChevron } = styles;

  const goTo = (nextOrPrev: NavigateAction) => {
    trackEvent({ category: 'Calendar', action: 'Go to', label: nextOrPrev.toLowerCase() });
    const view = props.view;
    if (view === 'month') {
      const date = props.date;
      props.onNavigate(nextOrPrev, new Date(date.getFullYear(), date.getMonth()));
    } else {
      props.onNavigate(nextOrPrev);
    }
  };

  const backToMonthView = () => {
    props.onView('month');
  };

  return (
    <div className={toolBar}>
      {props.view === 'day' && (
        <p className={backLabel} onClick={backToMonthView}>
          <img src={chevronLeft} className={leftMiniChevron} alt="Left chevron" />
          Back
        </p>
      )}
      <div className={toolBarMain}>
        <p className={monthLabel}>{props.label}</p>

        <img src={chevronLeft} className={leftChevron} alt="Left chevron" onClick={() => goTo('PREV')} />
        <img src={chevronLeft} className={rightChevron} alt="Right chevron" onClick={() => goTo('NEXT')} />
      </div>
    </div>
  );
}
