import { callApi } from 'utils/httpClient';
import { AddDocumentInput } from 'domain/sellingTools/schemas/addDocumentInput';
import { DocType } from 'domain/sellingTools/hooks/useGetDocTypes';

const wcmServiceUrl = `${process.env.REACT_APP_WCM_SERVICE_URL}`;

async function addDocument({ file, fileName, selectedCategory, link, type }: AddDocumentInput) {
  const { data } = await callApi('post', wcmServiceUrl, 'document', {
    title: fileName,
    filename: type.value === DocType.DOCUMENT ? file?.name : '',
    sectionKey: selectedCategory.value,
    documentType: type.value === DocType.DOCUMENT ? file?.type : 'link',
    storageUrl: type.value === DocType.LINK ? link : '',
  });

  if (type.value === DocType.DOCUMENT) {
    const formData = new FormData();
    formData.append('file', file ?? '');
    return callApi('put', wcmServiceUrl, `document/${data.publicId}/content`, formData, 'multipart/form-data');
  }
}

export default addDocument;
