import { useQuery } from 'react-query';
import getFavoriteDocuments from 'domain/favorites/api/getFavoriteDocuments';

export const getFavoriteDocumentsQueryKey = 'favoriteDocuments';

function useGetFavoriteDocuments() {
  return useQuery([getFavoriteDocumentsQueryKey], () => getFavoriteDocuments());
}

export default useGetFavoriteDocuments;
