import * as React from 'react';
import styles from 'styles/domain/calendar/custom-calendar.module.scss';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import CalendarToolbar from 'domain/calendar/components/CalendarToolbar';
import type { MeetEvent } from 'types/calendar';
import moment from 'moment/moment';
import { useTranslationContext } from 'contexts/TranslationProvider';
import { trackEvent } from 'utils/trackEvent';
import useGetBusinessUnit from 'domain/businessUnit/hooks/useGetBusinessUnit';
import convertEventForCalendar, { type ConvertedMeetEvent } from 'domain/calendar/utils/convertEventForCalendar';
import { EventRegistrationModalContent } from 'domain/calendar/components/EventRegistrationModalContent';
import { useModalContext } from 'hooks/useModalContext';
import CalendarLegend from 'domain/calendar/components/CalendarLegend';

const localizer = momentLocalizer(moment);

type Props = {
  events: MeetEvent[];
};

const {
  wrapper,
  calendar,
  peopleInsurance,
  investments,
  groupInsurance,
  mortgage,
  contest,
  general,
  inPerson,
  hybrid,
  online,
} = styles;

const eventPresenceClassnames: Record<string, string> = {
  default: inPerson,
  inPerson,
  hybrid,
  online,
};

const eventTypeClassnames: Record<string, string> = {
  default: peopleInsurance,
  peopleInsurance: peopleInsurance,
  investments: investments,
  groupInsuranceAndAnnuities: groupInsurance,
  mortgage: mortgage,
  contestAndTraining: contest,
  general: general,
};

export default function CustomCalendar({ events }: Props) {
  const { language } = useTranslationContext();
  const { data: businessUnits } = useGetBusinessUnit();
  const { showModal, hideModal } = useModalContext();

  const convertedEvents = events.map(convertEventForCalendar);

  const onSelectEvent = (event: ConvertedMeetEvent) => {
    trackEvent({ category: 'Calendar', action: 'On Event click', label: event.title });

    showModal({
      createComponent: () => <EventRegistrationModalContent event={event} hideParentModal={hideModal} />,
      cancellable: false,
    });
  };

  const eventStyleGetter = (event: ConvertedMeetEvent) => {
    const businessUnitName = businessUnits?.find(unit => unit.publicId === event.businessUnitIds[0])?.name;
    const eventTypeClassname = eventTypeClassnames[businessUnitName ? businessUnitName : 'default'];
    const eventPresenceTypeClassname = eventPresenceClassnames[event.presenceType ? event.presenceType : 'default'];
    return {
      className: `${eventTypeClassname} ${eventPresenceTypeClassname}`,
    };
  };

  return (
    <div className={wrapper}>
      <Calendar
        localizer={localizer}
        events={convertedEvents}
        startAccessor="scheduledStartDatetime"
        endAccessor="scheduledEndDatetime"
        className={calendar}
        components={{
          toolbar: CalendarToolbar,
        }}
        culture={language}
        formats={{ weekdayFormat: 'dddd' }}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={onSelectEvent}
      />
      <CalendarLegend />
    </div>
  );
}
