import { useTranslation } from 'react-i18next';

function useGetEventTypes() {
  const { t } = useTranslation('admin');

  return [
    {
      value: 'contest',
      label: t('contestAndPromotions'),
    },
    {
      value: 'event',
      label: t('event'),
    },
  ];
}

export default useGetEventTypes;
