import React from 'react';
import { useTranslation } from 'react-i18next';
import titleStyles from 'styles/components/pages.module.scss';
import styles from 'styles/domain/sellingTools/selling-tool-detail.module.scss';
import { useLocation } from 'react-router-dom';
import BackArrow from 'components/shared/BackArrow';
import Tabs, { TabsType } from 'components/shared/Tabs';
import { GenericItemSubSection } from 'types/tools';
import DocumentList from 'components/shared/list/DocumentList';
import RateList from 'components/shared/list/RateList';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';
import { UserRole } from 'types/auth';
import Button from 'components/inputs/Button';
import AddDocumentForm from 'domain/sellingTools/components/AddDocumentForm';
import { useModalContext } from 'hooks/useModalContext';

function DocumentItemDetailPage() {
  const { t } = useTranslation('genericItem');
  const { title, titleNoBorder } = titleStyles;
  const { titleWrapper, wrapper, addButton } = styles;
  const { state } = useLocation();
  const { userRole } = useAuthContext();
  const { showModal } = useModalContext();

  if (!state) {
    // Fixing app crash on direct routing on pages like My Company, Selling Tools or My Clients
    window.location.href = '/';
  }

  const tabs: TabsType[] = state.subSections.map((section: GenericItemSubSection, index: number) => ({
    label: t(section.title),
    index,
    Component: ComponentFactory(section.documentsSectionKey, section.ratesKey),
  }));

  const subsectionsKeys = state.subSections.reduce(
    (filtered: { label: string; value: string }[], section: GenericItemSubSection) =>
      section.documentsSectionKey
        ? [
            ...filtered,
            {
              label: t(section.title),
              value: section.documentsSectionKey,
            },
          ]
        : filtered,
    [],
  );

  return (
    <>
      <div className={`${title} ${titleNoBorder} ${titleWrapper}`}>
        <BackArrow />
        <h1>{t(state.title)}</h1>
        {userRole === UserRole.ADMIN && (
          <Button
            className={addButton}
            onClick={() =>
              showModal({
                createComponent: () => <AddDocumentForm documentSectionKeys={subsectionsKeys} />,
                cancellable: false,
              })
            }
          >
            {t('addDocument')}
          </Button>
        )}
      </div>
      <div className={wrapper}>
        <Tabs tabs={tabs} isTabsLeftAligned={true} selectedTabIndex={state.selectedTab} />
      </div>
    </>
  );
}

export default DocumentItemDetailPage;

function ComponentFactory(documentsSectionKey?: string, rateKey?: string) {
  if (documentsSectionKey) {
    return <DocumentList documentSectionKey={documentsSectionKey} />;
  }
  if (rateKey) {
    return <RateList ratesKey={rateKey} />;
  }
}
