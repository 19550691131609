import React from 'react';
import styles from 'styles/components/typography/error-message.module.scss';

type Props = {
  errorMessage?: string;
};

// TODO: This should receive children, not errorMessage (see Label)
export function ErrorMessage({ errorMessage }: Props) {
  const { error } = styles;

  return <p className={error}>{errorMessage}</p>;
}
