import { useTranslation } from 'react-i18next';
import { Errors } from 'types/errors';

function useGetErrorMessage(fieldName: string) {
  const { t } = useTranslation('forms', { keyPrefix: 'errors' });

  return (key?: string) => t('prefix') + ' ' + fieldName + ' ' + t(key?.toLowerCase() ?? Errors.INVALID);
}

export default useGetErrorMessage;
