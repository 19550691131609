import React from 'react';
import DocumentsGroup from 'components/layouts/favoritesSidebar/DocumentsGroup';
import styles from 'styles/components/layout/favoritesBar/mobile-documents-menu.module.scss';
import useGetFavoriteDocuments from 'domain/favorites/hooks/useGetFavoriteDocuments';
import { useTranslation } from 'react-i18next';
import useGetRecentDocuments from 'domain/favorites/hooks/useGetRecentDocuments';

type Props = {
  isOpen: boolean;
  documentType: 'favorite' | 'recent';
};

function MobileDocumentsMenu({ isOpen, documentType }: Props) {
  const { t } = useTranslation('navigation');

  const { data: documents } = documentType === 'favorite' ? useGetFavoriteDocuments() : useGetRecentDocuments();
  const { container, containerOpen } = styles;
  return (
    <div className={`${container} ${isOpen ? containerOpen : ''}`}>
      <DocumentsGroup
        title={documentType === 'favorite' ? t('favorites') : t('recentlyConsulted')}
        documents={documents}
        type={documentType}
        noDocumentsLabel={'No Documents'}
        isLoading={false}
      />
    </div>
  );
}

export default MobileDocumentsMenu;
