import { Namespace, TFunction } from 'react-i18next';

export function getCalendarEventPresences(t: TFunction<Namespace>) {
  return [
    {
      value: 'inPerson',
      label: t('inPerson', { ns: 'common' }),
    },
    {
      value: 'online',
      label: t('online', { ns: 'common' }),
    },
    {
      value: 'hybrid',
      label: t('hybrid', { ns: 'common' }),
    },
  ];
}
