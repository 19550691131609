import { useQuery, UseQueryResult } from 'react-query';
import { EquisoftConfig } from 'types/equisoftConfig';
import getEquisoftConfig from '../api/getEquisoftConfig';
import { useTranslation } from 'react-i18next';
import { useShowConfirmationModal } from 'hooks/useShowConfirmationModal';
import { useNavigate, useLocation } from 'react-router-dom';

function useGetEquisoftConfig(): UseQueryResult<EquisoftConfig> {
  const { t } = useTranslation('profile');
  const { showConfirmationModal } = useShowConfirmationModal();
  const navigate = useNavigate();
  const location = useLocation();

  return useQuery('equisoftConfig', getEquisoftConfig, {
    onSuccess: data => {
      const currentUrl = location.pathname + location.hash;
      if (data && data.isRefreshTokenValid === false && !currentUrl.includes('/profile#refresh')) {
        showConfirmationModal({
          message: t('refreshEquisoftText'),
          title: t('refreshEquisoftTitle'),
          onSubmit: () => navigate('/profile#refresh'),
          onCancel: () => {},
          submitText: t('refreshEquisoftSubmit'),
        });
      }
    },
    onError: () =>
      showConfirmationModal({
        message: t('errorEquisoftMessage'),
        title: t('errorEquisoftTitle'),
      })
  });
}
export default useGetEquisoftConfig;