import ReactGA from 'react-ga4';

type Props = {
  category: string;
  action: string;
  label: string;
  value?: number;
};

export function trackEvent(trackProps: Props) {
  return ReactGA.event({ ...trackProps });
}
