import React from 'react';
import styles from 'styles/components/layout/favoritesBar/favorites-bar.module.scss';
import { RouterLink } from 'components/navigation';
import LogoutButton from 'components/navigation/menu/LogoutButton';
import useGetProfileInfo from 'domain/profile/hooks/useGetProfileInfo';
import useGetProfileAvatar from 'domain/profile/hooks/useGetProfileAvatar';
import LoadingSpinner from 'components/shared/LoadingSpinner';

type Props = {
  isMobileDisplay?: boolean;
  onProfileClick?: () => void;
};

function SettingsMenu({ isMobileDisplay, onProfileClick }: Props) {
  const { settingsMenu, spinnerWrapper, profileLinkWrapper, userName, avatarImage } = styles;
  const { data: user, isLoading: isLoadingProfile } = useGetProfileInfo();
  const { data, isLoading: isLoadingAvatarPhoto } = useGetProfileAvatar();

  return (
    <div className={settingsMenu} onClick={onProfileClick}>
      {isLoadingProfile || isLoadingAvatarPhoto ? (
        <div className={spinnerWrapper}>
          <LoadingSpinner isMini isWhite={isMobileDisplay} />
        </div>
      ) : (
        <RouterLink to="profile" className={profileLinkWrapper}>
          <img
            className={avatarImage}
            src={`data:${data?.mimeType};base64, ${data?.contentAsBase64}`}
            alt={'Avatar Photo'}
          />
          <span className={userName}>{`${user?.firstName} ${user?.lastName}`}</span>
        </RouterLink>
      )}
      <LogoutButton isMobile={isMobileDisplay} />
    </div>
  );
}

export default SettingsMenu;
