import { AddRessourceInput } from 'domain/support/schemas/AddRessourceInput';
import { callApi } from 'utils/httpClient';
import { FinancialCenter } from 'types/FinancialCenter';
import { RessourceCategoryEnum } from 'types/ressources';

const gcServiceUrl = `${process.env.REACT_APP_GC_SERVICE_URL}`;

type AddRessourceProps = {
  addRessourceInput: AddRessourceInput;
  financialCenterKey: FinancialCenter;
  category: RessourceCategoryEnum;
};

async function addRessource({ addRessourceInput, financialCenterKey, category }: AddRessourceProps): Promise<void> {
  const { data } = await callApi('post', gcServiceUrl, 'contact', {
    ...addRessourceInput,
    financialCenterKey,
    categoryKeys: [category],
  });
  return data;
}

export default addRessource;
