import styles from 'styles/domain/sendToGc/admin/send-to-gc-edit-bid-action-modal.module.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from 'components/forms/TextField';
import Button from 'components/inputs/Button';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { EditBidActionFormSchema, editBidActionFormSchema } from 'domain/sendToGc/schemas/editBidActionFormSchema';
import useEditBidAction from 'domain/sendToGc/hooks/useEditBidAction';

type Props = {
  id: string;
  concurrencyId: string;
  email: string;
};

function SendToGcEditBidActionForm({ id, concurrencyId, email }: Props) {
  const { t } = useTranslation('admin');

  const { formContainer, sendButton } = styles;

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<EditBidActionFormSchema>({
    mode: 'onSubmit',
    resolver: zodResolver(editBidActionFormSchema),
    defaultValues: {
      email: email,
    },
  });

  const { mutate: editBidAction, isLoading } = useEditBidAction(reset);

  const onEditBidAction = (data: { email: string }) => {
    editBidAction({ id, concurrencyId, email: data.email });
  };

  return (
    <form className={formContainer} onSubmit={handleSubmit(data => onEditBidAction(data))}>
      <TextField control={control} name="email" label={`${t('email', { ns: 'sendToGc' })}*`} />
      <Button className={sendButton} type="submit" disabled={!isDirty}>
        {isLoading ? <LoadingSpinner isMini isWhite /> : t('edit')}
      </Button>
    </form>
  );
}

export default SendToGcEditBidActionForm;
