import React, { useRef, useState } from 'react';
import { Column, TableState } from '@tanstack/react-table';
import TableFilter from 'components/shared/Table/TableFilter';
import { useClick, ControlledMenu, MenuItem, MenuDivider, MenuHeader } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import Icon from 'components/icons/Icon';
import { useTranslation } from 'react-i18next';
import styles from 'styles/components/shared/table.module.scss';

function TableMenuColumn({
  useFiltering,
  useSorting,
  column,
  firstValue,
  totalResult,
  getState,
}: {
  useFiltering: boolean;
  useSorting: boolean;
  // eslint-disable-next-line
  column: Column<any, unknown>;
  firstValue: unknown;
  totalResult: number;
  getState: () => TableState;
}) {
  const { menuButton, menuButtonOpen, filterIcon, sortableIcon, sortableIconAsc, sortableIconDesc } = styles;
  const { t } = useTranslation('common');

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const anchorProps = useClick(isOpen, setOpen);

  return (
    <>
      {column.getIsFiltered() && (
        <span className={filterIcon}>
          <Icon name="filter" />
        </span>
      )}
      {column.getIsSorted() && (
        <span
          className={`${sortableIcon} ${
            getState().sorting.find(e => e.id === column.id)?.desc === false ? sortableIconDesc : sortableIconAsc
          }`}
        >
          <Icon name="rightArrow" />
        </span>
      )}
      <button type="button" ref={ref} className={`${menuButton} ${isOpen ? menuButtonOpen : ''}`} {...anchorProps}>
        <Icon name="downCaret" />
      </button>
      <ControlledMenu state={isOpen ? 'open' : 'closed'} anchorRef={ref} onClose={() => setOpen(false)}>
        {useSorting && column.getCanSort() && (
          <>
            <MenuItem
              onClick={() => {
                column.toggleSorting(false);
              }}
            >
              {t('sortAZ')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                column.toggleSorting(true);
              }}
            >
              {t('sortZA')}
            </MenuItem>
          </>
        )}
        {useFiltering && useSorting && <MenuDivider />}
        {useFiltering && column.getCanFilter() && (
          <>
            <MenuHeader>{t('filter')}</MenuHeader>
            <MenuItem
              onClick={e => {
                e.stopPropagation = true;
                e.keepOpen = true;
              }}
            >
              <TableFilter
                column={column}
                firstValue={firstValue}
                totalResult={totalResult}
                setOpenMenu={setOpen}
              ></TableFilter>
            </MenuItem>
          </>
        )}
      </ControlledMenu>
    </>
  );
}

export default TableMenuColumn;
