import { GenericDocument, genericDocumentSchema } from 'types/document';
import { callApi } from 'utils/httpClient';
import { z } from 'zod';

const userProfileUrl = `${process.env.REACT_APP_USER_PROFILE_SERVICE_URL}`;

async function getRecentDocuments(): Promise<GenericDocument[]> {
  const { data } = await callApi('get', userProfileUrl, 'me/recents');
  return z.array(genericDocumentSchema).parse(data);
}

export default getRecentDocuments;
