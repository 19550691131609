import * as React from 'react';
import { DocumentType } from 'types/document';
import Icon from 'components/icons/Icon';

type Props = {
  documentType?: string;
};

function DocumentIcon({ documentType }: Props) {
  switch (documentType) {
    case DocumentType.PDF:
      return <Icon name={'pdf'} />;
    case DocumentType.LINK:
      return <Icon name={'link'} />;
    default:
      return <Icon name={'form'} />;
  }
}

export default DocumentIcon;
