import { useQuery, UseQueryResult } from 'react-query';
import getNewsFeed from 'domain/home/api/getNewsFeed';
import { NewsArticle } from 'types/news';
import moment from 'moment';

export const newsFeedQueryKey = 'newsFeed';

function useGetNewsFeed(showExpiredNewsArticle: boolean): UseQueryResult<NewsArticle[]> {
  return useQuery(newsFeedQueryKey, async () => getNewsFeed(), {
    select: data => {
      let sortedData = data.sort((a, b) => moment(b.displayStartDatetimeUtc).diff(moment(a.displayStartDatetimeUtc)));
      if (!showExpiredNewsArticle) {
        const todayDate = moment.utc();
        sortedData = sortedData.filter(newsArticle =>
          todayDate.isBetween(moment(newsArticle.displayStartDatetimeUtc), moment(newsArticle.displayEndDatetimeUtc)),
        );
      }
      return sortedData;
    },
  });
}

export default useGetNewsFeed;
