import React from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import useGetErrorMessage from 'components/forms/hooks/useGetErrorMessage';
import DateOnlyInput, { type DateOnlyInputProps } from 'components/inputs/DateOnlyInput';

type DateFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<DateOnlyInputProps, 'value'> & UseControllerProps<TFieldValues, TName>;

function DateOnlyField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, ...props }: DateFieldProps<TFieldValues, TName>) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name });
  const getErrorMessage = useGetErrorMessage(props.label);

  const errorMessage = error ? getErrorMessage(error.message) : undefined;

  return <DateOnlyInput errorMessage={errorMessage} onChange={onChange} value={value} {...props} />;
}

export default DateOnlyField;
