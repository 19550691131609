import React from 'react';
import { NewsArticle } from 'types/news';
import NewsCard from 'domain/home/components/NewsCard';
import styles from 'styles/domain/home/components/news-card-list.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  newsArticles: NewsArticle[];
  showExpiredNewsArticles: boolean;
};

function NewsCardList({ newsArticles }: Props) {
  const { t } = useTranslation('home');
  const { noNewsMessage, cardsContainer } = styles;

  return (
    <>
      <div className={cardsContainer}>
        {newsArticles.length === 0 ? (
          <p className={noNewsMessage}>{t('noNewsMessage')}</p>
        ) : (
          newsArticles?.map((newsArticle, i) => <NewsCard key={i} {...newsArticle}></NewsCard>)
        )}
      </div>
    </>
  );
}

export default NewsCardList;
