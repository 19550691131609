import React from 'react';
import styles from 'styles/components/inputs/date-input.module.scss';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import DateTimePicker, { type DateTimePickerProps } from 'react-datetime-picker';
import RequiredMarker from 'components/typography/RequiredMarker';

type DateInputProps = {
  value: Date;
  label: string;
  errorMessage?: string;
  isRequired?: boolean;
} & Omit<DateTimePickerProps, 'value'>;

function DateInput({ label, errorMessage, isRequired, ...props }: DateInputProps) {
  return (
    <div className={styles.dateField}>
      <label className={styles.inputLabel}>
        {label} <RequiredMarker isRequired={isRequired} />
      </label>
      <DateTimePicker className={styles.datePicker} {...props} />
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
}

export type { DateInputProps };
export default DateInput;
