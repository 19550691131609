import { useQuery, UseQueryResult } from 'react-query';
import { getAdvisor, getAdvisorOfOffice } from 'domain/sendToGc/api/getAdvisor';
import { Advisor } from 'types/advisor';

export function useGetAdvisor(): UseQueryResult<Advisor> {
  return useQuery('currentAdvisor', getAdvisor);
}

export function useGetAdvisorOfOffice(): UseQueryResult<Advisor[]> {
  return useQuery('currentAdvisorOfOffice', getAdvisorOfOffice);
}
