import { GenericDocument, genericDocumentSchema } from 'types/document';
import { z } from 'zod';
import { callApi } from 'utils/httpClient';

const userProfileUrl = `${process.env.REACT_APP_USER_PROFILE_SERVICE_URL}`;

async function getFavoriteDocuments(): Promise<GenericDocument[]> {
  const { data } = await callApi('get', userProfileUrl, 'me/favorites');
  return z.array(genericDocumentSchema).parse(data);
}

export default getFavoriteDocuments;
