import React from 'react';
import { useTranslation } from 'react-i18next';
import titleStyles from 'styles/components/pages.module.scss';
import styles from 'styles/components/shared/item-grid.module.scss';
import ItemCard from 'components/shared/ItemCard';
import { Outlet } from 'react-router-dom';
import tools from 'domain/sellingTools/data/tools';

function SellingToolsPage() {
  const { t } = useTranslation('genericItem');
  const { title, titleNoBorder } = titleStyles;
  const { grid } = styles;

  return (
    <>
      <h1 className={`${title} ${titleNoBorder}`}>{t('sellingTools')}</h1>
      <div className={grid}>
        {tools?.map((tool, i) => (
          <ItemCard key={i} item={tool} />
        ))}
      </div>
      <Outlet />
    </>
  );
}

export default SellingToolsPage;
