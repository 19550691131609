import React from 'react';
import { useTranslation } from 'react-i18next';
import titleStyle from 'styles/components/pages.module.scss';
import style from 'styles/domain/admin/admin.module.scss';
import RouterLink from 'components/navigation/RouterLink';

function AdminPage() {
  const { t } = useTranslation('admin');
  const { title, titlePadded } = titleStyle;
  const { grid, link } = style;

  return (
    <>
      <h1 className={`${title} ${titlePadded}`}>{t('adminTitle')}</h1>
      <div className={grid}>
        <RouterLink to="news">
          <h3 className={link}>{t('newsPage')}</h3>
        </RouterLink>
        <RouterLink to="calendar">
          <h3 className={link}>{t('calendarPage')}</h3>
        </RouterLink>
        <RouterLink to="alerts">
          <h3 className={link}>{t('alertPage')}</h3>
        </RouterLink>
        <RouterLink to="sendToGc">
          <h3 className={link}>{t('sendToGcPage')}</h3>
        </RouterLink>
      </div>
    </>
  );
}

export default AdminPage;
