import { z } from 'zod';

export const authTokensSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
});

export type AuthTokens = z.infer<typeof authTokensSchema>;

export type RedirectLocationState = {
  from: Location;
};

export enum UserRole {
  END_USER = 'endUser',
  ADMIN = 'admin',
  STAFF = 'gcstaff',
}
