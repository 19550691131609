import { callApi } from 'utils/httpClient';
import { SendToGCInput } from 'domain/sendToGc/schemas/sendToGCInput';

const gcServiceUrl = `${process.env.REACT_APP_GC_SERVICE_URL}`;

function sendFileToGc({
  files,
  notes,
  notesForAdvisorOnly,
  selectedCategory,
  selectedFinancialCenter,
  selectedBusiness,
  clientName,
  transactionTypes,
  demandTypes,
  transactionAmount,
  transactionDate,
  advisorLicenseNumber,
  sendToEquisoft,
  equisoftDocuments
}: SendToGCInput) {
  const formData = new FormData();
  let bidFormEquisoft = null;

  if(equisoftDocuments) {
    bidFormEquisoft = JSON.parse(JSON.stringify(equisoftDocuments));
    for (let i = 0; i < equisoftDocuments.length; i++) {
      const typesArray = equisoftDocuments[i].type?.value.split("-/-");
      if(typesArray) {
        bidFormEquisoft[i].type = typesArray[0]?.trim();
        bidFormEquisoft[i].subType = typesArray[1]?.trim();
      } else {
        bidFormEquisoft[i].type = equisoftDocuments[i].type?.value;
      }
      
      for (let j = 0; j < equisoftDocuments[i].files.length; j++) {
        bidFormEquisoft[i].files[j].filename = equisoftDocuments[i].files[j].file?.name;
        delete bidFormEquisoft[i].files[j].file;
      }
    }
  }

  const body = {
    notes: notes,
    notesForAdvisorOnly: notesForAdvisorOnly,
    client: clientName,
    businessUnitKeys: [selectedBusiness].map(selectedBusiness => selectedBusiness.value),
    categoryKey: selectedCategory.value,
    financialCenterKey: selectedFinancialCenter?.value,
    transactionType: transactionTypes?.length ?? 0 > 0 ? transactionTypes?.map(e => e.value).sort()[0] : '',
    demandTypes: demandTypes?.map(demandTypes => demandTypes.value),
    transactionAmount: transactionAmount,
    transactionDate: transactionDate,
    advisorLicenseNumber: advisorLicenseNumber?.value,
    sendToEquisoft: sendToEquisoft,
    bidFormEquisoft : sendToEquisoft ? bidFormEquisoft : []
  };

  formData.append('data', JSON.stringify(body));
  files?.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });

  return callApi('post', gcServiceUrl, 'bidform', formData, 'multipart/form-data');
 
}

export default sendFileToGc;
