import { useMutation } from 'react-query';
import generateEquisoftConfig from '../api/generateEquisoftConfig';
import { useNavigate } from 'react-router-dom';

function useGenerateEquisoftConfig() {
  const navigate = useNavigate();
  return useMutation(generateEquisoftConfig, {
    onSuccess: () => navigate('/profile'),
    onError: () => navigate('/profile'),
  });
}

export default useGenerateEquisoftConfig;
