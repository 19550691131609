import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';
import { RedirectLocationState } from 'types/auth';
import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import gcLogo from 'assets/img/logo-cloutier-dark.png';
import styles from 'styles/domain/auth/signin-form.module.scss';
import Button from 'components/inputs/Button';
import { useMutation } from 'react-query';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { useSnackbarContext } from 'hooks/useSnackbarContext';

declare type IFormInputs = {
  email: string;
  password: string;
};

function SignInForm() {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuthContext();
  const { register, handleSubmit } = useForm<IFormInputs>();
  const { showSnackbar } = useSnackbarContext();

  const from = (location.state as RedirectLocationState)
    ? (location.state as RedirectLocationState)?.from?.pathname ?? '/'
    : '/';

  const handleSignIn: SubmitHandler<IFormInputs> = async ({ email, password }) => {
    await signIn(email, password);
  };

  const { isLoading, mutate } = useMutation(handleSignIn, {
    onSuccess: () => navigate(from),
    onError: () => showSnackbar({ text: t('invalidLogin'), severity: 'danger' }),
  });

  const { form, input, titleContainer, forgottenPassword } = styles;

  return (
    <form className={form} onSubmit={handleSubmit(data => mutate(data))}>
      <div className={titleContainer}>
        <img src={gcLogo} />
        <h1>{t('appName', { ns: 'common' })}</h1>
        <h2>{'2.0'}</h2>
      </div>
      <h1>{t('advisorAccess')}</h1>
      <label>
        {t('email')}
        <input className={input} type={'text'} {...register('email', { required: true })} />
      </label>
      <label>
        {t('password')}
        <input className={input} type={'password'} {...register('password', { required: true })} />
      </label>
      <a
        href="https://boiteaoutils.groupecloutier.com/loginConseiller.aspx?mdpo=true"
        target="_blank"
        className={forgottenPassword}
        rel="noreferrer"
      >
        {t('forgottenPassword')}
      </a>
      <Button disabled={isLoading} type="submit">
        {isLoading ? (
          <div>
            <LoadingSpinner isMini isWhite />
          </div>
        ) : (
          t('signIn')
        )}
      </Button>
    </form>
  );
}

export default SignInForm;
