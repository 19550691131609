import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'styles/domain/profile/profile.module.scss';
import titleStyles from 'styles/components/pages.module.scss';
import ProfileInfo from 'domain/profile/components/ProfileInfo';
import LanguageButton from 'components/navigation/menu/LanguageButton';
import ProfileEquisoft from '../../equisoft/component/ProfileEquisoft';
import { useLocation } from 'react-router-dom';
import useIsTester from 'domain/sendToGc/hooks/useIsTester';

function ProfilePage() {
  const { t } = useTranslation('profile');

  const { title, titleNoBorder } = titleStyles;
  const { container, wrapper, header, personalInfo, editProfile, refreshWrapper } = styles;
  const [triggerRefreshAction, setTriggerRefreshAction] = useState(false);
  const location = useLocation();
  const isTester = useIsTester();

  useEffect(() => {
    const currentUrl = location.pathname + location.hash;
    const isRefresh = currentUrl.includes('/profile#refresh');
    setTriggerRefreshAction(isRefresh);
    if (isRefresh) {
      const section = document.getElementById('refresh');
      if (section) {
        setTimeout(() => {
          section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 200);
      }
    }
  }, [location]);

  return (
    <>
      <h1 className={`${title} ${titleNoBorder}`}>{t('profile')}</h1>
      <div className={container}>
        <div className={wrapper}>
          <div className={header}>
            <h2 className={personalInfo}>{t('personnalInformation')}</h2>
            <a
              className={editProfile}
              href={'https://boiteaoutils.groupecloutier.com/fr/profil.aspx'}
              target="_blank"
              rel="noreferrer"
            >
              {t('editMyProfile')}
            </a>
            <LanguageButton />
          </div>
          <ProfileInfo />
        </div>
        {
          isTester &&
          <div className={`${wrapper} ${triggerRefreshAction ? refreshWrapper : ''}`}>
            <ProfileEquisoft />
          </div>
        }

      </div>
    </>
  );
}

export default ProfilePage;
