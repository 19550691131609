import React, { useMemo } from 'react';
import styles from 'styles/domain/sellingTools/table/selling-tool-doc-table.module.scss';
import DocumentListItem from 'components/shared/list/DocumentListItem';
import useGetSectionDocuments from 'domain/sellingTools/hooks/useGetSectionDocuments';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGetFavoriteDocuments from 'domain/favorites/hooks/useGetFavoriteDocuments';

type Props = {
  documentSectionKey: string;
};

function DocumentList({ documentSectionKey }: Props) {
  const { table, spinnerContainer } = styles;

  const { data: favoriteDocuments } = useGetFavoriteDocuments();

  const memoizedFavoriteDocumentsSet = useMemo(
    () => new Set<string>(favoriteDocuments?.map(document => document.publicId)),
    [favoriteDocuments],
  );

  const { data: documents, isLoading } = useGetSectionDocuments(documentSectionKey);

  const mappedDocuments = useMemo(
    () =>
      documents?.map(document => ({
        ...document,
        isFavorite: memoizedFavoriteDocumentsSet.has(document.publicId),
      })),
    [documents, memoizedFavoriteDocumentsSet],
  );

  return (
    <div className={table}>
      {isLoading && (
        <div className={spinnerContainer}>
          <LoadingSpinner />
        </div>
      )}
      {mappedDocuments?.map((document, i) => (
        <DocumentListItem document={document} key={i} />
      ))}
    </div>
  );
}

export default DocumentList;
