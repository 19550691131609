import { useTranslation } from 'react-i18next';
import React from 'react';
import styles from 'styles/domain/auth/signin-page.module.scss';
import SignInForm from 'domain/auth/components/SignInForm';
import Button from 'components/inputs/Button';
import Icon from 'components/icons/Icon';

const oldBaoSignupLink = `${process.env.REACT_APP_OLD_BAO_BASE_LINK}${process.env.REACT_APP_OLD_BAO_SIGNUP_LINK}`;

function SignInPage() {
  const { t } = useTranslation('auth');

  const { container, divContainer, signUpContainer, backContainer, formContainer } = styles;
  return (
    <div className={container}>
      <div className={formContainer}>
        <a className={backContainer} href={'https://www.groupecloutier.com/accueil'}>
          <Icon name={'leftArrow'} />
          {t('backToMainWebsite')}
        </a>
        <SignInForm />
      </div>
      <div className={divContainer}>
        <div className={signUpContainer}>
          <h1>{t('advisorSignup')}</h1>
          <p>{t('signupInfoText')}</p>
          <Button onClick={() => window.location.replace(oldBaoSignupLink)}>{t('signUp')}</Button>
        </div>
      </div>
    </div>
  );
}

export default SignInPage;
