import { useTranslation } from 'react-i18next';

export enum DocType {
  LINK = 'link',
  DOCUMENT = 'document',
}

function useGetDocTypes() {
  const { t } = useTranslation('genericItem');

  return [
    {
      value: DocType.LINK,
      label: t('link'),
    },
    {
      value: DocType.DOCUMENT,
      label: t('document'),
    },
  ];
}

export default useGetDocTypes;
