import { useQuery, UseQueryResult } from 'react-query';
import getSectionDocuments from 'domain/sellingTools/api/getSectionDocuments';
import { SectionDocument } from 'types/document';

export const getSectionDocumentsQueryKey = 'getSectionDocument';

function useGetSectionDocuments(sectionKey: string): UseQueryResult<SectionDocument[]> {
  return useQuery([`${getSectionDocumentsQueryKey}${sectionKey}`, sectionKey], async () =>
    getSectionDocuments(sectionKey),
  );
}

export default useGetSectionDocuments;
