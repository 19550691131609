import React, { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'styles/domain/profile/profile-info.module.scss';
import editImageLogo from 'assets/img/logo-edit-image-.png';
import useUpdateProfileAvatar from 'domain/profile/hooks/useUpdateProfileAvatar';
import useGetProfileAvatar from 'domain/profile/hooks/useGetProfileAvatar';
import useGetProfileInfo from 'domain/profile/hooks/useGetProfileInfo';
import LoadingSpinner from 'components/shared/LoadingSpinner';

function ProfileInfo() {
  const { t } = useTranslation('profile');
  const {
    grid,
    picNameWrapper,
    loadingWrapper,
    avatarImage,
    profileName,
    infoWrapper,
    label,
    field,
    editImage,
    editImageWrapper,
  } = styles;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { data, isLoading: isProfileLoading } = useGetProfileAvatar();
  const { data: user, isLoading: isAvatarLoading } = useGetProfileInfo();

  const { mutate, isLoading: isAvatarUpdating } = useUpdateProfileAvatar();

  const updateImage = (file: File) => {
    const reader = new FileReader();      
    reader.readAsDataURL(file);
    reader.onload = function () {
      const base64String = reader.result as string;
      const strImage = base64String.replace(/^data:image\/[a-z]+;base64,/, '');
      const stripedType = file.type.replace(/^image\//, '.');
      mutate({ fileExtension: stripedType, imageContent: strImage });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const onNewImage = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.item(0);
    if (image) {
      updateImage(image);
    }
  };

  if (isProfileLoading || isAvatarLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <div className={grid}>
        <div className={picNameWrapper}>
          {isAvatarUpdating ? (
            <div className={loadingWrapper}>
              <LoadingSpinner />
            </div>
          ) : (
            <span className={editImageWrapper}>
              <img
                className={avatarImage}
                src={`data:${data?.mimeType};base64, ${data?.contentAsBase64}`}
                alt={'ProfilePicture'}
              />
              <span className={editImage}>
                <img src={editImageLogo} alt="Edit logo" onClick={() => fileInputRef?.current?.click()} />
              </span>
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                ref={fileInputRef}
                onChange={onNewImage}
                hidden
              />
            </span>
          )}
          <h1 className={profileName}>{`${user?.firstName} ${user?.lastName}`}</h1>
        </div>
        <div className={infoWrapper}>
          <div>
            <p className={label}>{t('emailAdress')}</p>
            <p className={field}>{user?.email}</p>
          </div>
          <div>
            <p className={label}>{t('phoneNumber')}</p>
            <p className={field}>{user?.phoneNumber}</p>
          </div>
          <div>
            <p className={label}>{t('address')}</p>
            <p className={field}>{user?.address}</p>
          </div>
          <div>
            <p className={label}>{t('zipCode')}</p>
            <p className={field}>{user?.zipCode}</p>
          </div>
          <div>
            <p className={label}>{t('city')}</p>
            <p className={field}>{user?.city}</p>
          </div>
          <div>
            <p className={label}>{t('province')}</p>
            <p className={field}>{user?.state}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileInfo;
