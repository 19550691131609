import React from 'react';
import styles from 'styles/components/typography/confirmation-modal-text.module.scss';

export function ConfirmationModalText({ message, title }: { message: string; title?: string }) {
  return (
    <>
      {title && <h2 className={styles.title}>{title}</h2>}
      <p className={styles.text}>{message}</p>
    </>
  );
}
