import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from 'router/Router';
import AuthProvider from 'domain/auth/contexts/AuthProvider';
import 'styles/app.scss';
import { MockProvider } from 'domain/contexts/MockProvider';
import TranslationProvider from 'contexts/TranslationProvider';
import SnackbarProvider from 'contexts/SnackbarProvider';
import ReactGA from 'react-ga4';
import ModalProvider from 'contexts/ModalProvider';

ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`);

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <MockProvider>
          <TranslationProvider>
            <SnackbarProvider>
              <ModalProvider>
                <Router />
              </ModalProvider>
            </SnackbarProvider>
          </TranslationProvider>
        </MockProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
