import React, { memo } from 'react';
import WarningBar, { WarningLevel } from 'components/shared/WarningBar';
import useGetNotes from 'domain/alert/hooks/useGetNotes';
import compareDateToToday from 'utils/compareDateToToday';

function WarningWrapper() {
  const { data: notes } = useGetNotes();

  return (
    <div>
      {notes
        ?.filter(note => compareDateToToday(note.expirationDatetimeUtc) && note.isVisible)
        .map((note, index) => (
          <WarningBar
            level={note.content as WarningLevel}
            message={note.title}
            isClosable={true}
            isGlobal={true}
            key={index}
            link={note.externalInformationUrl}
          />
        ))}
    </div>
  );
}

export default memo(WarningWrapper);
