import { z } from 'zod';
import { Errors } from 'types/errors';

export declare type EventAndNewsType =
  | 'PeopleInsurance'
  | 'Investments'
  | 'GroupInsuranceAndAnnuities'
  | 'Mortgage'
  | 'ContestAndTraining'
  | 'General';

export const newsArticleSchema = z.object({
  displayStartDatetimeUtc: z.preprocess(arg => arg + 'Z', z.string().datetime()),
  displayEndDatetimeUtc: z.preprocess(arg => arg + 'Z', z.string().datetime()),
  isVisible: z.boolean().optional(),
  title: z.string(),
  content: z.string(),
  externalInformationUrl: z.string().url(Errors.INVALID).optional().or(z.literal('')),
  imageUrl: z.string(),
  venueId: z.string().uuid().optional().nullable(),
  businessUnitIds: z.array(z.string().uuid()),
  publicId: z.string().uuid().optional(),
});

export type NewsArticle = z.infer<typeof newsArticleSchema>;
