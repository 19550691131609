import { useMutation } from 'react-query';
import getEquisoftAuthLink from '../api/getEquisoftAuthLink';
import { useTranslation } from 'react-i18next';
import { useShowOutputMessage } from 'hooks/useShowOutputMessage';

function useGetEquisoftAuthLink() {
    const { t } = useTranslation('profile');
    const { showConfirmationModal } = useShowOutputMessage();
  return useMutation(getEquisoftAuthLink, {
    onSuccess: (data) => window.location.href = data,
    onError: () => showConfirmationModal({ text: t('errorEquisoftAuthText'), severity: 'danger', submitText: t('submitText') }),
  });
}

export default useGetEquisoftAuthLink;
