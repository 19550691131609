import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'styles/components/pages.module.scss';
import supportPageStyles from 'styles/domain/support/support-page.module.scss';
import Tabs, { TabsType } from 'components/shared/Tabs';
import RessourcesRepertory from 'domain/support/components/RessourcesRepertory';
import { FinancialCenter } from 'types/FinancialCenter';

function SupportPage() {
  const { t } = useTranslation('support');

  const { container } = supportPageStyles;

  const tabs: TabsType[] = [
    {
      label: t('montreal', { ns: 'financialCenter' }),
      index: 0,
      Component: <RessourcesRepertory financialCenter={FinancialCenter.MONTREAL} />,
    },
    {
      label: t('outaouais', { ns: 'financialCenter' }),
      index: 1,
      Component: <RessourcesRepertory financialCenter={FinancialCenter.OUTAOUAIS} />,
    },
    {
      label: t('quebec', { ns: 'financialCenter' }),
      index: 2,
      Component: <RessourcesRepertory financialCenter={FinancialCenter.QUEBEC} />,
    },
    {
      label: t('sherbrooke', { ns: 'financialCenter' }),
      index: 3,
      Component: <RessourcesRepertory financialCenter={FinancialCenter.SHERBROOKE} />,
    },
    {
      label: t('stHyacinthe', { ns: 'financialCenter' }),
      index: 4,
      Component: <RessourcesRepertory financialCenter={FinancialCenter.ST_HYACINTHE} />,
    },
    {
      label: t('troisRivieres', { ns: 'financialCenter' }),
      index: 5,
      Component: <RessourcesRepertory financialCenter={FinancialCenter.TROIS_RIVIERES} />,
    },
  ];

  return (
    <>
      <h1 className={styles.title}>{t('ressourcesRepertory')}</h1>
      <div className={container}>
        <Tabs tabs={tabs} />
      </div>
    </>
  );
}

export default SupportPage;
