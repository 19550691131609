import { MutableRefObject, useEffect } from 'react';

type Params = {
  ref: MutableRefObject<HTMLElement | null>;
  callback: () => void;
  enabled: boolean;
};

export function useHandleOutOfComponentClick({ ref, callback, enabled }: Params) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (enabled && ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, enabled]);
}
