import { useMutation, UseMutationOptions } from 'react-query';
import addDocument from 'domain/sellingTools/api/addDocument';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTranslation } from 'react-i18next';

function useAddDocument(options?: UseMutationOptions<unknown, unknown, unknown>) {
  const { t } = useTranslation('genericItem');
  const { showSnackbar } = useSnackbarContext();

  return useMutation(addDocument, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
      showSnackbar({ text: t('addDocumentMessage', { ns: 'admin' }), severity: 'success' });
    },
    onError: (error, variables, context) => {
      if (options?.onError) {
        options.onError(error, variables, context);
      }
      showSnackbar({ text: t('failedToAddDocumentMessage', { ns: 'admin' }), severity: 'danger' });
    },
  });
}

export default useAddDocument;
