import React from 'react';
import Icon from 'components/icons/Icon';
import { useTranslation } from 'react-i18next';
import styles from 'styles/components/navigation/toggle-menu-button.module.scss';

type Props = {
  setMenuOpen: () => void;
  isMenuOpen: boolean;
};

function ToggleMenuButton({ setMenuOpen, isMenuOpen }: Props) {
  const { t } = useTranslation('navigation');

  const { button } = styles;

  const toggleMenu = () => {
    setMenuOpen();
  };
  return (
    <button onClick={toggleMenu} className={button}>
      <Icon name={isMenuOpen ? 'collapseMenu' : 'openMenu'} />
      <p>{t('collapseMenu')}</p>
    </button>
  );
}

export default ToggleMenuButton;
