import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from 'locales/resources';
import { defaultLanguage, en, fr, defaultNamespace } from 'locales/config';

use(initReactI18next).init({
  lng: defaultLanguage,
  fallbackLng: fr,
  ns: defaultNamespace,
  defaultNS: defaultNamespace,
  resources,
  supportedLngs: [fr, en],
});
