import { useMutation } from 'react-query';
import addNewArticle from 'domain/home/api/addNewArticle';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTranslation } from 'react-i18next';

function useAddNews() {
  const { t } = useTranslation('admin');
  const { showSnackbar } = useSnackbarContext();

  return useMutation(addNewArticle, {
    onSuccess: () => showSnackbar({ text: t('addNewsMessage'), severity: 'success' }),
    onError: () => showSnackbar({ text: t('failedToAddNewsMessage'), severity: 'danger' }),
  });
}

export default useAddNews;
