import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import removeNews from 'domain/home/api/removeNews';
import { newsFeedQueryKey } from 'domain/home/hooks/useGetNewsFeed';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTranslation } from 'react-i18next';

function useRemoveNews(options?: UseMutationOptions<unknown, unknown, unknown>) {
  const queryClient = useQueryClient();
  const { t } = useTranslation('common');
  const { showSnackbar } = useSnackbarContext();

  return useMutation(removeNews, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
      showSnackbar({ text: t('removeNewsMessage', { ns: 'admin' }), severity: 'success' });
      return queryClient.invalidateQueries([newsFeedQueryKey]);
    },
    onError: (error, variables, context) => {
      if (options?.onError) {
        options.onError(error, variables, context);
      }
      showSnackbar({ text: t('failedToRemoveNewsMessage', { ns: 'admin' }), severity: 'success' });
    },
  });
}

export default useRemoveNews;
