import React from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import useGetErrorMessage from 'components/forms/hooks/useGetErrorMessage';
import DateTimeRangeInput, { DateTimeRangeInputProps } from 'components/inputs/DateTimeRangeInput';

type DateTimeRangeFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<DateTimeRangeInputProps, 'value'> & UseControllerProps<TFieldValues, TName>;

function DateTimeRangeField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, ...props }: DateTimeRangeFieldProps<TFieldValues, TName>) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name });
  const getErrorMessage = useGetErrorMessage(props.label);

  const errorMessage = error ? getErrorMessage(error.message) : undefined;

  return <DateTimeRangeInput errorMessage={errorMessage} onChange={onChange} value={value} {...props} />;
}

export default DateTimeRangeField;
