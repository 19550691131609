import { z } from 'zod';

const selectOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

type SelectOption = z.infer<typeof selectOptionSchema>;

export type { SelectOption };
export { selectOptionSchema };
