import { BusinessUnit, businessUnitSchema } from 'types/businessUnit';
import { z } from 'zod';
import { callApi } from 'utils/httpClient';

const wcmServiceUrl = `${process.env.REACT_APP_WCM_SERVICE_URL}`;

async function getBusinessUnit(): Promise<BusinessUnit[]> {
  const { data } = await callApi('get', wcmServiceUrl, 'businessUnit', {});
  return z.array(businessUnitSchema).parse(data);
}

export default getBusinessUnit;
