import { z } from 'zod';

export const bidActionsSchema = z.object({
  publicId: z.string().uuid(),
  concurrencyId: z.string(),
  businessUnitKey: z.string(),
  categoryKey: z.string(),
  financialCenterKey: z.string(),
  configuration: z.string(),
});

export type BidActions = z.infer<typeof bidActionsSchema>;
