import React, { ReactElement, useState } from 'react';
import styles from 'styles/domain/alert/admin/alert-admin-table.module.scss';
import Table from 'components/shared/Table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import useGetNotes from 'domain/alert/hooks/useGetNotes';
import moment from 'moment/moment';
import Icon from 'components/icons/Icon';
import useDeleteNote from 'domain/alert/hooks/useDeleteNote';
import { useShowConfirmationModal } from 'hooks/useShowConfirmationModal';

type NoteTable = {
  title: string;
  type: string;
  link: ReactElement;
  expirationDate: string;
  button: ReactElement;
};

function AlertAdminTable() {
  const { table, removeBtn } = styles;
  const { t } = useTranslation('admin');
  const [noteToDeleteId, setNoteToDeleteId] = useState('');
  const { mutate } = useDeleteNote();
  const { data: notes } = useGetNotes();
  const { showConfirmationModal } = useShowConfirmationModal();

  const onDeleteNoteConfirm = () => {
    if (noteToDeleteId) {
      mutate(noteToDeleteId);
    }
  };

  const onRemoveNote = (id: string) => {
    setNoteToDeleteId(id);
    showConfirmationModal({
      message: t('deleteNoteConfirmationMessage', { ns: 'forms' }),
      onSubmit: onDeleteNoteConfirm,
      submitText: t('delete', { ns: 'common' }),
    });
  };

  const tableNotes = notes
    ? notes.map(n => ({
        title: n.title,
        type: n.content,
        link: (
          <a href={n.externalInformationUrl} target="_blank" rel="noreferrer">
            {t('link')}
          </a>
        ),
        expirationDate: n.expirationDatetimeUtc,
        id: n.publicId,
        button: (
          <button className={removeBtn} onClick={() => onRemoveNote(n.publicId ?? '')}>
            <Icon name="remove" />
          </button>
        ),
      }))
    : [];

  const columnHelper = createColumnHelper<NoteTable>();

  // TODO : Handle the date and time better
  const columns = [
    columnHelper.accessor('title', {
      header: () => t('title'),
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('type', {
      header: () => t('type'),
      cell: info => t(info.getValue(), { ns: 'common' }),
    }),
    columnHelper.accessor('expirationDate', {
      header: () => t('endDate'),
      cell: info => moment.utc(info.getValue()).toDate().toString().split('GMT')[0],
    }),
    columnHelper.accessor('link', {
      header: () => t('link'),
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('button', {
      header: '',
      cell: info => info.getValue(),
    }),
  ];

  return <Table columns={columns} data={tableNotes ?? []} className={table} />;
}

export default AlertAdminTable;
