import React from 'react';
import { TransactionDocument } from 'types/transaction';
import useDownload from 'utils/useDownload';
import Icon from 'components/icons/Icon';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import styles from 'styles/components/layout/favoritesBar/document-element.module.scss';

type Props = {
  document: TransactionDocument;
};

function TransactionDocumentItem({ document }: Props) {
  const { handleDownload } = useDownload();

  const { refetch: downloadDocument, isFetching: isDownloading } = handleDownload(
    document.contentUrl,
    document.contentUrl,
    document.filename,
    true,
  );

  const { container, button } = styles;
  return (
    <div className={container}>
      <span>{document.filename}</span>
      <button className={button} onClick={() => downloadDocument()}>
        {isDownloading ? <LoadingSpinner isMini /> : <Icon name={'download'} />}
      </button>
    </div>
  );
}

export default TransactionDocumentItem;
