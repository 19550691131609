import { callApi } from 'utils/httpClient';
import { Transaction, transactionSchema } from 'types/transaction';
import { z } from 'zod';

const gcServiceUrl = `${process.env.REACT_APP_GC_SERVICE_URL}`;

export type TransactionsGroup = 'all' | 'mine';

async function getTransactions(transactionsGroup: TransactionsGroup): Promise<Transaction[]> {
  const { data } = await callApi('get', gcServiceUrl, `bidform${transactionsGroup === 'mine' ? '/mine' : ''}`);
  return z.array(transactionSchema).parse(data);
}

export default getTransactions;
