import React from 'react';
import { useTranslation } from 'react-i18next';
import Tabs, { TabsType } from 'components/shared/Tabs';
import TransactionsTable from 'domain/transactions/components/TransactionsTable';
import { UserRole } from 'types/auth';

function AdminTransactionsTabs() {
  const { t } = useTranslation('transactions');

  const adminTabs: TabsType[] = [
    {
      label: t('allTransactions'),
      index: 0,
      Component: <TransactionsTable transactionsGroup={'all'} userRole={UserRole.ADMIN} />,
    },
    {
      label: t('receivedTransactions'),
      index: 1,
      Component: <TransactionsTable transactionsGroup={'mine'} userRole={UserRole.ADMIN} />,
    },
  ];
  return <Tabs tabs={adminTabs} />;
}

export default AdminTransactionsTabs;
