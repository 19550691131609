import React from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import SingleFileInput, { type SingleFileInputProps } from 'components/inputs/SingleFileInput';
import useGetErrorMessage from 'components/forms/hooks/useGetErrorMessage';

type SingleFileFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<SingleFileInputProps, 'onChange' | 'value'> & UseControllerProps<TFieldValues, TName>;

function SingleFileField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, ...props }: SingleFileFieldProps<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });
  const getErrorMessage = useGetErrorMessage(props.label);

  const errorMessage = error ? getErrorMessage(error.message) : undefined;

  return <SingleFileInput errorMessage={errorMessage} {...props} {...field} />;
}

export default SingleFileField;
