import React from 'react';
import styles from 'styles/components/inputs/date-input.module.scss';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import DatePicker, { type DatePickerProps } from 'react-date-picker';
import RequiredMarker from 'components/typography/RequiredMarker';

type DateOnlyInputProps = {
  value: Date;
  label: string;
  errorMessage?: string;
  isRequired?: boolean;
} & Omit<DatePickerProps, 'value'>;

function DateOnlyInput({ label, errorMessage, isRequired, ...props }: DateOnlyInputProps) {
  return (
    <div className={styles.dateField}>
      <label className={styles.inputLabel}>
        {label} <RequiredMarker isRequired={isRequired} />
      </label>
      <DatePicker className={styles.datePicker} {...props} />
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
}

export type { DateOnlyInputProps };
export default DateOnlyInput;
