import React from 'react';
import styles from 'styles/components/inputs/text-input.module.scss';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import RequiredMarker from 'components/typography/RequiredMarker';

type TextInputProps = {
  label: string;
  errorMessage?: string;
  isRequired?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, errorMessage, isRequired, ...props }, ref) => {
    return (
      <div className={styles.wrapper}>
        <label className={styles.label}>
          {label} <RequiredMarker isRequired={isRequired} />
        </label>
        <input className={styles.input} ref={ref} {...props} />
        <ErrorMessage errorMessage={errorMessage} />
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';

export type { TextInputProps };
export default TextInput;
