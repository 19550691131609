import { useMutation, useQueryClient } from 'react-query';
import deleteDocument from 'domain/sellingTools/api/deleteDocument';
import { getSectionDocumentsQueryKey } from 'domain/sellingTools/hooks/useGetSectionDocuments';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { getRecentDocumentsQueryKey } from 'domain/favorites/hooks/useGetRecentDocuments';
import { getFavoriteDocumentsQueryKey } from 'domain/favorites/hooks/useGetFavoriteDocuments';

function useDeleteDocument(sectionKey: string) {
  const { t } = useTranslation('genericItem');
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbarContext();

  return useMutation(deleteDocument, {
    onSuccess: () => {
      showSnackbar({ text: t('deleteDocumentMessage'), severity: 'success' });
      queryClient.invalidateQueries(`${getSectionDocumentsQueryKey}${sectionKey}`);
      queryClient.invalidateQueries(getRecentDocumentsQueryKey);
      queryClient.invalidateQueries(getFavoriteDocumentsQueryKey);
    },
    onError: () => showSnackbar({ text: t('failedToDeleteDocumentMessage'), severity: 'danger' }),
  });
}

export default useDeleteDocument;
