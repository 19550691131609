import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'styles/components/layout/modal.module.scss';
import Icon from 'components/icons/Icon';
import Button from 'components/inputs/Button';

export type ModalProps = {
  readonly onClose: () => unknown;
  readonly isInFront: boolean;
  readonly onSubmit?: () => unknown;
  readonly onCancel?: () => unknown;
  readonly submitText?: string;
  readonly cancelText?: string;
  readonly showCloseBtn?: boolean;
  readonly cancellable?: boolean;
  readonly closeOnSubmit?: boolean;
};

export default function Modal({
  children,
  onClose,
  isInFront,
  onSubmit,
  onCancel,
  submitText,
  cancelText,
  showCloseBtn = true,
  cancellable = true,
  closeOnSubmit = true,
}: React.PropsWithChildren<ModalProps>) {
  const { t } = useTranslation();

  function handleCancel() {
    onCancel?.();
    onClose();
  }

  function handleSubmit() {
    onSubmit?.();
    if (closeOnSubmit) {
      onClose();
    }
  }

  return (
    <div className={styles.container} style={{ zIndex: isInFront ? 10002 : 10001 }}>
      {showCloseBtn && (
        <div className={styles.closeBtn} onClick={onClose}>
          <Icon name="remove" />
        </div>
      )}
      {children}
      {(cancellable || onSubmit) && (
        <div className={styles.buttonsWrapper}>
          {onSubmit && (
            <Button aria-label={submitText ?? t('submit')} onClick={handleSubmit}>
              {submitText ?? t('submit')}
            </Button>
          )}
          {cancellable && (
            <Button btnType="secondary" aria-label={cancelText ?? t('cancel')} onClick={handleCancel}>
              {cancelText ?? t('cancel')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
