import { callApi } from 'utils/httpClient';
import { Note } from 'types/note';

const wcmServiceUrl = `${process.env.REACT_APP_WCM_SERVICE_URL}`;

async function addNote(note: Note) {
  await callApi('post', wcmServiceUrl, 'note', note);
}

export default addNote;
