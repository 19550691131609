import { useMutation } from 'react-query';
import addMeet from 'domain/calendar/api/addMeet';
import { useTranslation } from 'react-i18next';
import { useSnackbarContext } from 'hooks/useSnackbarContext';

function useAddEvents() {
  const { t } = useTranslation('admin');
  const { showSnackbar } = useSnackbarContext();

  return useMutation(addMeet, {
    onSuccess: () => showSnackbar({ text: t('addEventMessage'), severity: 'success' }),
    onError: () => showSnackbar({ text: t('failedToAddEventMessage'), severity: 'danger' }),
  });
}

export default useAddEvents;
