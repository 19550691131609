import * as React from 'react';
import { Alert, AlertProps } from 'components/feedback/Alert';
import styles from 'styles/components/feedback/snackbar.module.scss';

type SnackbarProps = {
  readonly duration: number; // ms
  readonly onClick: () => unknown;
} & Omit<AlertProps, 'removable'>;

function Snackbar({ duration, onClick, ...alertProps }: SnackbarProps) {
  return (
    <div onClick={onClick} className={styles.container} style={{ animation: `brief-appear ${duration}ms forwards` }}>
      <Alert removable {...alertProps} />
    </div>
  );
}

export type { SnackbarProps };
export { Snackbar };
