import { callApi } from 'utils/httpClient';
import { Transaction, transactionSchema } from 'types/transaction';

const gcServiceUrl = `${process.env.REACT_APP_GC_SERVICE_URL}`;

async function getTransactions(transactionId: string): Promise<Transaction> {
  const { data } = await callApi('get', gcServiceUrl, `bidform/${transactionId}`);
  return transactionSchema.parse(data);
}

export default getTransactions;
