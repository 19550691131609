import * as React from 'react';
import { ConvertedMeetEvent } from 'domain/calendar/utils/convertEventForCalendar';
import { useTranslation } from 'react-i18next';
import { useShowConfirmationModal } from 'hooks/useShowConfirmationModal';
import useDeleteEvent from 'domain/calendar/hooks/useDeleteEvent';
import EventRegistration from 'domain/calendar/components/EventRegistration';

type Props = {
  readonly event: ConvertedMeetEvent;
  readonly hideParentModal: () => unknown;
};

export function EventRegistrationModalContent({ event, hideParentModal }: Props) {
  const { t } = useTranslation('admin');
  const { showConfirmationModal } = useShowConfirmationModal();
  const { mutate } = useDeleteEvent();

  const onDeleteButtonClick = (eventToDelete: ConvertedMeetEvent) => {
    showConfirmationModal({
      message: t('deleteEventConfirmationMessage', { ns: 'forms' }),
      submitText: t('delete', { ns: 'common' }),
      onSubmit: () => {
        mutate(eventToDelete.publicId ?? '');
        hideParentModal();
      },
      hasParentModal: true,
    });
  };

  return (
    <EventRegistration
      src={event.externalInformationUrl ?? ''}
      onDeleteButtonClick={() => onDeleteButtonClick(event)}
    />
  );
}
