import React, { memo, useEffect } from 'react';
import styles from 'styles/components/layout/layout.module.scss';
import { Header, NavigationSidebar, FavoritesSidebar } from 'components/layouts';
import { Outlet, useLocation } from 'react-router-dom';
import MobileHeader from 'components/layouts/MobileHeader';
import MobileBottomBar from 'components/layouts/favoritesSidebar/MobileBottomBar';
import ReactGA from 'react-ga4';
import MobileDocumentsMenu from 'components/layouts/favoritesSidebar/MobileDocumentsMenu';
import useLayout from 'components/layouts/hooks/useLayout';
import WarningWrapper from 'components/layouts/WarningWrapper';

function Layout() {
  const location = useLocation();

  const {
    toggleMobileRecentMenu,
    closeSidebarOnMobileLinkClick,
    toggleMobileFavoriteMenu,
    toggleSidebar,
    documentsMenuShown,
    isMobileFavoriteMenuOpen,
    isNavigationSidebarOpen,
    isMobileRecentMenuOpen,
  } = useLayout();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return (
    <>
      <WarningWrapper />
      <div className={styles.layout}>
        <NavigationSidebar
          setSidebarOpen={toggleSidebar}
          isOpen={isNavigationSidebarOpen}
          onMenuLinkClick={closeSidebarOnMobileLinkClick}
        />
        <div className={isNavigationSidebarOpen ? styles.wrapperOpen : styles.wrapper}>
          <MobileHeader onAppHeaderClick={closeSidebarOnMobileLinkClick} />
          <Header />
          <div>
            <div className={styles.children}>
              <Outlet />
            </div>
          </div>
        </div>
        <FavoritesSidebar />
        <MobileDocumentsMenu
          isOpen={isMobileFavoriteMenuOpen || isMobileRecentMenuOpen}
          documentType={documentsMenuShown}
        />
        <MobileBottomBar
          setMobileMenuOpen={toggleSidebar}
          toggleFavoritesMenu={toggleMobileFavoriteMenu}
          toggleRecentsMenu={toggleMobileRecentMenu}
          isMenuOpen={isNavigationSidebarOpen}
          isFavoriteMenuOpen={isMobileFavoriteMenuOpen}
          isRecentsMenuOpen={isMobileRecentMenuOpen}
        />
      </div>
    </>
  );
}

export default memo(Layout);
