import React from 'react';
import styles from 'styles/components/inputs/file-input.module.scss';
import FileDropMessage from 'components/inputs/fileInput/FileDropMessage';
import AddedFileList from 'components/inputs/fileInput/AddedFileList';
import { Label } from 'components/typography/Label';
import RequiredMarker from 'components/typography/RequiredMarker';

type MultiFileInputProps = {
  label: string;
  errorMessage?: string;
  isRequired?: boolean;
  fileDropMessage: string;
  value?: File[];
  onChange: (files: File[]) => unknown;
  acceptedFiles?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;

const MultiFileInput = React.forwardRef<HTMLInputElement, MultiFileInputProps>(
  (
    {
      label,
      errorMessage,
      isRequired,
      fileDropMessage,
      value = [],
      onChange,
      acceptedFiles = '.pdf, .docx, .zip',
      ...props
    },
    ref,
  ) => {
    function addFiles(files: FileList | null) {
      if (files) {
        onChange([...value, ...Object.values(files)]);
      }
    }

    function removeFile(index: number) {
      onChange([...value.slice(0, index), ...(index < value.length - 1 ? value.slice(index + 1) : [])]);
    }

    return (
      <div className={styles.wrapper}>
        <Label>
          {label} <RequiredMarker isRequired={isRequired} />
        </Label>
        <div className={`${styles.inputWrapper} ${errorMessage ? styles.invalid : ''}`}>
          <input
            type="file"
            className={styles.input}
            onChange={e => addFiles(e.target.files)}
            multiple
            accept={acceptedFiles}
            ref={ref}
            {...props}
          />
          <div className={styles.filesWrapper}>
            {!!value.length && <AddedFileList files={value} onRemove={removeFile} />}
            <FileDropMessage message={fileDropMessage} errorMessage={errorMessage} />
          </div>
        </div>
      </div>
    );
  },
);

MultiFileInput.displayName = 'MultiFileInput';

export type { MultiFileInputProps };
export default MultiFileInput;
