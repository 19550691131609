import React from 'react';
import styles from 'styles/components/logos/company-logo.module.scss';
import { ReactComponent as IaIcon } from 'assets/icons/providers/ia.svg';
import { ReactComponent as SunLifeIcon } from 'assets/icons/providers/sunlife.svg';
import { ReactComponent as UnknownIcon } from 'assets/icons/providers/unknown.svg';
import { ReactComponent as AxaIcon } from 'assets/icons/providers/axa.svg';
import { ReactComponent as AigIcon } from 'assets/icons/providers/aig.svg';
import { ReactComponent as AssomptionVieIcon } from 'assets/icons/providers/assomption-vie.svg';
import { ReactComponent as AsvIcon } from 'assets/icons/providers/asv.svg';
import { ReactComponent as BmoIcon } from 'assets/icons/providers/bmo.svg';
import { ReactComponent as CanadaLifeIcon } from 'assets/icons/providers/canada-vie.svg';
import { ReactComponent as BlueCrossIcon } from 'assets/icons/providers/croix-bleue.svg';
import { ReactComponent as DesjardinsIcon } from 'assets/icons/providers/desjardins.svg';
import { ReactComponent as EmpireVieIcon } from 'assets/icons/providers/empire-vie.svg';
import { ReactComponent as EquitableIcon } from 'assets/icons/providers/equitable.svg';
import { ReactComponent as ForestersIcon } from 'assets/icons/providers/foresters.svg';
import { ReactComponent as GreatWestIcon } from 'assets/icons/providers/great-west.svg';
import { ReactComponent as HumaniaIcon } from 'assets/icons/providers/humania.svg';
import { ReactComponent as IvariIcon } from 'assets/icons/providers/ivari.svg';
import { ReactComponent as LaCapitaleIcon } from 'assets/icons/providers/la-capitale.svg';
import { ReactComponent as RbcIcon } from 'assets/icons/providers/rbc.svg';
import { ReactComponent as SsqIcon } from 'assets/icons/providers/ssq.svg';
import { ReactComponent as StandardLifeIcon } from 'assets/icons/providers/standard-life.svg';
import { ReactComponent as UvIcon } from 'assets/icons/providers/uv.svg';
import { ReactComponent as GcIcon } from 'assets/icons/providers/gc.svg';

type Props = {
  provider: string;
};

const logos: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  '1': AigIcon,
  '2': AssomptionVieIcon,
  '3': AxaIcon,
  '4': AsvIcon,
  '5': BmoIcon,
  '6': CanadaLifeIcon,
  '8': BlueCrossIcon,
  '9': DesjardinsIcon,
  '11': EmpireVieIcon,
  '12': EquitableIcon,
  '13': ForestersIcon,
  '14': GreatWestIcon,
  '15': HumaniaIcon,
  '16': IvariIcon,
  '17': LaCapitaleIcon,
  '20': IaIcon,
  '24': RbcIcon,
  '25': SunLifeIcon,
  '26': StandardLifeIcon,
  '27': SsqIcon,
  '30': UvIcon,
  '999': GcIcon,
};

function CompanyLogo({ provider }: Props) {
  const Logo = logos[provider];

  return Logo ? <Logo className={styles.logo} /> : <UnknownIcon key="unknown" className={styles.logo} />;
}

export default CompanyLogo;
