import React from 'react';
import styles from 'styles/components/navigation/side-bar-navigation-group.module.scss';
import RouterLink from 'components/navigation/RouterLink';
import { Link } from 'types/navigation';

type Props = {
  links: Link[];
  isAtBottom?: boolean;
  onMenuItemClick: () => void;
  isMenuOpen: boolean;
};

function NavigationGroup({ links, isAtBottom, onMenuItemClick, isMenuOpen }: Props) {
  const { navOptionsList, navOptionsListClosed, navOption, selectedNavOption, bottomNavOptionsList, iconContainer } =
    styles;

  const onNavItemClick = () => {
    onMenuItemClick();
  };

  return (
    <div
      className={`${navOptionsList} ${isMenuOpen ? '' : navOptionsListClosed} ${
        isAtBottom ? bottomNavOptionsList : ''
      }`}
      onClick={onNavItemClick}
    >
      {links.map(({ to, text, icon, isActive }, i) => (
        <RouterLink to={to} key={i}>
          <div className={`${navOption} ${isActive ? selectedNavOption : ''} `}>
            <button className={iconContainer}>{icon}</button>
            <p>{text}</p>
          </div>
        </RouterLink>
      ))}
    </div>
  );
}

export default NavigationGroup;
