import { useMutation } from 'react-query';
import createEquisoftDocument from '../api/createEquisoftDocument';
import { useTranslation } from 'react-i18next';
import { useShowOutputMessage } from 'hooks/useShowOutputMessage';

function useCreateEquisoftDocument() {
    const { t } = useTranslation('sendtoGc');
    const { showConfirmationModal } = useShowOutputMessage();
  return useMutation(createEquisoftDocument, {
    onError: () => showConfirmationModal({ text: t('errorCreateDoc'), severity: 'danger', submitText: t('submitText') })
  })
}
export default useCreateEquisoftDocument;