import React, { lazy, useState } from 'react';
import { DocumentType, GenericDocument } from 'types/document';
import styles from 'styles/components/layout/favoritesBar/document-element.module.scss';
import useDownload from 'utils/useDownload';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useRemoveRecentDocument from 'domain/favorites/hooks/useRemoveRecentDocument';
import useRemoveFavoriteDocument from 'domain/favorites/hooks/useRemoveFavoriteDocument';
import { UseMutationResult } from 'react-query';
import isMobile from 'utils/isMobile';
import useAddRecentDocument from 'domain/favorites/hooks/useAddRecentDocument';
import DocumentIcon from 'components/icons/DocumentIcon';

const Icon = lazy(() => import('components/icons/Icon'));

type Props = {
  document: GenericDocument;
  type: 'favorite' | 'recent';
};

function DocumentElement({ document, type }: Props) {
  const [hovered, setHovered] = useState(false);

  const removeFunctions: Record<string, UseMutationResult<void, unknown, string>> = {
    favorite: useRemoveFavoriteDocument(),
    recent: useRemoveRecentDocument(),
  };

  const { handleDownload } = useDownload();
  const { refetch: downloadDocument, isFetching } = handleDownload(
    document.publicId,
    document.storageUrl,
    document.filename,
  );
  const { mutate: removeDocument, isLoading: isRemovingDocument } = removeFunctions[type];
  const { mutate: addRecentDocument } = useAddRecentDocument();

  const showButtonGroup = hovered || isMobile();

  const addToFavorite = () => {
    if (type === 'favorite') {
      addRecentDocument(document.publicId);
    }
  };

  const { container, button, buttonGroup, buttonLink } = styles;
  return (
    <div className={container} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <DocumentIcon documentType={document.documentType} />
      <span>{document.title}</span>
      {showButtonGroup && (
        <div className={buttonGroup}>
          <button className={button} aria-label={'remove-button'} onClick={() => removeDocument(document.publicId)}>
            {isRemovingDocument ? <LoadingSpinner isMini={true} /> : <Icon name={'remove'} />}
          </button>
          {document.documentType !== DocumentType.LINK ? (
            <button
              className={button}
              onClick={() => {
                addToFavorite();
                downloadDocument();
              }}
            >
              {isFetching ? <LoadingSpinner isMini={true} /> : <Icon name={'download'} />}
            </button>
          ) : (
            <a href={document.storageUrl} target="_blank" rel="noopener noreferrer">
              <button className={`${button} ${buttonLink}`} onClick={() => addToFavorite()}>
                <Icon name="link" />
              </button>
            </a>
          )}
        </div>
      )}
    </div>
  );
}

export default DocumentElement;
