import { useEffect, useState } from 'react';

// Hook to fix transitions on the left sidebar when resizing the page (menu opening or closing in mobile/desktop)
export default function useIsResizing() {
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    let resizeTimer: NodeJS.Timeout | undefined;

    const resizing = () => {
      setIsResizing(true);
      if (resizeTimer) {
        clearTimeout(resizeTimer);
      }

      resizeTimer = setTimeout(() => setIsResizing(false), 100);
    };

    window.addEventListener('resize', resizing);

    return function cleanup() {
      window.removeEventListener('resize', resizing);

      if (resizeTimer) {
        clearTimeout(resizeTimer);
      }
    };
  }, []);
  return isResizing;
}
