import React, { memo } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import rootRoutes, { RouteParams } from 'router/routes';
import { Layout } from 'components/layouts';
import RequireAuth from 'domain/auth/components/RequireAuth';
import SignInPage from 'domain/auth/components/SignInPage';

function generateRoutes(routes: RouteParams[]) {
  return routes.map(({ path, index, Element, children }, i) => {
    const element = (
      <RequireAuth>
        <Element />
      </RequireAuth>
    );
    return children && !index ? (
      <Route key={path + i} path={path} index={index} element={<Outlet />}>
        <Route index element={element} />
        {generateRoutes(children)}
      </Route>
    ) : (
      <Route key={path + i} path={path} index={index} element={element} />
    );
  });
}

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/signIn'} element={<SignInPage />} />
        <Route element={<Layout />}>{generateRoutes(rootRoutes)}</Route>
      </Routes>
    </BrowserRouter>
  );
}

export default memo(Router);
