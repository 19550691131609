import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'hooks/useLocalStorage/useLocalStorage';
import { defaultLanguage, en, fr } from 'locales/config';

function TranslationProvider({ children }: PropsWithChildren<unknown>) {
  const [language, setLang] = useLocalStorage('lang', defaultLanguage);

  const {
    i18n: { changeLanguage },
  } = useTranslation();

  useEffect(() => {
    changeLanguage(language);
  }, []);

  async function switchLanguage() {
    const newLang = language === en ? fr : en;
    await changeLanguage(newLang);
    setLang(newLang);
  }

  return <TranslationContext.Provider value={{ language, switchLanguage }}>{children}</TranslationContext.Provider>;
}

function useTranslationContext() {
  return useContext(TranslationContext);
}

const TranslationContext = createContext<{
  language: string;
  switchLanguage: () => Promise<void>;
}>({ language: 'fr', switchLanguage: () => Promise.resolve() });

export default TranslationProvider;

export { TranslationContext, useTranslationContext };
