import React, { useRef } from 'react';
import styles from 'styles/components/navigation/search-dropdown.module.scss';
import { Document } from 'types/document';
import SearchResultItem from 'domain/search/components/SearchResultItem';
import { useTranslation } from 'react-i18next';
import SearchTabs, { SearchTabsType } from 'domain/search/components/SearchTabs';
import SearchFilter from 'domain/search/components/SearchFilter';
import { useHandleEndOfScroll } from 'hooks/useHandleEndOfScroll';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type Props = {
  isDropdownOpen: boolean;
  documents: Document[][];
  nbResults: number[];
  isDocumentLoading: boolean[];
  isDocumentFetching: boolean[];
  isFilterLoading: boolean;
  hasQuerySpecified: boolean;
  hasFilterSpecified: boolean;
  onTabChange: (tab: string) => void;
  onScrollToBottom: (() => void)[];
  tabsHasNextPage: boolean[];
};

function SearchDropdown({
  isDropdownOpen,
  documents,
  nbResults,
  isDocumentLoading,
  isDocumentFetching,
  isFilterLoading,
  hasQuerySpecified,
  onTabChange,
  onScrollToBottom,
  tabsHasNextPage,
}: Props) {
  const { wrapper, searchDropdown, searchDropdownOpen, documentWrapper, noResultText, skeleton } = styles;
  const { t } = useTranslation('search');

  const listInnerRef = useRef(null);

  const onScroll = (index: number) => {
    useHandleEndOfScroll(listInnerRef, onScrollToBottom[index], !isDocumentFetching[index]);
  };

  const skeletonWrapper = () => {
    return (
      <div className={skeleton}>
        <span className="react-loading-skeleton"></span>
        <span className="react-loading-skeleton"></span>
      </div>
    );
  };

  const tabs: SearchTabsType[] = [
    {
      label: 'formAndProposal',
      index: 0,
      numberOfResult: nbResults[0] ?? 0,
      hasNextPage: tabsHasNextPage[0],
      Component: (
        <div className={wrapper}>
          <div className={documentWrapper} onScroll={() => onScroll(0)} ref={listInnerRef ? listInnerRef : null}>
            {!isDocumentLoading[0] && !isDocumentFetching[0] && !hasQuerySpecified && documents[0].length === 0 && (
              <p className={noResultText}>{t('noQuery')}</p>
            )}
            {!isDocumentLoading[0] && !isDocumentFetching[0] && hasQuerySpecified && documents[0].length === 0 && (
              <p className={noResultText}>{t('noResults')}</p>
            )}
            {documents[0].length > 0 &&
              documents[0].map((document, i) => <SearchResultItem document={document} key={i} />)}
            {isDocumentLoading[0] || (isDocumentFetching[0] && <Skeleton count={20} wrapper={skeletonWrapper} />)}
          </div>
          <SearchFilter isLoading={isFilterLoading} />
        </div>
      ),
    },
    {
      label: 'otherContent',
      index: 1,
      numberOfResult: nbResults[1] ?? 0,
      hasNextPage: tabsHasNextPage[1],
      Component: (
        <>
          <div className={documentWrapper} onScroll={() => onScroll(1)} ref={listInnerRef ? listInnerRef : null}>
            {!isDocumentLoading[1] && !isDocumentFetching[1] && !hasQuerySpecified && documents[1].length === 0 && (
              <p className={noResultText}>{t('noQuery')}</p>
            )}
            {!isDocumentLoading[1] && !isDocumentFetching[1] && hasQuerySpecified && documents[1].length === 0 && (
              <p className={noResultText}>{t('noResults')}</p>
            )}
            {documents[1].length > 0 &&
              documents[1].map((document, i) => <SearchResultItem document={document} key={i} />)}
            {isDocumentLoading[1] || (isDocumentFetching[1] && <Skeleton count={20} wrapper={skeletonWrapper} />)}
          </div>
        </>
      ),
    },
  ];

  return (
    <div className={`${searchDropdown} ${isDropdownOpen ? searchDropdownOpen : ''}`}>
      <SearchTabs
        tabs={tabs}
        onTabChange={onTabChange}
        isDocumentLoading={[
          isDocumentLoading[0] || isDocumentFetching[0],
          isDocumentLoading[1] || isDocumentFetching[1],
        ]}
      />
    </div>
  );
}

export default SearchDropdown;
