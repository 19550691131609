import React from 'react';
import styles from 'styles/components/inputs/date-picker-input.module.scss';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import DateRangePicker, { DateRangePickerProps } from '@wojtekmaj/react-daterange-picker';
import RequiredMarker from 'components/typography/RequiredMarker';

type DateRangeInputProps = {
  value: [string | Date, string | Date];
  label: string;
  errorMessage?: string;
  isRequired?: boolean;
} & Omit<DateRangePickerProps, 'value'>;

function DateRangeInput({ label, errorMessage, isRequired, ...props }: DateRangeInputProps) {
  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>
        {label} <RequiredMarker isRequired={isRequired} />
      </label>
      <DateRangePicker className={styles.datePicker} {...props} />
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
}

export type { DateRangeInputProps };
export default DateRangeInput;
