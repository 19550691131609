import { z } from 'zod';
import { Errors } from 'types/errors';

const newsTypeSchema = z.object({ value: z.string(), label: z.string(), publicId: z.string().uuid() });

const newsArticleAdminInputSchema = z.object({
  dateRange: z.array(z.date()).length(2, Errors.REQUIRED),
  title: z.string().min(1, Errors.REQUIRED),
  content: z.string().min(1, Errors.REQUIRED),
  externalLink: z.string().url(Errors.INVALID).optional().or(z.literal('')),
  image: z.instanceof(File, Errors.REQUIRED),
  newsType: newsTypeSchema,
});

export { newsArticleAdminInputSchema };

export type NewsArticleInput = z.infer<typeof newsArticleAdminInputSchema>;

export type NewsType = z.infer<typeof newsTypeSchema>;
