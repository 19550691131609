import { z } from 'zod';
import { FinancialCenter } from 'types/FinancialCenter';
import { Errors } from 'types/errors';

export enum RessourceCategoryEnum {
  HIGH_DIRECTION = 'highDirection',
  GENERAL_DIRECTION = 'generalDirection',
  ADMIN_SERVICES_DIRECTION = 'adminServiceDirection',
  INDIVIDUAL_INSURANCE = 'individualInsurance',
  INVESTMENTS_RETIREMENT = 'investmentsRetirement',
  MORTGAGE = 'mortgage',
  GROUP_ANNUITIES = 'groupAnnuities',
  ADVISOR_SERVICES = 'advisorServices',
  ADVANCED_SOLUTION = 'advancedSolutions',
  FINANCIAL_CENTER_DIRECTION = 'financialCenterDirection',
  FINANCIAL_CENTER_RECEPTION = 'financialCenterReception',
}

export const ressourceSchema = z.object({
  publicId: z.string().uuid(),
  categoryKeys: z.array(z.nativeEnum(RessourceCategoryEnum)),
  firstname: z.string().min(1, Errors.REQUIRED),
  lastname: z.string().min(1, Errors.REQUIRED),
  title: z.string().nullable().optional(),
  phone: z.string().nullable().optional(),
  tollFreePhone: z.string().nullable().optional(),
  fax: z.string().nullable().optional(),
  email: z.string().email().nullable().optional(),
  description: z.string().nullable().optional(),
  financialCenterKey: z.nativeEnum(FinancialCenter),
});

export declare type Ressource = z.infer<typeof ressourceSchema>;

export declare type RessourceGroup = Map<RessourceCategoryEnum, Ressource[]>;
