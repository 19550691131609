import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import deleteNote from 'domain/alert/api/deleteNote';
import { noteQueryKey } from 'domain/alert/hooks/useGetNotes';
import { useSnackbarContext } from 'hooks/useSnackbarContext';

function useDeleteNote() {
  const { t } = useTranslation('admin');
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbarContext();

  return useMutation(deleteNote, {
    onSuccess: () => {
      showSnackbar({ text: t('deleteNoteMessage'), severity: 'success' });
      return queryClient.invalidateQueries([noteQueryKey]);
    },
    onError: () => showSnackbar({ text: t('failedToDeleteNoteMessage'), severity: 'danger' }),
  });
}

export default useDeleteNote;
