import useLocalStorage from 'hooks/useLocalStorage/useLocalStorage';
import { useState } from 'react';
import { trackEvent } from 'utils/trackEvent';
import isMobile from 'utils/isMobile';

type DocumentType = 'favorite' | 'recent';

function useLayout() {
  const [isNavigationSidebarOpen, setNavigationSidebarOpen] = useLocalStorage('isNavigationSidebarOpen', false);
  const [isMobileFavoriteMenuOpen, setMobileFavoriteMenuOpen] = useState(false);
  const [isMobileRecentMenuOpen, setMobileRecentMenuOpen] = useState(false);
  const [documentsMenuShown, setDocumentsMenuShown] = useState<DocumentType>('favorite');

  const toggleSidebar = () => {
    setMobileFavoriteMenuOpen(false);
    setMobileRecentMenuOpen(false);
    setNavigationSidebarOpen(!isNavigationSidebarOpen);
    trackEvent({ category: 'Menu', action: 'Toggle Menu', label: isNavigationSidebarOpen });
  };

  const toggleMobileFavoriteMenu = () => {
    setNavigationSidebarOpen(false);
    setMobileRecentMenuOpen(false);
    setMobileFavoriteMenuOpen(!isMobileFavoriteMenuOpen);
    setDocumentsMenuShown('favorite');
  };

  const toggleMobileRecentMenu = () => {
    setNavigationSidebarOpen(false);
    setMobileFavoriteMenuOpen(false);
    setMobileRecentMenuOpen(!isMobileRecentMenuOpen);
    setDocumentsMenuShown('recent');
  };

  const closeSidebarOnMobileLinkClick = () => {
    if (isMobile()) {
      setNavigationSidebarOpen(false);
    }
  };

  return {
    documentsMenuShown,
    toggleSidebar,
    toggleMobileFavoriteMenu,
    toggleMobileRecentMenu,
    closeSidebarOnMobileLinkClick,
    isNavigationSidebarOpen,
    isMobileFavoriteMenuOpen,
    isMobileRecentMenuOpen,
  };
}

export default useLayout;
