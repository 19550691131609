import { Rate, rateSchema } from 'types/rates';
import { callApi } from 'utils/httpClient';

const gcServiceUrl = `${process.env.REACT_APP_GC_SERVICE_URL}`;

async function getRates(): Promise<Rate[]> {
  const { data: rates } = await callApi('get', gcServiceUrl, 'rates');

  const splitRates = rates.split('\n');
  return splitRates.map((rate: string) => {
    const splitted = rate.split(',');
    return rateSchema.parse({
      term: parseInt(splitted[0]),
      bestRate: parseFloat(splitted[1]),
      marketRate: parseFloat(splitted[2]),
    });
  });
}

export default getRates;
