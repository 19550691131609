import type { MeetEvent } from 'types/calendar';
import moment from 'moment';

type ConvertedMeetEvent = {
  scheduledStartDatetime: Date;
  scheduledEndDatetime: Date;
} & Omit<MeetEvent, 'scheduledStartDatetimeUtc' | 'scheduledEndDatetimeUtc'>;

function convertEventForCalendar(event: MeetEvent): ConvertedMeetEvent {
  return {
    ...event,
    scheduledStartDatetime: convertUtcDateToLocalDate(event.scheduledStartDatetimeUtc),
    scheduledEndDatetime: convertUtcDateToLocalDate(event.scheduledEndDatetimeUtc),
  };
}

function convertUtcDateToLocalDate(date: string) {
  return moment.utc(date).toDate();
}

export type { ConvertedMeetEvent };
export default convertEventForCalendar;
