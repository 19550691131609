import { useMutation, useQueryClient } from 'react-query';
import removeFavoriteDocument from 'domain/favorites/api/removeFavoriteDocument';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTranslation } from 'react-i18next';

function useRemoveFavoriteDocument() {
  const queryClient = useQueryClient();
  const { t } = useTranslation('genericItem');
  const { showSnackbar } = useSnackbarContext();

  return useMutation(removeFavoriteDocument, {
    onSuccess: () => {
      showSnackbar({ text: t('removeFavoriteMessage'), severity: 'success' });
      return queryClient.invalidateQueries(['favoriteDocuments']);
    },
    onError: () => showSnackbar({ text: t('failedToRemoveFavoriteMessage'), severity: 'danger' }),
  });
}

export default useRemoveFavoriteDocument;
