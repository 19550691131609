import { z } from 'zod';
import { Errors } from 'types/errors';

const regexForMultipleEmails = new RegExp(
  /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/,
);

const editBidActionFormSchema = z.object({
  email: z.string().min(1, Errors.REQUIRED).regex(regexForMultipleEmails, Errors.INVALID_EMAIL),
});

export type EditBidActionFormSchema = z.infer<typeof editBidActionFormSchema>;

export { editBidActionFormSchema };
