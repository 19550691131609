import titleStyle from 'styles/components/pages.module.scss';
import style from 'styles/domain/alert/admin/alert-admin-page.module.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from 'components/forms/SelectField';
import TextAreaField from 'components/forms/TextAreaField';
import Button from 'components/inputs/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertAdminInputSchema, alertAdminInputSchema } from 'types/note';
import useAddNote from 'domain/alert/hooks/useAddNote';
import { WarningLevel } from 'components/shared/WarningBar';
import DateField from 'components/forms/DateField';
import moment from 'moment';
import AlertAdminTable from 'domain/alert/components/AlertAdminTable';
import TextField from 'components/forms/TextField';
import LoadingSpinner from 'components/shared/LoadingSpinner';

function AlertAdminPage() {
  const { t } = useTranslation('admin');

  const { grid, section, tableSection, wrapper, input, subTitle } = style;

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    resolver: zodResolver(alertAdminInputSchema),
    defaultValues: {
      title: '',
      warningType: { value: '', label: '' },
      link: '',
      isVisible: { value: '', label: '' },
      date: new Date(),
    },
  });

  const warningTypeOptions = [
    {
      value: WarningLevel.WARNING,
      label: t('warning', { ns: 'common' }),
    },
    {
      value: WarningLevel.IMPORTANT,
      label: t('important', { ns: 'common' }),
    },
  ];

  const { mutate, isLoading } = useAddNote();

  const handleAddAlert: SubmitHandler<AlertAdminInputSchema> = ({ title, warningType, link, date }) => {
    const alert = {
      title,
      content: warningType.value,
      externalInformationUrl: link ?? '',
      expirationDatetimeUtc: moment(date, 'YYYY-MM-DD HH:mm:ss').tz('UTC').format(),
      isVisible: true,
    };
    mutate(alert);
  };

  return (
    <>
      <h1 className={`${titleStyle.title} ${titleStyle.titlePadded}`}>{t('alertAdminTitle')}</h1>
      <div className={grid}>
        <div className={section}>
          <h2 className={subTitle}>{t('addAlert')}</h2>
          <form onSubmit={handleSubmit(i => handleAddAlert(i as AlertAdminInputSchema))} data-testid="newsForm">
            <div className={wrapper}>
              <TextAreaField className={input} control={control} label={`${t('title')}*`} name="title" />
              <SelectField control={control} name="warningType" options={warningTypeOptions} label={`${t('type')}*`} />
              <DateField label={`${t('endDate')}*`} control={control} minDate={new Date()} name="date" />
              <TextField className={input} control={control} label={t('link')} name="link" />
              <Button type="submit">{isLoading ? <LoadingSpinner isMini isWhite /> : t('add')}</Button>
            </div>
          </form>
        </div>
        <div className={tableSection}>
          <AlertAdminTable />
        </div>
      </div>
    </>
  );
}

export default AlertAdminPage;
