import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import deleteRessource from 'domain/support/api/deleteRessource';
import { FinancialCenter } from 'types/FinancialCenter';
import { ressourcesQueryKey } from 'domain/support/hooks/useGetRessources';

function useDeleteRessource(financialCenterKey: FinancialCenter) {
  const { t } = useTranslation('support');
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbarContext();

  return useMutation(deleteRessource, {
    onSuccess: () => {
      showSnackbar({ text: t('deleteRessourceMessage'), severity: 'success' });
      return queryClient.invalidateQueries([`${ressourcesQueryKey}${financialCenterKey}`]);
    },
    onError: () => showSnackbar({ text: t('failedToDeleteRessourceMessage'), severity: 'danger' }),
  });
}

export default useDeleteRessource;
