import React from 'react';
import { useTranslation } from 'react-i18next';
import titleStyle from 'styles/components/pages.module.scss';
import style from 'styles/domain/home/components/admin/news-admin-page.module.scss';
import Button from 'components/inputs/Button';
import useAddNews from 'domain/home/hooks/useAddNews';
import { SubmitHandler, useForm } from 'react-hook-form';
import SingleFileField from 'components/forms/SingleFileField';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  newsArticleAdminInputSchema,
  NewsArticleInput,
} from 'domain/home/components/admin/schemas/newsArticleAdminInput';
import useGetBusinessUnit from 'domain/businessUnit/hooks/useGetBusinessUnit';
import TextField from 'components/forms/TextField';
import SelectField from 'components/forms/SelectField';
import DateRangeField from 'components/forms/DateRangeField';
import moment from 'moment/moment';
import 'moment-timezone';
import TextAreaField from 'components/forms/TextAreaField';
import LoadingSpinner from 'components/shared/LoadingSpinner';

function NewsArticleAdminPage() {
  const { t } = useTranslation('admin');

  const { data: businessUnits } = useGetBusinessUnit();

  const allNewsType = businessUnits?.map(unit => ({
    value: unit.name,
    label: t(unit.name, { ns: 'common' }),
    publicId: unit.publicId,
  }));

  const { wrapper, input, dateWrapper } = style;

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    resolver: zodResolver(newsArticleAdminInputSchema),
    defaultValues: {
      dateRange: [new Date(), new Date()],
      title: '',
      content: '',
      externalLink: '',
      newsType: { value: '', label: '', publicId: '' },
      image: undefined as File | undefined,
    },
  });

  const { mutate, isLoading } = useAddNews();

  const handleAddNews: SubmitHandler<NewsArticleInput> = ({
    title,
    content,
    externalLink,
    dateRange,
    newsType,
    image,
  }) => {
    const newArticle = {
      title,
      content,
      externalInformationUrl: externalLink,
      displayStartDatetimeUtc: moment(dateRange[0], 'YYYY-MM-DD HH:mm:ss').tz('UTC').format(),
      displayEndDatetimeUtc: moment(dateRange[1], 'YYYY-MM-DD HH:mm:ss').tz('UTC').format(),
      businessUnitIds: [newsType.publicId],
      imageUrl: '',
    };

    const img = image ?? ({} as File);
    mutate({ newArticle, image: img });
  };

  return (
    <>
      <h1 className={`${titleStyle.title} ${titleStyle.titlePadded}`}>{t('newsAdminTitle')}</h1>
      <form onSubmit={handleSubmit(i => handleAddNews(i as NewsArticleInput))} data-testid="newsForm">
        <div className={wrapper}>
          <TextField control={control} name="title" label={`${t('title')}*`} />
          <div className={dateWrapper}>
            <DateRangeField control={control} name="dateRange" label={`${t('newsDuration')}*`} />
          </div>
          <SelectField control={control} name="newsType" options={allNewsType} label={`${t('type')}*`} />
          <TextAreaField
            className={input}
            control={control}
            label={`${t('description')}*`}
            name="content"
            data-testid="description"
          />
          <TextField control={control} name="externalLink" label={`${t('externalLink')}`} />
          <SingleFileField
            control={control}
            name="image"
            label={`${t('image')}*`}
            fileDropMessage={t('dragDropMessage', { ns: 'sendToGc' })}
            acceptedFiles=".jpg, .jpeg, .png, .gif"
            recommendedMessage={t('newsImageRecommendation')}
          />
          <Button type="submit">{isLoading ? <LoadingSpinner isMini isWhite /> : t('add')}</Button>
        </div>
      </form>
    </>
  );
}

export default NewsArticleAdminPage;
