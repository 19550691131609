import { NewsArticle } from 'types/news';
import { callApi } from 'utils/httpClient';
import addImage from 'api/addImage';

const wcmServiceUrl = `${process.env.REACT_APP_WCM_SERVICE_URL}`;

type NewArticleWithImg = {
  newArticle: NewsArticle;
  image: File;
};

async function addNewArticle({ newArticle, image }: NewArticleWithImg) {
  newArticle.imageUrl = await addImage(image);

  return callApi('post', wcmServiceUrl, 'news', newArticle);
}

export default addNewArticle;
