import { useMutation } from 'react-query';
import deleteEquisoftConfig from '../api/deleteEquisoftConfig'
import { useTranslation } from 'react-i18next';
import { useShowOutputMessage } from 'hooks/useShowOutputMessage';

function useDeleteEquisoftConfig(successCallback: () => void) {

    const { t } = useTranslation('profile');
    const { showConfirmationModal } = useShowOutputMessage();
  return useMutation(deleteEquisoftConfig, {
    onSuccess: () => successCallback(),
    onError: () => showConfirmationModal({ text: t('errorEquisoftAuthText'), severity: 'danger', submitText: t('submitText') }),
  });
}
export default useDeleteEquisoftConfig;