import React from 'react';
import styles from 'styles/components/layout/favoritesBar/favorites-bar.module.scss';
import SettingsMenu from 'components/layouts/favoritesSidebar/SettingsMenu';
import FavoritesMenu from 'components/layouts/favoritesSidebar/FavoritesMenu';

function FavoritesSidebar() {
  const { container } = styles;

  return (
    <div className={container}>
      <SettingsMenu />
      <FavoritesMenu />
    </div>
  );
}

export default FavoritesSidebar;
