import React from 'react';
import { useTranslation } from 'react-i18next';
import titleStyles from 'styles/components/pages.module.scss';
import styles from 'styles/domain/calendar/calendar-page.module.scss';
import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CustomCalendar from 'domain/calendar/components/CustomCalendar';
import Tabs, { TabsType } from 'components/shared/Tabs';
import useGetCalendarEvents from 'domain/calendar/hooks/useGetCalendarEvents';
import useGetVenues from 'domain/venue/hooks/useGetVenues';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ContestSection from 'domain/calendar/components/contest/ContestSection';

function CalendarPage() {
  const { t } = useTranslation('calendar');
  const { title, titleNoBorder } = titleStyles;

  const { data: events } = useGetCalendarEvents();

  const { data: venues } = useGetVenues();

  const tabs: TabsType[] =
    venues
      ?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .map((venue, index) => ({
        label: t(venue.name, { ns: 'financialCenter' }),
        index,
        Component: (
          <>
            <ContestSection
              events={events?.filter(e => e.venueId === venue.publicId && e.eventType === 'contest') ?? []}
            />
            <CustomCalendar
              events={events?.filter(e => e.venueId === venue.publicId && e.eventType === 'event') ?? []}
            />
          </>
        ),
      })) ?? [];

  return (
    <>
      <h1 className={`${title} ${titleNoBorder}`}>{t('calendar')}</h1>
      {tabs.length > 0 ? (
        <Tabs tabs={tabs} noBorder={true} />
      ) : (
        <div className={styles.calendarPageWrapper}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

export default CalendarPage;
