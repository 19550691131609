import { z } from 'zod';

export enum DocumentType {
  PDF = 'application/pdf',
  LINK = 'link',
  NO_VALUE = '',
}

export const documentSchema = z.object({
  publicId: z.string().uuid(),
  title: z.string(),
  provider: z.string(),
  isFavorite: z.boolean().default(false),
  documentType: z.string().optional(),
  version: z.string(),
  storageUrl: z.string(),
  filename: z.string(),
});

export const searchResultSchema = z.object({
  totalResult: z.number(),
  results: z.array(documentSchema),
});

export const paginatedDocumentsSchema = z.object({
  data: searchResultSchema,
  previousPage: z.number().optional(),
  nextPage: z.number().optional(),
});

export type Document = z.infer<typeof documentSchema>;

export type PaginatedDocuments = z.infer<typeof paginatedDocumentsSchema>;

export const genericDocumentSchema = documentSchema.pick({
  publicId: true,
  title: true,
  storageUrl: true,
  filename: true,
  isFavorite: true,
  documentType: true,
});

export type GenericDocument = z.infer<typeof genericDocumentSchema>;

export const sectionDocumentSchema = genericDocumentSchema.extend({
  sectionKey: z.string(),
});

export type SectionDocument = z.infer<typeof sectionDocumentSchema>;
