import { MutableRefObject } from 'react';

export function useHandleEndOfScroll(
  ref: MutableRefObject<HTMLElement | null>,
  callback: () => void,
  extraCondition?: boolean,
) {
  if (ref.current) {
    const { scrollTop, scrollHeight, clientHeight } = ref.current;

    if (Math.ceil(scrollTop + clientHeight + 1) >= scrollHeight && extraCondition) {
      callback();
    }
  }
}
