import { selectOptionSchema } from 'schemas/selectOption';
import { z } from 'zod';
import { Errors } from 'types/errors';
import { FormatDoc } from '../component/EquisoftFormDocument';

const equisoftFileSchema = z.object({
    file: z.instanceof(File).optional(),
    synchronize: z.boolean(),
});

const equisoftDocumentSchema = z.object({
    formatDoc: z.string(),
    equisoftDocName: z.string().optional(),
    description: z.string(),
    type: selectOptionSchema.optional(),
    sendTo: z.array(selectOptionSchema),
    files: z.array(equisoftFileSchema)
}).superRefine((val, ctx) => {
    if (val.formatDoc === FormatDoc.OneDoc || (val.formatDoc === FormatDoc.MultipleDoc && val.files[0].synchronize === true)) {
        if (val.type === undefined) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: Errors.REQUIRED,
                path: ['type'],
            });
        }
        if (val.equisoftDocName === undefined || val.equisoftDocName.trim().length <= 1) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: Errors.REQUIRED,
                path: ['equisoftDocName'],
            });
        }
    }

});

type EquisoftDocument = z.infer<typeof equisoftDocumentSchema>;
type EquisoftFile = z.infer<typeof equisoftFileSchema>;

export type { EquisoftDocument };
export type { EquisoftFile };
export { equisoftDocumentSchema };
export { equisoftFileSchema };