import React from 'react';
import styles from 'styles/components/layout/favoritesBar/favorites-mobile-bottom-bar.module.scss';
import FavoritesMobileItem from 'components/layouts/favoritesSidebar/FavoritesMobileItem';
import { useTranslation } from 'react-i18next';

type Props = {
  setMobileMenuOpen: () => void;
  isMenuOpen: boolean;
  toggleFavoritesMenu: () => void;
  isFavoriteMenuOpen: boolean;
  toggleRecentsMenu: () => void;
  isRecentsMenuOpen: boolean;
};

function MobileBottomBar({
  setMobileMenuOpen,
  isMenuOpen,
  isFavoriteMenuOpen,
  toggleFavoritesMenu,
  toggleRecentsMenu,
  isRecentsMenuOpen,
}: Props) {
  const { bottomBar } = styles;
  const { t } = useTranslation('navigation');

  return (
    <div className={bottomBar}>
      <FavoritesMobileItem
        icon={'recentIconMobile'}
        label={t('recent')}
        isPageOpen={isRecentsMenuOpen}
        toggleFavoritesMenu={toggleRecentsMenu}
      />
      <FavoritesMobileItem
        icon={'favoriteMobile'}
        label={t('favorites')}
        isPageOpen={isFavoriteMenuOpen}
        toggleFavoritesMenu={toggleFavoritesMenu}
      />
      <FavoritesMobileItem
        icon={'mobileHamburger'}
        label={t('menu')}
        isPageOpen={isMenuOpen}
        toggleFavoritesMenu={setMobileMenuOpen}
      />
    </div>
  );
}

export default MobileBottomBar;
