import React from 'react';
import styles from 'styles/domain/support/ressources-repertory.module.scss';
import useGetRessources from 'domain/support/hooks/useGetRessources';
import RessourcesCardList from 'domain/support/components/RessourcesCardList';
import { useTranslation } from 'react-i18next';
import { FinancialCenter, financialCenterInformations } from 'types/FinancialCenter';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { directionRessourceSections, ressourceSections } from 'domain/support/data/RessourceSections';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';
import { UserRole } from 'types/auth';
import Button from 'components/inputs/Button';
import { RessourceCategoryEnum } from 'types/ressources';
import { useModalContext } from 'hooks/useModalContext';
import AddRessourceForm from 'domain/support/components/AddRessourceForm';

type Props = {
  financialCenter: FinancialCenter;
};

function RessourcesRepertory({ financialCenter }: Props) {
  const { t } = useTranslation('support');
  const { data, isLoading } = useGetRessources(financialCenter);
  const { userRole } = useAuthContext();

  const {
    sectionTitle,
    detailContent,
    gridContent,
    gridCard,
    expandMenuList,
    gridCardTitle,
    gridSubtitle,
    spinnerContainer,
    financialCenterInfoContainer,
    financialCenterInfos,
    financialCenterInfoTitle,
  } = styles;

  const { showModal } = useModalContext();

  const openAddRessourceModal = (category: RessourceCategoryEnum) => {
    showModal({
      createComponent: () => <AddRessourceForm financialCenterKey={financialCenter} category={category} />,
      cancellable: false,
    });
  };

  const { address, directionUrl, city } = financialCenterInformations[financialCenter];

  return (
    <div>
      <div className={financialCenterInfoContainer}>
        <div className={financialCenterInfos}>
          <p className={financialCenterInfoTitle}>{t('address')}</p>
          <p>{address}</p>
          <p>{city}</p>
          <a href={directionUrl} target="_blank" rel="noreferrer">
            {t('directions')}
          </a>
        </div>
        <div className={financialCenterInfos}>
          <div className={financialCenterInfoTitle}>
            {t('financialCenterReception')}
            {userRole === UserRole.ADMIN && (
              <Button onClick={() => openAddRessourceModal(RessourceCategoryEnum.FINANCIAL_CENTER_RECEPTION)}>+</Button>
            )}
          </div>

          <RessourcesCardList
            direction={'column'}
            isWhite={true}
            ressources={data?.get(RessourceCategoryEnum.FINANCIAL_CENTER_RECEPTION)}
          />
        </div>
        <div className={financialCenterInfos}>
          <div className={financialCenterInfoTitle}>
            {t('financialCenterDirection')}
            {userRole === UserRole.ADMIN && (
              <Button onClick={() => openAddRessourceModal(RessourceCategoryEnum.FINANCIAL_CENTER_DIRECTION)}>+</Button>
            )}
          </div>

          <RessourcesCardList
            direction={'column'}
            isWhite={true}
            ressources={data?.get(RessourceCategoryEnum.FINANCIAL_CENTER_DIRECTION)}
          />
        </div>
      </div>
      <div className={gridContent}>
        {ressourceSections.map(({ category, title, subTitle, imageSrc }, i) => (
          <div className={gridCard} key={i}>
            <div className={gridCardTitle}>
              <img src={imageSrc} />
              {t(title)}
              {userRole === UserRole.ADMIN && <Button onClick={() => openAddRessourceModal(category)}>+</Button>}
            </div>
            <p className={gridSubtitle}>{t(subTitle)}</p>
            {isLoading && (
              <div className={spinnerContainer}>
                <LoadingSpinner />
              </div>
            )}
            {data && <RessourcesCardList ressources={data.get(category)} direction={'column'} />}
          </div>
        ))}
      </div>

      <div className={expandMenuList}>
        <p className={sectionTitle}>{t('gcDirection')}</p>
        {directionRessourceSections.map(({ category, title }, i) => (
          <details key={i}>
            <summary className={sectionTitle}>
              {t(title)}
              {userRole === UserRole.ADMIN && <Button onClick={() => openAddRessourceModal(category)}>+</Button>}
            </summary>
            <div className={detailContent}>
              {isLoading && (
                <div className={spinnerContainer}>
                  <LoadingSpinner isWhite />
                </div>
              )}
              {data && <RessourcesCardList isWhite={true} direction={'grid'} ressources={data.get(category)} />}
            </div>
          </details>
        ))}
      </div>
    </div>
  );
}

export default RessourcesRepertory;
