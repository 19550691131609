import styles from 'styles/components/shared/loading-spinner.module.scss';
import React from 'react';

type Props = {
  isMini?: boolean;
  isWhite?: boolean;
};
export default function LoadingSpinner({ isMini = false, isWhite = false }: Props) {
  const { miniWrapper, bigWrapper, spinner, whiteSpinner } = styles;

  return (
    <div className={`${isMini ? miniWrapper : bigWrapper} `}>
      <div className={`${spinner} ${isWhite ? whiteSpinner : ''}`} />
    </div>
  );
}
