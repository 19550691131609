import React from 'react';
import styles from 'styles/components/navigation/search-filter.module.scss';
import { SearchFilterType } from 'types/classification';
import Checkbox from 'components/shared/CheckBox';
import DisplayMore from 'domain/search/components/DisplayMore';
import DisplayMoreFiltersModalContent from 'domain/search/components/DisplayMoreFiltersModalContent';
import { useModalContext } from 'hooks/useModalContext';

type Props = {
  title: string;
  filters: SearchFilterType[];
  onSelectFilter: (filter: SearchFilterType) => void;
  onSubmitFilters: (filters: SearchFilterType[]) => void;
};

function SearchFilterItem({ title, filters, onSelectFilter, onSubmitFilters }: Props) {
  const { item, titleStyle, checkWrap, inputWrapper } = styles;
  const { showModal, hideModal } = useModalContext();

  const onShowModal = () => {
    showModal({
      createComponent: () => (
        <DisplayMoreFiltersModalContent filters={filters} onSubmitFilters={onSubmitFilters} hideModal={hideModal} />
      ),
      cancellable: false,
    });
  };

  const onChange = (filter: SearchFilterType) => {
    onSelectFilter(filter);
  };

  return (
    <div className={item}>
      <h2 className={titleStyle}>{title}</h2>
      <div className={checkWrap}>
        {filters?.slice(0, 3).map((filter, i) => (
          <div className={inputWrapper} key={i}>
            <Checkbox label={filter.description} isChecked={filter.isChecked} onChange={() => onChange(filter)} />
          </div>
        ))}
      </div>
      <DisplayMore onMoreClick={onShowModal} />
    </div>
  );
}

export default SearchFilterItem;
