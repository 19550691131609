import React from 'react';
import dropLogo from 'assets/icons/drop-icon.png';
import styles from 'styles/components/inputs/fileInput/file-drop-message.module.scss';

type FileDropMessageProps = {
  message: string;
  errorMessage?: string;
};

function FileDropMessage({ message, errorMessage }: FileDropMessageProps) {
  return (
    <div className={styles.fileDropMessageWrapper}>
      {!errorMessage && <img src={dropLogo} alt={message} className={styles.fileDropMessageImg} />}
      <p className={`${styles.fileDropMessageText} ${errorMessage ? styles.fileDropMessageTextError : ''}`}>
        {errorMessage ?? message}
      </p>
    </div>
  );
}

export default FileDropMessage;
