import { useQuery } from 'react-query';
import getMeets from 'domain/calendar/api/getMeets';

export const meetQueryKey = 'meets';

function useGetCalendarEvents(type?: string) {
  return useQuery(meetQueryKey, () => getMeets(type));
}

export default useGetCalendarEvents;
