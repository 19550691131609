import React from 'react';
import styles from 'styles/components/layout/favoritesBar/favorites-mobile-item.module.scss';
import Icon from 'components/icons/Icon';

type Props = {
  icon: 'favoriteMobile' | 'recentIconMobile' | 'mobileHamburger';
  label: string;
  isPageOpen: boolean;
  toggleFavoritesMenu: () => void;
};

function FavoritesMobileItem({ icon, label, isPageOpen, toggleFavoritesMenu }: Props) {
  const { favMobileItem, favMobileLabel } = styles;

  return (
    <button className={favMobileItem} onClick={() => toggleFavoritesMenu()}>
      <Icon name={isPageOpen ? 'closeMobileMenu' : icon} />
      <p className={favMobileLabel}>{label}</p>
    </button>
  );
}

export default FavoritesMobileItem;
