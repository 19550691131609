import React from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import Select, { SelectProps } from 'components/inputs/Select';
import useGetErrorMessage from 'components/forms/hooks/useGetErrorMessage';

type SelectFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = SelectProps & UseControllerProps<TFieldValues, TName>;

function SelectField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, ...props }: SelectFieldProps<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });
  const getErrorMessage = useGetErrorMessage(props.label);
  const errorMessage = error ? getErrorMessage(error.message) : undefined;
  return <Select errorMessage={errorMessage} {...field} {...props} />;
}

export default SelectField;
