import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'styles/components/shared/item-grid.module.scss';
import ItemCard from 'components/shared/ItemCard';
import { Outlet } from 'react-router-dom';
import titleStyles from 'styles/components/pages.module.scss';
import useGetClientsItems from 'domain/myClients/hooks/useGetClientsItems';
import { GenericSectionItem } from 'types/tools';
import infoClientsImg from 'assets/img/genericItem/icon-info-clients.png';

const clientsInfo: GenericSectionItem = {
  title: 'clientsInfo',
  description: 'originalSiteDescription',
  subSections: [{ title: 'originalSite', documentsSectionKey: '' }],
  image: infoClientsImg,
  externalLink: `${process.env.REACT_APP_OLD_BAO_BASE_LINK}${process.env.REACT_APP_OLD_BAO_SEARCH_LINK}`,
  isDescriptionWarning: true,
};

function MyClientsPage() {
  const { t } = useTranslation('myClients');

  const { data } = useGetClientsItems();
  const { title, titleNoBorder } = titleStyles;
  const { grid } = styles;

  return (
    <>
      <h1 className={`${title} ${titleNoBorder}`}>{t('clientsGestion')}</h1>
      <div className={grid}>
        <ItemCard item={clientsInfo} />
        {data?.map((tool, i) => (
          <ItemCard key={i} item={tool} />
        ))}
      </div>
      <Outlet />
    </>
  );
}

export default MyClientsPage;
