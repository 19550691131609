import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import TextField from 'components/forms/TextField';
import Button from 'components/inputs/Button';
import SelectField from 'components/forms/SelectField';
import { AddDocumentInput, addDocumentInputSchema } from 'domain/sellingTools/schemas/addDocumentInput';
import useAddDocument from 'domain/sellingTools/hooks/useAddDocument';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SingleFileField from 'components/forms/SingleFileField';
import { useTranslation } from 'react-i18next';
import styles from 'styles/domain/sellingTools/add-document-form.module.scss';
import { getSectionDocumentsQueryKey } from 'domain/sellingTools/hooks/useGetSectionDocuments';
import { useQueryClient } from 'react-query';
import useGetDocTypes, { DocType } from 'domain/sellingTools/hooks/useGetDocTypes';

type Props = {
  documentSectionKeys: { label: string; value: string }[];
};

function AddDocumentForm({ documentSectionKeys }: Props) {
  const { t } = useTranslation('genericItem');

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isDirty },
    reset,
    watch,
    resetField,
  } = useForm<AddDocumentInput>({
    mode: 'onChange',
    resolver: zodResolver(addDocumentInputSchema),
    defaultValues: {
      selectedCategory: documentSectionKeys[0],
      type: { value: DocType.LINK, label: t('link') },
      fileName: '',
      link: '',
    },
  });

  const types = useGetDocTypes();

  const queryClient = useQueryClient();

  const watchSelectedDocumentType = watch('type');

  const isDocumentTypeLink = watchSelectedDocumentType.value === 'link';

  React.useEffect(() => {
    const documentTypeSelected = watchSelectedDocumentType.value;
    resetField(documentTypeSelected === 'link' ? 'link' : 'file');
  }, [watchSelectedDocumentType]);

  const { mutate: addDocument, isLoading } = useAddDocument({
    onSuccess: () => {
      reset();
      return queryClient.invalidateQueries([`${getSectionDocumentsQueryKey}${getValues('selectedCategory.value')}`]);
    },
  });
  const { formContainer, sendButton } = styles;

  return (
    <form className={formContainer} onSubmit={handleSubmit(data => addDocument(data))}>
      <TextField control={control} name="fileName" label={`${t('title')}*`} />
      <SelectField
        control={control}
        name="selectedCategory"
        isMulti={false}
        options={documentSectionKeys}
        label={`${t('section')}*`}
      />
      <SelectField control={control} name="type" isMulti={false} options={types} label={`${t('type')}*`} />
      {isDocumentTypeLink ? (
        <TextField control={control} name="link" label={`${t('link')}*`} />
      ) : (
        <SingleFileField control={control} name="file" label={`${t('file')}*`} fileDropMessage={t('dragDropMessage')} />
      )}
      <Button className={sendButton} type="submit" disabled={!isDirty}>
        {isLoading ? <LoadingSpinner isMini isWhite /> : t('send')}
      </Button>
    </form>
  );
}

export default AddDocumentForm;
