import { useQuery, UseQueryResult } from 'react-query';
import { EquisoftClient } from 'types/equisoftClient';
import getEquisoftClient from '../api/getEquisoftClient';
import { useTranslation } from 'react-i18next';
import { useShowConfirmationModal } from 'hooks/useShowConfirmationModal';

function useGetEquisoftClient(): UseQueryResult<EquisoftClient> {
    const { t } = useTranslation('profile');
    const { showConfirmationModal } = useShowConfirmationModal();
    return useQuery('equisoftClient', getEquisoftClient, {
        onError: () =>
          showConfirmationModal({
            message: t('errorEquisoftMessage'),
            title: t('errorEquisoftTitle'),
          })
      });
}

export default useGetEquisoftClient;
