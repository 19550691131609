import { useQuery, UseQueryResult } from 'react-query';
import { Note } from 'types/note';
import getNotes from 'domain/alert/api/getNotes';

export const noteQueryKey = 'note';

function useGetNotes(): UseQueryResult<Note[]> {
  return useQuery(noteQueryKey, async () => getNotes());
}

export default useGetNotes;
