import { PaginatedDocuments, paginatedDocumentsSchema } from 'types/document';
import { callApi } from 'utils/httpClient';
import { trackEvent } from 'utils/trackEvent';
import { SearchFilterType } from 'types/classification';

const searchServiceURL = `${process.env.REACT_APP_SEARCH_SERVICE_URL}`;

export type SearchDocumentQuery = {
  label: string;
  filters: SearchFilterType[];
  pageSize: number;
  currentPage: number;
  assumeEndWildcardPattern: boolean;
};

async function getFilteredDocuments(query: SearchDocumentQuery): Promise<PaginatedDocuments> {
  trackEvent({ category: 'Search', action: 'Search', label: query.label });

  if (query.label === '' && query.filters.length <= 1)
    return paginatedDocumentsSchema.parse({ data: { totalResult: 0, results: [] } });

  const mappedFilters = query.filters.map(f => ({ key: f.type, value: f.key }));

  const { data } = await callApi('post', searchServiceURL, 'Search', {
    ...query,
    query: query.label,
    filters: mappedFilters,
  });

  const paginatedData = {
    data,
    previousPage: query.currentPage === 1 ? undefined : query.currentPage - 1,
    nextPage: data.results.length ? query.currentPage + 1 : undefined,
  };

  return paginatedDocumentsSchema.parse(paginatedData);
}

export default getFilteredDocuments;
