import { useMutation, useQueryClient } from 'react-query';
import removeRecentDocument from 'domain/favorites/api/removeRecentDocument';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTranslation } from 'react-i18next';

function useRemoveRecentDocument() {
  const queryClient = useQueryClient();
  const { t } = useTranslation('genericItem');
  const { showSnackbar } = useSnackbarContext();

  return useMutation(removeRecentDocument, {
    onSuccess: () => {
      return queryClient.invalidateQueries(['recentDocuments']);
    },
    onError: () => showSnackbar({ text: t('failedToRemoveRecentDocumentMessage'), severity: 'danger' }),
  });
}

export default useRemoveRecentDocument;
