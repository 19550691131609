import { useQuery, UseQueryResult } from 'react-query';
import { FinancialCenter } from 'types/FinancialCenter';
import getFinancialCenterResources from 'domain/support/api/getFinancialCenterResources';
import { Ressource, RessourceCategoryEnum, RessourceGroup } from 'types/ressources';

const mapRessources = (ressources: Ressource[]) => {
  let ressourcesMap = new Map<RessourceCategoryEnum, Ressource[]>();

  Object.values(RessourceCategoryEnum).forEach(category => {
    const filtered = ressources.filter(ressource => ressource.categoryKeys.includes(category));
    ressourcesMap = ressourcesMap.set(category as RessourceCategoryEnum, filtered);
  });

  return ressourcesMap;
};

export const ressourcesQueryKey = 'Ressources';

function useGetRessources(financialCenter: FinancialCenter): UseQueryResult<RessourceGroup> {
  return useQuery(
    [`${ressourcesQueryKey}${financialCenter}`, financialCenter],
    () => getFinancialCenterResources(financialCenter),
    {
      select: ressources => mapRessources(ressources),
    },
  );
}

export default useGetRessources;
