import { ErrorMessage } from 'components/typography/ErrorMessage';
import RequiredMarker from 'components/typography/RequiredMarker';
import styles from 'styles/components/inputs/radio-button-input.module.scss';
import React from 'react';

type GroupRadioInputProps = {
  inputs: RadioInputProps[];
  errorMessage?: string;
  isRequired?: boolean;
  name: string;
  label: string;
  stateValue: string
  setStateValue: React.Dispatch<React.SetStateAction<string>>
};

type RadioInputProps = {
  id: string;
  label: string;
  value: string;
};

const GroupRadioInput = ({ inputs, errorMessage, isRequired, name, label, stateValue, setStateValue }: GroupRadioInputProps) => {
  return (
    <div>
      <p className={styles.label}>
        {label} <RequiredMarker isRequired={isRequired} />
      </p>
      <div className={styles.groupContainer}>
      {inputs.map(input => (
        <div key={input.value} className={styles.inputContainer}>
          <input id={input.id} type="radio" className={styles.input} name={name} checked={stateValue === input.value} onChange={() => setStateValue(input.value)} />
          <label htmlFor={input.id} className={styles.labelInput}>
            {input.label}
          </label>
        </div>
      ))}
      </div>
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
};

export type { GroupRadioInputProps };
export default GroupRadioInput;
