import LoadingSpinner from 'components/shared/LoadingSpinner'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import styles from 'styles/domain/equisoft/equisoft-page.module.scss';
import useGenerateEquisoftConfig from '../hooks/useGenerateEquisoftConfig';

export default function EquisoftPage() {
    const {spinnerContainer, backdrop } = styles;
    const location = useLocation();
    
    const { mutate: generate } = useGenerateEquisoftConfig();

    useEffect(()=> {
        if(location.search !== "") {
            generate(location.search);
        }
     },[location])

  return (
    <div className={spinnerContainer}>
        <LoadingSpinner></LoadingSpinner>
        <div className={backdrop}></div>
    </div>
  )
}
