import { callApi } from 'utils/httpClient';
import { ClassificationType, classificationSchema } from 'types/classification';

const publisherServiceUrl = `${process.env.REACT_APP_PUBLISHER_SERVICE_URL}`;

const classificationBytypesUrl = 'classification/bytypes';

async function getClassificationType(language: string): Promise<ClassificationType> {
  const { data: sections } = await callApi(
    'get',
    publisherServiceUrl,
    `${classificationBytypesUrl}/Section`,
    {},
    language,
  );
  const { data: providers } = await callApi(
    'get',
    publisherServiceUrl,
    `${classificationBytypesUrl}/Provider`,
    {},
    language,
  );
  const { data: categories } = await callApi(
    'get',
    publisherServiceUrl,
    `${classificationBytypesUrl}/Category`,
    {},
    language,
  );
  const { data: languages } = await callApi(
    'get',
    publisherServiceUrl,
    `${classificationBytypesUrl}/Language`,
    {},
    language,
  );

  return classificationSchema.parse({
    sections,
    providers,
    categories,
    languages,
  });
}

export default getClassificationType;
