import React from 'react';
import { FinancialCenter } from 'types/FinancialCenter';
import { RessourceCategoryEnum } from 'types/ressources';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddRessourceInput, addRessourceInputSchema } from 'domain/support/schemas/AddRessourceInput';
import TextField from 'components/forms/TextField';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import Button from 'components/inputs/Button';
import useAddRessource from 'domain/support/hooks/useAddRessource';
import styles from 'styles/domain/support/add-ressource-form.module.scss';
import TextAreaField from 'components/forms/TextAreaField';

type Props = {
  financialCenterKey: FinancialCenter;
  category: RessourceCategoryEnum;
};

function AddRessourceForm({ financialCenterKey, category }: Props) {
  const { t } = useTranslation('support');
  const { formContainer, sendButton } = styles;

  const { mutate: addRessource, isLoading } = useAddRessource(financialCenterKey);

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<AddRessourceInput>({
    mode: 'onSubmit',
    resolver: zodResolver(addRessourceInputSchema),
  });
  return (
    <form
      onSubmit={handleSubmit(addRessourceInput =>
        addRessource({
          addRessourceInput,
          category,
          financialCenterKey,
        }),
      )}
      className={formContainer}
    >
      <TextField control={control} name="firstname" label={t('addRessourceForm.firstName')} />
      <TextField control={control} name="lastname" label={t('addRessourceForm.lastName')} />
      <TextField control={control} name="title" label={t('addRessourceForm.title')} />
      <TextAreaField control={control} name="description" label={t('addRessourceForm.description')} />
      <TextField control={control} name="email" label={t('addRessourceForm.email')} />
      <TextField control={control} name="phone" label={t('addRessourceForm.phoneNumber')} />
      <TextField control={control} name="tollFreePhone" label={t('addRessourceForm.tollFreePhoneNumber')} />
      <TextField control={control} name="fax" label={t('addRessourceForm.fax')} />
      <Button type="submit" className={sendButton} disabled={!isDirty}>
        {isLoading ? <LoadingSpinner isMini isWhite /> : t('addRessourceForm.send')}
      </Button>
    </form>
  );
}

export default AddRessourceForm;
