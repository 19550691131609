import { callApi } from 'utils/httpClient';

const userProfileUrl = `${process.env.REACT_APP_USER_PROFILE_SERVICE_URL}`;

type Image = {
  imageContent: string;
  fileExtension: string;
};

async function updateProfileAvatar({ imageContent, fileExtension }: Image) {
  await callApi('put', userProfileUrl, 'me/profile/avatar', {
    contentBase64: imageContent,
    fileExtension: fileExtension,
  });
}

export default updateProfileAvatar;
