import { useMutation, useQueryClient } from 'react-query';
import updateProfileAvatar from 'domain/profile/api/updateProfileAvatar';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTranslation } from 'react-i18next';

function useUpdateProfileAvatar() {
  const queryClient = useQueryClient();
  const { t } = useTranslation('profile');
  const { showSnackbar } = useSnackbarContext();

  return useMutation(updateProfileAvatar, {
    onSuccess: () => {
      showSnackbar({ text: t('updateProfileAvatarMessage'), severity: 'success' });
      return queryClient.invalidateQueries(['profileAvatar']);
    },
    onError: () => showSnackbar({ text: t('failedToUpdateProfileAvatarMessage'), severity: 'danger' }),
  });
}

export default useUpdateProfileAvatar;
