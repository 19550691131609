import useGetProfileInfo from 'domain/profile/hooks/useGetProfileInfo';
import React from 'react';

const testerList = [
  'kgrenier@gcloutier.com',
  'fdeziel@gcloutier.com',
  'spaquin@gcloutier.com',
  'fgoulet@gcloutier.com',
  'fbruneau@gcloutier.com',
  'btrudel@gcloutier.com',
  'earsenault@gcloutier.com',
  'mgaudet@gcloutier.com',
  'bmoffet@gcloutier.com',
  'njourdain@gcloutier.com',
  'GCloutierAdmin@gcloutier.ca',
  'martin.danis@groupedussault.ca',
  'p-l.dufour@servicesfinancierspld.com',
  'info@katialalancette.com',
  'f.tremblay@tremblayfinance.com',
  'pdupuis@sfpauldupuis.com',
  'js@sfrioux.com',
  'nadine@blsf.ca',
  'mjlanthier@blaquiereassocies.com',
  'mathieu.marcil@marcilgestionfinanciere.com',
  'gabrielferland@sfbbf.ca',
  's.poulin@actiprima.com',
  'bernard@boulanger.ws',
  'jean-guy@rioux.ws',
  'cdionne@cs-la.ca',
  'lbeaumier@pbsf.ca',
  'dlavoie@blaquiereassocies.com',
  'robert.calvert@sfrc-inc.ca',
];

function useIsTester() {
  const info = useGetProfileInfo();
  const email = info.data?.email.toLowerCase();
  const indexOf = testerList.findIndex(item => email === item.toLowerCase());

  return indexOf !== -1;
}

export default useIsTester;
