import { Advisor, advisorSchema } from 'types/advisor';
import { z } from 'zod';
import { callApi } from 'utils/httpClient';

const gcServiceUrl = `${process.env.REACT_APP_GC_SERVICE_URL}`;

export async function getAdvisor(): Promise<Advisor | null> {
  const { data } = await callApi('get', gcServiceUrl, 'advisor/me', {});
  return data ? advisorSchema.parse(data) : null;
}

export async function getAdvisorOfOffice(): Promise<Advisor[] | null> {
  const { data } = await callApi('get', gcServiceUrl, 'advisorOffice/me', {});
  return data ? z.array(advisorSchema).parse(data.advisors) : null;
}
