import * as React from 'react';
import { useModalContext } from 'hooks/useModalContext';
import { ConfirmationModalText } from 'components/typography/ConfirmationModalText';
import { ModalProps } from 'components/layouts/Modal';

type ShowConfirmationModalParams = {
  message: string
  title?: string,
  // If you have a parent modal, remember to hideModal() in your onSubmit
  hasParentModal?: boolean;
} & Pick<ModalProps, 'onSubmit' | 'submitText' | 'onCancel'>;

export function useShowConfirmationModal() {
  const { showModal } = useModalContext();

  return {
    showConfirmationModal: ({ message, title, onSubmit, submitText, hasParentModal = false, onCancel }: ShowConfirmationModalParams) =>
      showModal({
        createComponent: () => <ConfirmationModalText title={title} message={message} />,
        onSubmit,
        submitText,
        onCancel,
        closeOnSubmit: !hasParentModal,
        showCloseBtn: false,
      }),
  };
}
