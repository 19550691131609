import { equisoftClientSchema } from 'types/equisoftClient';
import { callApi } from 'utils/httpClient';

const gcServiceUrl = `${process.env.REACT_APP_GC_SERVICE_URL}`;

async function getEquisoftClient() { 
    const { data } = await callApi('get', gcServiceUrl, '/equisoft/client');
    return data ? equisoftClientSchema.parse(data) : data;
}

export default getEquisoftClient