import { callApi } from 'utils/httpClient';

const gcServiceUrl = `${process.env.REACT_APP_GC_SERVICE_URL}`;

async function deleteRessource(ressourceId: string): Promise<void> {
  const { data } = await callApi('delete', gcServiceUrl, `contact/${ressourceId}`);
  return data;
}

export default deleteRessource;
