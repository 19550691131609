import React from 'react';
import styles from 'styles/domain/calendar/event-registration.module.scss';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icons/Icon';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';
import { UserRole } from 'types/auth';

type Props = {
  src: string;
  onDeleteButtonClick: () => unknown;
};

export default function EventRegistration({ src, onDeleteButtonClick }: Props) {
  const { iframe, deleteBtn } = styles;
  const { t } = useTranslation('admin');
  const { userRole } = useAuthContext();

  return (
    <>
      {userRole === UserRole.ADMIN && (
        <button className={deleteBtn} aria-label="delete-button" onClick={onDeleteButtonClick}>
          <Icon name="remove" />
          <p>{t('deleteEvent', { ns: 'admin' })}</p>
        </button>
      )}
      <iframe src={src} title="Event Inscription" className={iframe}></iframe>
    </>
  );
}
