import React from 'react';
import Icon from 'components/icons/Icon';
import styles from 'styles/components/inputs/fileInput/added-file-list.module.scss';
import { useTranslation } from 'react-i18next';

type AddedFileListProps = {
  files: File[];
  onRemove: (index: number) => unknown;
};

function AddedFileList({ files, onRemove }: AddedFileListProps) {
  const { t } = useTranslation('genericItem');

  return (
    <div className={styles.fileListWrapper}>
      <div className={styles.fileList}>
        {files.map((file, index) => (
          <div key={index} className={styles.fileWrapper}>
            <p className={styles.fileName}>{file.name}</p>
            <p className={styles.fileSize}>{`${(file.size / 1000000).toFixed(2)} ${t('megaBytes')}`}</p>
            <button type="button" onClick={() => onRemove(index)} className={styles.fileButton}>
              <Icon name="remove" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AddedFileList;
