import * as React from 'react';
import Modal, { ModalProps } from 'components/layouts/Modal';
import styles from 'styles/contexts/modal-provider.module.scss';

type ModalRequest = {
  readonly createComponent: () => React.ReactNode;
} & Pick<ModalProps, 'onSubmit' | 'submitText' | 'onCancel' | 'showCloseBtn' | 'cancellable' | 'closeOnSubmit'>;

type ModalRequestWithKey = {
  readonly key: number;
} & ModalRequest;

function ModalProvider({ children }: React.PropsWithChildren<unknown>) {
  const [requests, setRequests] = React.useState<ModalRequestWithKey[]>([]);
  const [nextKey, setNextKey] = React.useState(1);

  function showModal(request: ModalRequest) {
    setRequests([...requests, { ...request, key: nextKey }]);
    setNextKey(nextKey + 1);
  }

  function hideModal() {
    setRequests(requests.slice(0, -1));
  }

  return (
    <ModalContext.Provider value={{ showModal, hideModal, isModalOpen: !!requests.length }}>
      <div className={styles.container}>
        {children}
        {requests.map((request, i) => {
          const isInFront = i === requests.length - 1;

          return (
            <React.Fragment key={request.key}>
              {isInFront && <div className={styles.backdrop} />}
              <Modal onClose={hideModal} isInFront={isInFront} {...request}>
                {request.createComponent()}
              </Modal>
            </React.Fragment>
          );
        })}
      </div>
    </ModalContext.Provider>
  );
}

const ModalContext = React.createContext<{
  readonly showModal: (request: ModalRequest) => void;
  readonly hideModal: () => void;
  readonly isModalOpen: boolean;
}>({ showModal: () => void 0, hideModal: () => void 0, isModalOpen: false });

export { ModalContext };
export default ModalProvider;
