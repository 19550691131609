import { GenericSectionItem } from 'types/tools';
import lifeInsuranceImg from 'assets/img/genericItem/icon-assurance-vie.png';
import livingBenefitsImg from 'assets/img/genericItem/icon-prestation-vivant.png';
import protekSolutionsImg from 'assets/img/genericItem/icon-protek.png';
import mortgageImg from 'assets/img/genericItem/icon-hypotheque.png';
import investmentsImg from 'assets/img/genericItem/icon-investissements.png';
import groupInsuranceImg from 'assets/img/genericItem/icon-assurance-collective.png';
import entrepreneursImg from 'assets/img/genericItem/icon-entrepreneurs.png';
import privateMarketImg from 'assets/img/genericItem/icon-particulier.png';
import travelInsuranceImg from 'assets/img/genericItem/icon-assurance-voyage.png';

const lifeInsurance: GenericSectionItem = {
  title: 'lifeInsurance',
  subSections: [
    { title: 'sellingTools', documentsSectionKey: 'lifeInsurance_sellingTools' },
    { title: 'calculators', documentsSectionKey: 'lifeInsurance_calculators' },
  ],
  image: lifeInsuranceImg,
};

const livingBenefits: GenericSectionItem = {
  title: 'livingBenefits',
  subSections: [{ title: 'sellingTools', documentsSectionKey: 'livingBenefits_sellingTools' }],
  image: livingBenefitsImg,
};

const protekSolutions: GenericSectionItem = {
  title: 'protekSolutions',
  subSections: [{ title: 'sellingTools', documentsSectionKey: 'protekSolutions_sellingTools' }],
  image: protekSolutionsImg,
};

const mortgage: GenericSectionItem = {
  title: 'mortgage',
  subSections: [
    { title: 'sellingTools', documentsSectionKey: 'mortgage_sellingTools' },
    { title: 'rates', ratesKey: 'taux' },
  ],
  image: mortgageImg,
};

const investments: GenericSectionItem = {
  title: 'investments',
  subSections: [
    { title: 'sellingTools', documentsSectionKey: 'investments_sellingTools' },
    { title: 'planningTools', documentsSectionKey: 'investments_planningTools' },
    { title: 'analyticsTools', documentsSectionKey: 'investments_analyticsTools' },
    { title: 'freeCalculators', documentsSectionKey: 'investments_freeCalculators' },
  ],
  image: investmentsImg,
};

const groupInsurance: GenericSectionItem = {
  title: 'groupInsurance',
  subSections: [
    { title: 'insurance', documentsSectionKey: 'groupInsurance_insurance' },
    { title: 'annuities', documentsSectionKey: 'groupInsurance_annuities' },
    { title: 'partnerOffers', documentsSectionKey: 'groupInsurance_partnerOffers' },
  ],
  image: groupInsuranceImg,
};

const entrepreneurMarket: GenericSectionItem = {
  title: 'entrepreneurMarket',
  subSections: [{ title: 'sellingTools', documentsSectionKey: 'entrepreneurMarket_sellingTools' }],
  image: entrepreneursImg,
};

const privateMarket: GenericSectionItem = {
  title: 'privateMarket',
  subSections: [{ title: 'sellingTools', documentsSectionKey: 'privateMarket_sellingTools' }],
  image: privateMarketImg,
};

const travelInsurance: GenericSectionItem = {
  title: 'travelInsurance',
  subSections: [{ title: 'sellingTools', documentsSectionKey: 'travelInsurance_sellingTools' }],
  image: travelInsuranceImg,
};

const tools = [
  lifeInsurance,
  livingBenefits,
  protekSolutions,
  mortgage,
  investments,
  groupInsurance,
  entrepreneurMarket,
  privateMarket,
  travelInsurance,
];

export default tools;
