import { z } from 'zod';
import { callApi } from 'utils/httpClient';
import { Venue, venueSchema } from 'types/venue';

const wcmServiceUrl = `${process.env.REACT_APP_WCM_SERVICE_URL}`;

async function getVenues(): Promise<Venue[]> {
  const { data } = await callApi('get', wcmServiceUrl, 'venue', {});
  return z.array(venueSchema).parse(data);
}

export default getVenues;
