import { callApi } from 'utils/httpClient';

const userProfileUrl = `${process.env.REACT_APP_USER_PROFILE_SERVICE_URL}`;

async function getProfileAvatar() {
  const { data } = await callApi('get', userProfileUrl, 'me/profile/avatar');
  return data;
}

export default getProfileAvatar;
