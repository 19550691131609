import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icons/Icon';
import styles from 'styles/components/navigation/reset-all-filters-button.module.scss';
import { useFilterContext } from 'domain/search/contexts/FilterProvider';

function ResetAllFiltersButton() {
  const { t } = useTranslation('search');

  const { resetAllFilters } = useFilterContext();

  const { wrapper, label } = styles;

  return (
    <div className={wrapper} onClick={resetAllFilters}>
      <Icon name={'remove'} />
      <p className={label}>{t('resetAllFilters')}</p>
    </div>
  );
}

export default ResetAllFiltersButton;
