import { useMutation, useQueryClient } from 'react-query';
import addRecentDocument from 'domain/favorites/api/addRecentDocument';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import { getRecentDocumentsQueryKey } from 'domain/favorites/hooks/useGetRecentDocuments';

function useAddRecentDocument() {
  const queryClient = useQueryClient();
  const { t } = useTranslation('genericItem');
  const { showSnackbar } = useSnackbarContext();

  return useMutation(addRecentDocument, {
    onSuccess: () => {
      return queryClient.invalidateQueries([getRecentDocumentsQueryKey]);
    },
    onError: () => showSnackbar({ text: t('failedToAddRecentDocumentMessage'), severity: 'danger' }),
  });
}

export default useAddRecentDocument;
