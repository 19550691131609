import React from 'react';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import Table from 'components/shared/Table/Table';
import styles from 'styles/domain/transactions/transactions.module.scss';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'types/transaction';
import { UserRole } from 'types/auth';
import Button from 'components/inputs/Button';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { TransactionsGroup } from 'domain/transactions/api/getTransactions';
import useGetTransactions from 'domain/transactions/hooks/useGetTransactions';

type Props = {
  transactionsGroup: TransactionsGroup;
  userRole?: UserRole;
};

const formatSentDate = (utcDate: string) => {
  return moment(utcDate).tz('America/New_York').format('YYYY-MM-DD HH:mm');
};

function TransactionsTable({ transactionsGroup, userRole }: Props) {
  const { t } = useTranslation('transactions');

  const { wrapper, table, spinnerContainer, noTransactions } = styles;

  const { data: transactions, isLoading } = useGetTransactions(transactionsGroup);

  const columnHelper = createColumnHelper<Transaction>();
  const navigate = useNavigate();

  const columns = [
    columnHelper.accessor('client', {
      header: () => t('table.clientName'),
      cell: info => info.getValue(),
    }),
    columnHelper.accessor(row => new Date(formatSentDate(row.createdDateTimeUtc)), {
      id: 'createdDateTimeUtc',
      header: () => t('table.sendDate'),
      cell: info => formatSentDate(info.getValue().toUTCString()),
      filterFn: (row, id, filterValue): boolean => {
        if (filterValue && filterValue.length > 0) {
          const start = (filterValue[0] as Date).getTime();
          const end = (filterValue[1] as Date).getTime();
          const current = new Date(row.original.createdDateTimeUtc).getTime();

          return current >= start && current <= end;
        }
        return true;
      },
    }),
    columnHelper.accessor(
      row => row.businessUnitKeys.map(businessKey => t(businessKey, { ns: 'genericItem' })).join(', '),
      {
        id: 'businessUnitKeys',
        header: () => t('table.businessUnit'),
        cell: info => info.getValue(),
      },
    ),
    columnHelper.accessor(row => t(row.categoryKey, { ns: 'common' }), {
      id: 'categoryKey',
      header: () => t('table.category'),
      cell: info => info.getValue(),
    }),
    columnHelper.accessor(row => t(`status.${row.status}`), {
      id: 'status',
      header: () => t('table.status'),
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('notes', {
      header: () => t('table.notes'),
      cell: info => info.getValue(),
    }),
    columnHelper.accessor(row => row.documents.length, {
      id: 'documents',
      header: () => t('table.attachedDocuments'),
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('publicId', {
      header: () => '',
      cell: info => <Button onClick={() => navigate(`${info.getValue()}`)}>{t('open')}</Button>,
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];

  const adminColumns = [
    columnHelper.accessor(row => `${row.bidder.firstname} ${row.bidder.lastname}`, {
      id: 'bidder',
      header: () => t('table.advisor'),
      cell: info => info.getValue(),
      enableHiding: userRole === UserRole.END_USER,
    }),
  ];

  return (
    <div className={wrapper}>
      {isLoading && (
        <div className={spinnerContainer}>
          <LoadingSpinner />
        </div>
      )}
      {transactions &&
        (transactions.length > 0 ? (
          <Table
            columns={
              userRole === UserRole.ADMIN || userRole === UserRole.STAFF ? [...adminColumns, ...columns] : columns
            }
            data={transactions}
            className={table}
            usePagination
            useSorting
            useFiltering
            sortingDefault={[
              {
                id: 'createdDateTimeUtc',
                desc: true,
              },
            ]}
          />
        ) : (
          <p className={noTransactions}>{t('noTransactionsMessage')}</p>
        ))}
    </div>
  );
}

export default TransactionsTable;
