import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'styles/domain/equisoft/profile-equisoft.module.scss';
import { useShowConfirmationModal } from 'hooks/useShowConfirmationModal';
import CheckboxInput from 'components/inputs/CheckboxInput';
import useGetEquisoftAuthLink from 'domain/equisoft/hooks/useGetEquisoftAuthLink';
import useGetEquisoftConfig from '../hooks/useGetEquisoftConfig';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useDeleteEquisoftConfig from '../hooks/useDeleteEquisoftConfig';

function ProfileEquisoft() {
  const { t } = useTranslation('profile');
  const { titleEquisoft, descEquisoft, successEquisoft } = styles;
  const { showConfirmationModal } = useShowConfirmationModal();
  const [isEquisoftSynchronize, setIsEquisoftSynchronize] = React.useState(false);
  const { mutate: getUrlAuthEquisoft } = useGetEquisoftAuthLink();
  const { mutate: deleteEquisoftConfig } = useDeleteEquisoftConfig(() => setIsEquisoftSynchronize(false));
  const { data: equisoftConfig, isLoading: equisoftConfigIsLoading } = useGetEquisoftConfig();

  useEffect(() => {
    setIsEquisoftSynchronize(equisoftConfig?.email !== null && equisoftConfig?.email !== undefined && equisoftConfig?.isRefreshTokenValid === true);
  }, [equisoftConfig, equisoftConfig?.isRefreshTokenValid]);

  const handleCheckbox = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (event.currentTarget.checked) {
      getUrlAuthEquisoft();
    } else {
      showConfirmationModal({
        message: t('disableEquisoftDesc'),
        title: t('submitTextEquisoft'),
        onSubmit: () => deleteEquisoftConfig(),
        onCancel: () => {},
        submitText: t('submitTextEquisoft'),
      });
    }
  };

  return (
    <>
      <h2 id="refresh" className={titleEquisoft}>
        {t('titleEquisoft')}
      </h2>
      <p className={descEquisoft}>{t('descEquisoft')}</p>
      {equisoftConfigIsLoading ? (
        <LoadingSpinner />
      ) : (
        <CheckboxInput
          readOnly
          id="synchronisation"
          label={t('labelEquisoft')}
          name="synchronisation"
          onClick={e => handleCheckbox(e)}
          checked={isEquisoftSynchronize}
        />
      )}
      {isEquisoftSynchronize && (
        <p className={successEquisoft}>
          {t('synchroEnableEquisoft1')} <strong>{equisoftConfig?.email}</strong> {t('synchroEnableEquisoft2')}
        </p>
      )}
    </>
  );
}
export default ProfileEquisoft;
