import React from 'react';
import { useModalContext } from 'hooks/useModalContext';
import type { Severity } from 'types/severity';
import OutputMessage from 'components/feedback/OutputMessage';

type OutputProps = {
  text: string;
  severity: Severity;
  submitText: string;
  onSubmit?: (() => unknown) | undefined;
  note?: string;
};

export function useShowOutputMessage() {
  const { showModal } = useModalContext();
  return {
    showConfirmationModal: ({ text, severity, submitText, onSubmit, note }: OutputProps) =>
      showModal({
        createComponent: () => <OutputMessage text={text} severity={severity} note={note} />,
        cancellable: false,
        onSubmit: onSubmit,
        submitText: submitText,
        closeOnSubmit: true,
      }),
  };
}
