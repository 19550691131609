import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { ClassificationType, SearchFilterType } from 'types/classification';
import useGetClassificationType from 'domain/search/hooks/useGetClassificationType';
import { useTranslationContext } from 'contexts/TranslationProvider';

type FilterState = {
  sectionsFilter: SearchFilterType[];
  providersFilter: SearchFilterType[];
  categoriesFilter: SearchFilterType[];
  languagesFilter: SearchFilterType[];
  setSectionsFilter: Dispatch<SetStateAction<SearchFilterType[]>>;
  setProvidersFilter: Dispatch<SetStateAction<SearchFilterType[]>>;
  setCategoriesFilter: Dispatch<SetStateAction<SearchFilterType[]>>;
  setLanguagesFilter: Dispatch<SetStateAction<SearchFilterType[]>>;
  getCheckedFilters: () => SearchFilterType[];
  resetAllFilters: () => void;
  filterData: ClassificationType | undefined;
  isFilterLoading: boolean;
};

type Props = {
  children: ReactNode;
};

function FilterProvider({ children }: Props) {
  const { Provider } = SearchContext;

  const { language } = useTranslationContext();

  const { data: filterData, isFetched, isLoading: isFilterLoading } = useGetClassificationType(language);

  const [sectionsFilter, setSectionsFilter] = useState<SearchFilterType[]>([]);
  const [providersFilter, setProvidersFilter] = useState<SearchFilterType[]>([]);
  const [categoriesFilter, setCategoriesFilter] = useState<SearchFilterType[]>([]);
  const [languagesFilter, setLanguagesFilter] = useState<SearchFilterType[]>([]);

  useEffect(() => {
    if (isFetched && filterData) {
      setSectionsFilter(filterData.sections);
      setProvidersFilter(filterData.providers);
      setCategoriesFilter(filterData.categories);
      setLanguagesFilter(filterData.languages);
    }
  }, [isFetched, language]);

  const getCheckedFilters = () => {
    return [
      {
        type: 'Datasource',
        key: 'Forms',
        description: 'Forms',
        isChecked: true,
      },
      ...sectionsFilter.filter(f => f.isChecked),
      ...providersFilter.filter(f => f.isChecked),
      ...categoriesFilter.filter(f => f.isChecked),
      ...languagesFilter.filter(f => f.isChecked),
    ];
  };

  const resetAllFilters = () => {
    setSectionsFilter(sectionsFilter.map(filter => ({ ...filter, isChecked: false })));
    setProvidersFilter(providersFilter.map(filter => ({ ...filter, isChecked: false })));
    setCategoriesFilter(categoriesFilter.map(filter => ({ ...filter, isChecked: false })));
    setLanguagesFilter(languagesFilter.map(filter => ({ ...filter, isChecked: false })));
  };

  const value = {
    sectionsFilter,
    setSectionsFilter,
    providersFilter,
    setProvidersFilter,
    categoriesFilter,
    setCategoriesFilter,
    languagesFilter,
    setLanguagesFilter,
    getCheckedFilters,
    resetAllFilters,
    filterData,
    isFilterLoading,
  };

  return <Provider value={value}>{children}</Provider>;
}

const SearchContext = createContext<FilterState>({} as FilterState);

export function useFilterContext() {
  return useContext(SearchContext);
}

export default FilterProvider;
