import React from 'react';
import styles from 'styles/domain/sellingTools/table/selling-tool-doc-table-item.module.scss';
import Icon from 'components/icons/Icon';
import { SectionDocument } from 'types/document';
import useDownload from 'utils/useDownload';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useAddRecentDocument from 'domain/favorites/hooks/useAddRecentDocument';
import useAddFavoriteDocument from 'domain/favorites/hooks/useAddFavoriteDocument';
import useDeleteDocument from 'domain/sellingTools/hooks/useDeleteDocument';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';
import { UserRole } from 'types/auth';
import useRemoveFavoriteDocument from 'domain/favorites/hooks/useRemoveFavoriteDocument';

type Props = {
  document: SectionDocument;
};

function DocumentListItem({ document }: Props) {
  const { item, name, iconWrapper, button, buttonLink } = styles;

  const { userRole } = useAuthContext();
  const { handleDownload } = useDownload();
  const { mutate: addRecentDocument } = useAddRecentDocument();
  const { mutate: deleteDocument, isLoading: isDeletingDocument } = useDeleteDocument(document.sectionKey);
  const { mutate: addFavoriteDocument, isLoading: isAddingFavorite } = useAddFavoriteDocument();
  const { mutate: removeFavoriteDocument, isLoading: isRemovingFavorite } = useRemoveFavoriteDocument();

  const { refetch: downloadDocument, isFetching: isDownloading } = handleDownload(
    document.publicId,
    document.storageUrl,
    document.filename,
  );

  return (
    <div className={item}>
      {document.documentType !== 'link' ? <Icon name="pdf" /> : <Icon name="link" />}
      <p className={name}>{document.title}</p>
      <div className={iconWrapper}>
        {!document.isFavorite && (
          <button className={button} onClick={() => addFavoriteDocument(document.publicId)}>
            {isAddingFavorite ? <LoadingSpinner isMini={true} /> : <Icon name={'starPlus'} />}
          </button>
        )}
        {document.isFavorite && (
          <button className={button} onClick={() => removeFavoriteDocument(document.publicId)}>
            {isRemovingFavorite ? <LoadingSpinner isMini={true} /> : <Icon name={'starMinus'} />}
          </button>
        )}
        {document.documentType !== 'link' ? (
          <button
            className={button}
            onClick={() => {
              downloadDocument();
              addRecentDocument(document.publicId);
            }}
          >
            {isDownloading ? <LoadingSpinner isMini={true} /> : <Icon name={'download'} />}
          </button>
        ) : (
          <a href={document.storageUrl} target="_blank" rel="noopener noreferrer">
            <button
              className={`${button} ${buttonLink}`}
              onClick={() => {
                addRecentDocument(document.publicId);
              }}
            >
              <Icon name="link" />
            </button>
          </a>
        )}
        {userRole === UserRole.ADMIN && (
          <button
            className={button}
            onClick={() => {
              deleteDocument(document.publicId);
            }}
          >
            {isDeletingDocument ? <LoadingSpinner isMini={true} /> : <Icon name={'remove'} />}
          </button>
        )}
      </div>
    </div>
  );
}

export default DocumentListItem;
