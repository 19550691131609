import React from 'react';
import RSelect, { Props as RSelectProps, createFilter, components, GroupProps } from 'react-select';
import styles from 'styles/components/inputs/select.module.scss';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import RequiredMarker from 'components/typography/RequiredMarker';
import { List, AutoSizer } from 'react-virtualized';

type SelectProps = {
  label: string;
  errorMessage?: string;
  isRequired?: boolean;
  group?: boolean;
} & RSelectProps;

// Using "any" here, because react-select did not do their job defining things well
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Select = React.forwardRef<any, SelectProps>(
  ({ label, errorMessage, isMulti = false, isRequired,group, ...props }, ref) => {
    const { customSelect, selectLabel } = styles;

    // Resets select when previous value isn't listed in new values
    const key = `select-${props.value}`;

    return (
      <div>
        <label className={selectLabel} data-testid={label.toLowerCase()} htmlFor={label.toLowerCase()}>
          {label} <RequiredMarker isRequired={isRequired} />
        </label>
        {group ? (
          <RSelect
            ref={ref}
            styles={{
              group: () => ({
                marginLeft: 24,
              }),
              groupHeading: () => ({
                marginLeft: -10,
                color: "gray"
              }),
              
            }}
            filterOption={createFilter({ ignoreAccents: false })}
            classNamePrefix={customSelect} 
            inputId={label.toLowerCase()}
            isMulti={isMulti}
            key={key}
            {...props}
          />
        ) : (
          <RSelect
            ref={ref}
            components={{ MenuList: MenuListCustom }}
            filterOption={createFilter({ ignoreAccents: false })}
            classNamePrefix={customSelect}
            inputId={label.toLowerCase()}
            isMulti={isMulti}
            key={key}
            {...props}
          />
        )}

        <ErrorMessage errorMessage={errorMessage} />
      </div>
    );
  },
);

Select.displayName = 'Select';

export type { SelectProps };
export default Select;

const MenuListCustom = (props: { children: any }) => {
  const rows = props.children;
  const rowRenderer = ({
    key,
    index,
    style,
  }: {
    key: string;
    index: number;
    isScrolling: boolean;
    isVisible: boolean;
    style: React.CSSProperties;
  }) => (
    <div key={key} style={style}>
      {rows[index]}
    </div>
  );

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
        style={{minHeight: 35}}
          width={width}
          height={rows.length <= 10 ? rows.length * 35 : 350}
          rowHeight={35}
          rowCount={rows.length ? rows.length : 0}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
};
