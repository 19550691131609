import React, { useState } from 'react';
import { NewsArticle } from 'types/news';
import styles from 'styles/domain/home/components/news-card.module.scss';
import Icon from 'components/icons/Icon';
import { useTranslation } from 'react-i18next';
import eventAndNewsStyles from 'styles/components/shared/event-and-news-label.module.scss';
import baseImg from 'domain/home/stubs/img/mockImage.png';
import useGetBusinessUnit from 'domain/businessUnit/hooks/useGetBusinessUnit';
import useRemoveNews from 'domain/home/hooks/useRemoveNews';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';
import { UserRole } from 'types/auth';
import { useShowConfirmationModal } from 'hooks/useShowConfirmationModal';
import moment from 'moment';

const { newLabel, peopleInsurance, investments, groupInsurance, mortgage, contest, general } = eventAndNewsStyles;

const newsTypeClassName: Record<string, string> = {
  peopleInsurance: peopleInsurance,
  investments: investments,
  groupInsuranceAndAnnuities: groupInsurance,
  mortgage: mortgage,
  contestAndTraining: contest,
  general: general,
};

function NewsCard({
  imageUrl,
  externalInformationUrl,
  content,
  title,
  businessUnitIds,
  publicId,
  displayEndDatetimeUtc,
  displayStartDatetimeUtc,
}: NewsArticle) {
  const { t } = useTranslation('common');

  const { userRole } = useAuthContext();

  const [isBeingRemoved, setIsBeingRemoved] = useState(false);

  const { showConfirmationModal } = useShowConfirmationModal();

  const { mutate } = useRemoveNews({
    onSuccess: () => setIsBeingRemoved(false),
    onError: () => setIsBeingRemoved(false),
  });

  const {
    container,
    cardBeingRemoved,
    textContainer,
    card,
    cardTitle,
    cardText,
    cardHeader,
    imgContainer,
    cardImage,
    learnMoreButton,
    removeBtn,
    cardStatusText,
  } = styles;

  const { data: businessUnits } = useGetBusinessUnit();

  const newsType = businessUnits?.find(unit => unit.publicId === businessUnitIds[0]);

  const onRemoveNews = (newsId: string) => {
    setIsBeingRemoved(true);
    mutate(newsId);
  };

  const isNewExpired = moment().utc().isAfter(moment(displayEndDatetimeUtc));
  const isNewNotYetShown = moment().utc().isBefore(moment(displayStartDatetimeUtc));

  const onShowRemoveNewsModal = () => {
    showConfirmationModal({
      message: t('deleteNewsConfirmationMessage', { ns: 'forms' }),
      onSubmit: () => onRemoveNews(publicId ?? ''),
      submitText: t('delete', { ns: 'common' }),
    });
  };

  return (
    <>
      <div className={`${container} ${isBeingRemoved ? cardBeingRemoved : ''}`}>
        <div className={imgContainer}>
          <img className={cardImage} src={imageUrl ? imageUrl : baseImg} alt={t('image', { ns: 'admin' })} />
        </div>
        <div className={card}>
          <div className={cardHeader}>
            {newsType && <div className={`${newLabel} ${newsTypeClassName[newsType.name]}`}>{t(newsType.name)}</div>}
            {userRole === UserRole.ADMIN && isNewExpired && (
              <p className={cardStatusText}>{t('expired', { ns: 'home' })}</p>
            )}
            {userRole === UserRole.ADMIN && isNewNotYetShown && (
              <p className={cardStatusText}>{t('notYetShown', { ns: 'home' })}</p>
            )}
            {userRole === UserRole.ADMIN && (
              <button className={removeBtn} onClick={onShowRemoveNewsModal}>
                <Icon name="remove" />
              </button>
            )}
          </div>
          <div className={textContainer}>
            <h2 className={cardTitle}>{title}</h2>
            <p className={cardText}>{content}</p>
            {externalInformationUrl && (
              <button className={learnMoreButton}>
                <Icon name={'rightArrowGreen'} />
                <a href={externalInformationUrl} target="_blank" rel="noreferrer">
                  {t('learnMore')}
                </a>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsCard;
