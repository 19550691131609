import { useMutation, useQueryClient } from 'react-query';
import deleteMeet from 'domain/calendar/api/deleteMeet';
import { meetQueryKey } from 'domain/calendar/hooks/useGetCalendarEvents';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTranslation } from 'react-i18next';

function useDeleteEvent() {
  const { t } = useTranslation('admin');
  const { showSnackbar } = useSnackbarContext();
  const queryClient = useQueryClient();
  return useMutation(deleteMeet, {
    onSuccess: () => {
      showSnackbar({ text: t('deleteEventMessage'), severity: 'success' });
      queryClient.invalidateQueries(meetQueryKey);
    },
    onError: () => showSnackbar({ text: t('failedToRemoveEventMessage'), severity: 'danger' }),
  });
}

export default useDeleteEvent;
