import React from 'react';
import Icon from 'components/icons/Icon';
import styles from 'styles/components/navigation/search-result-item.module.scss';
import { Document, DocumentType } from 'types/document';
import CompanyLogo from 'components/logos/CompanyLogo';
import useAddFavoriteDocument from 'domain/favorites/hooks/useAddFavoriteDocument';
import useRemoveFavoriteDocument from 'domain/favorites/hooks/useRemoveFavoriteDocument';
import useDownload from 'utils/useDownload';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useAddRecentDocument from 'domain/favorites/hooks/useAddRecentDocument';
import DocumentIcon from 'components/icons/DocumentIcon';

type Props = {
  document: Document;
};

function SearchResultItem({ document }: Props) {
  const { mutate: addFavoriteDocument, isLoading: isAddingFavorite } = useAddFavoriteDocument();
  const { mutate: addRecentDocument } = useAddRecentDocument();
  const { mutate: removeFavoriteDocument, isLoading: isRemovingFavorite } = useRemoveFavoriteDocument();
  const { handleDownload } = useDownload();
  const { refetch: downloadDocument, isFetching } = handleDownload(
    document.publicId,
    document.storageUrl,
    document.filename,
  );

  const { documentItem, documentInfo, documentInfoHeader, documentTitle, documentIconWrapper, button } = styles;

  return (
    <div className={documentItem}>
      <CompanyLogo provider={document.provider} />
      <div className={documentInfo}>
        <div className={documentInfoHeader}>
          <DocumentIcon documentType={document.documentType} />
          <h2 className={documentTitle}>{document.title}</h2>
        </div>
      </div>
      <div className={documentIconWrapper}>
        {!document.isFavorite && (
          <button className={button} onClick={() => addFavoriteDocument(document.publicId)}>
            {isAddingFavorite ? <LoadingSpinner isMini={true} /> : <Icon name={'starPlus'} />}
          </button>
        )}
        {document.isFavorite && (
          <button className={button} onClick={() => removeFavoriteDocument(document.publicId)}>
            {isRemovingFavorite ? <LoadingSpinner isMini={true} /> : <Icon name={'starMinus'} />}
          </button>
        )}

        {document.documentType !== DocumentType.LINK ? (
          <button
            className={button}
            onClick={() => {
              downloadDocument();
              addRecentDocument(document.publicId);
            }}
          >
            {isFetching ? <LoadingSpinner isMini={true} /> : <Icon name={'download'} />}
          </button>
        ) : (
          <a href={document.storageUrl} target="_blank" rel="noopener noreferrer">
            <button className={`${button}`} onClick={() => addRecentDocument(document.publicId)}>
              <Icon name="link" />
            </button>
          </a>
        )}
      </div>
    </div>
  );
}

export default SearchResultItem;
