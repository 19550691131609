import React from 'react';
import styles from 'styles/domain/calendar/calendar-legend.module.scss';
import { useTranslation } from 'react-i18next';

export default function CalendarLegend() {
  const { t } = useTranslation('common');

  const { legend, inPerson, online, hybrid } = styles;

  return (
    <div className={legend}>
      <p className={inPerson}>{t('inPerson')}</p>
      <p className={online}>{t('online')}</p>
      <p className={hybrid}>{t('hybrid')}</p>
    </div>
  );
}
