import styles from 'styles/domain/calendar/contest-section.module.scss';
import React from 'react';
import Icon from 'components/icons/Icon';
import { useTranslation } from 'react-i18next';
type Props = {
  isCollapsed: boolean;
  setCollapse: (isCollapse: boolean) => void;
  trackIndex: number;
  max: number;
  onSlideClick: (i: number) => void;
};
export default function ContestSectionHeader({ isCollapsed, setCollapse, trackIndex, max, onSlideClick }: Props) {
  const { t } = useTranslation('calendar');

  const { rotate, header, title, button, rightButtons } = styles;

  return (
    <div className={header}>
      <button className={`${button} ${!isCollapsed ? rotate : ''}`} onClick={() => setCollapse(!isCollapsed)}>
        <Icon name="downCaret" />
      </button>
      <h2 className={title}>{t('contest')}</h2>
      {max > 0 && (
        <div className={rightButtons}>
          <button className={button} onClick={() => onSlideClick(trackIndex - 1)} disabled={trackIndex === 0}>
            <Icon name="leftArrow" />
          </button>
          <button className={button} onClick={() => onSlideClick(trackIndex + 1)} disabled={trackIndex === max}>
            <Icon name="rightArrow" />
          </button>
        </div>
      )}
    </div>
  );
}
