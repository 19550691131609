import { GenericSectionItem } from 'types/tools';
import outilsImg from 'assets/img/genericItem/icon-outils.png';

const tools: GenericSectionItem = {
  title: 'tools',
  subSections: [
    { title: 'tools', documentsSectionKey: 'tools_tools' },
    { title: 'wConnectStuff', documentsSectionKey: 'tools_wConnectStuff' },
  ],
  image: outilsImg,
};

const clientsItems = [tools];

export default clientsItems;
