import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'styles/components/navigation/search-bar.module.scss';
import searchButtonIcon from 'assets/img/search-icon.png';
import closeButtonIcon from 'assets/img/close-icon.png';
import SearchDropdown from 'domain/search/components/SearchDropdown';
import useDocumentSearch from 'domain/search/hooks/useDocumentSearch';
import { useDebounce } from 'hooks/useDebounce';
import useGetFavoriteDocuments from 'domain/favorites/hooks/useGetFavoriteDocuments';
import { useFilterContext } from 'domain/search/contexts/FilterProvider';
import { useHandleOutOfComponentClick } from 'hooks/useHandleOutOfComponentClick';
import { useModalContext } from 'hooks/useModalContext';

function SearchBar() {
  const { t } = useTranslation('navigation');
  const minCharacterStartSearch = 3;
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryInput, setSearchQueryInput] = useState('');
  const [, setSelectedTab] = useState('formAndProposal');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { getCheckedFilters, isFilterLoading } = useFilterContext();

  const { isModalOpen } = useModalContext();

  const debouncedSearchTerm = useDebounce(searchQuery);

  const {
    data: formAndProposalTabPaginatedDocuments,
    isLoading: formAndProposalTabIsDocumentLoading,
    isFetching: formAndProposalTabIsDocumentFetching,
    fetchNextPage: formAndProposalTabFetchNextPage,
    hasNextPage: formAndProposalTabHasNextPage,
  } = useDocumentSearch(
    {
      label: debouncedSearchTerm,
      filters: getCheckedFilters(),
      pageSize: 20,
      currentPage: 1,
      assumeEndWildcardPattern: true,
    },
    isDropdownOpen,
  );

  const {
    data: otherContentTabPaginatedDocuments,
    isLoading: otherContentTabIsDocumentLoading,
    isFetching: otherContentTabIsDocumentFetching,
    fetchNextPage: otherContentTabFetchNextPage,
    hasNextPage: otherContentTabHasNextPage,
  } = useDocumentSearch(
    {
      label: debouncedSearchTerm,
      filters: [
        {
          description: 'Site',
          isChecked: true,
          type: 'Datasource',
          key: 'Site',
        },
      ],
      pageSize: 20,
      currentPage: 1,
      assumeEndWildcardPattern: true,
    },
    isDropdownOpen,
  );

  const { data: favoriteDocuments } = useGetFavoriteDocuments();

  const closeDropdown = () => {
    setSearchQuery('');
    setSearchQueryInput('');
    setIsDropdownOpen(false);
  };

  const formAndProposalTabOnScrollToBottom = async () => {
    formAndProposalTabFetchNextPage();
  };

  const otherContentTabOnScrollToBottom = () => {
    otherContentTabFetchNextPage();
  };

  const wrapperRef = useRef(null);

  useHandleOutOfComponentClick({ ref: wrapperRef, callback: closeDropdown, enabled: !isModalOpen });

  const memoizedFavoriteDocumentsSet = useMemo(
    () => new Set<string>(favoriteDocuments?.map(document => document.publicId)),
    [favoriteDocuments],
  );

  const formAndProposalTabMappedDocuments = formAndProposalTabPaginatedDocuments?.pages
    .map(page => page.data.results)
    .flat()
    .map(document => ({ ...document, isFavorite: memoizedFavoriteDocumentsSet.has(document.publicId) }));

  const formAndProposalTabNbResult = formAndProposalTabPaginatedDocuments?.pages[0].data.totalResult;

  const otherContentTabMappedDocuments = otherContentTabPaginatedDocuments?.pages
    .map(page => page.data.results)
    .flat()
    .map(document => ({ ...document, isFavorite: memoizedFavoriteDocumentsSet.has(document.publicId) }));

  const otherContentTabNbResult = otherContentTabPaginatedDocuments?.pages[0].data.totalResult;

  const { wrapper, input, inputWrapper, searchIcon, closeIcon } = styles;

  return (
    <div className={wrapper} ref={isDropdownOpen ? wrapperRef : null}>
      <div className={inputWrapper}>
        <img className={searchIcon} src={searchButtonIcon} alt="Search icon"></img>
        <input
          className={input}
          value={searchQueryInput}
          placeholder={t('searchBarPlaceholder')}
          onChange={e => {
            setSearchQueryInput(e.target.value);
            if (e.target.value.trim() === '' || e.target.value.trim().length >= minCharacterStartSearch) {
              setSearchQuery(e.target.value);
            }
          }}
          onFocus={() => setIsDropdownOpen(true)}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              setSearchQuery(e.currentTarget.value);
            }
          }}
        />
        {searchQuery.length ? (
          <img
            className={closeIcon}
            src={closeButtonIcon}
            onClick={() => {
              closeDropdown();
            }}
            alt="Close icon"
          ></img>
        ) : null}
      </div>

      <SearchDropdown
        isDropdownOpen={isDropdownOpen}
        documents={[formAndProposalTabMappedDocuments ?? [], otherContentTabMappedDocuments ?? []]}
        nbResults={[formAndProposalTabNbResult ?? 0, otherContentTabNbResult ?? 0]}
        isDocumentLoading={[formAndProposalTabIsDocumentLoading, otherContentTabIsDocumentLoading]}
        isDocumentFetching={[formAndProposalTabIsDocumentFetching, otherContentTabIsDocumentFetching]}
        isFilterLoading={isFilterLoading}
        hasQuerySpecified={debouncedSearchTerm !== '' || getCheckedFilters().length > 1}
        hasFilterSpecified={getCheckedFilters().length > 1}
        onTabChange={tab => setSelectedTab(tab)}
        onScrollToBottom={[formAndProposalTabOnScrollToBottom, otherContentTabOnScrollToBottom]}
        tabsHasNextPage={[formAndProposalTabHasNextPage ?? false, otherContentTabHasNextPage ?? false]}
      />
    </div>
  );
}

export default SearchBar;
