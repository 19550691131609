import { callApi } from 'utils/httpClient';
import { z } from 'zod';
import { SectionDocument, sectionDocumentSchema } from 'types/document';
const wcmServiceUrl = `${process.env.REACT_APP_WCM_SERVICE_URL}`;

async function getSectionDocuments(sectionKey: string): Promise<SectionDocument[]> {
  const { data } = await callApi('get', wcmServiceUrl, `document/section/${sectionKey}`);
  return z.array(sectionDocumentSchema).parse(data);
}

export default getSectionDocuments;
