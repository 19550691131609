import { callApi } from 'utils/httpClient';
import { z } from 'zod';
import { MeetEvent, meetSchema } from 'types/calendar';

const wcmServiceUrl = `${process.env.REACT_APP_WCM_SERVICE_URL}`;

async function getMeets(type?: string): Promise<MeetEvent[]> {
  const { data } = await callApi('get', wcmServiceUrl, 'meets', {});
  return z.array(meetSchema).parse(type ? data.filter((d: MeetEvent) => d.eventType === type) : data);
}

export default getMeets;
