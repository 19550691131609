import React from 'react';
import { useTranslation } from 'react-i18next';
import titleStyles from 'styles/components/pages.module.scss';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';
import { UserRole } from 'types/auth';
import TransactionsTable from 'domain/transactions/components/TransactionsTable';
import AdminTransactionsTabs from 'domain/transactions/components/AdminTransactionsTabs';

function TransactionsPage() {
  const { t } = useTranslation('transactions');
  const { title, titleNoBorder, titlePadded } = titleStyles;

  const { userRole } = useAuthContext();

  return (
    <>
      <h1 className={`${title} ${titleNoBorder}`}>{t('transactions')}</h1>
      <p className={`${titlePadded}`}>{t('headerNote')}</p>
      {userRole === UserRole.ADMIN && <AdminTransactionsTabs />}
      {(userRole === UserRole.END_USER || userRole === UserRole.STAFF) && (
        <TransactionsTable transactionsGroup="mine" userRole={userRole} />
      )}
    </>
  );
}

export default TransactionsPage;
