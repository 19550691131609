import React from 'react';
import Icon from 'components/icons/Icon';
import styles from 'styles/components/navigation/logout-button.module.scss';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';
import Tooltip from 'components/shared/Tooltip';
import { useTranslation } from 'react-i18next';

type Props = {
  isMobile?: boolean;
};

function LogoutButton({ isMobile }: Props) {
  const { button } = styles;
  const { signOut } = useAuthContext();
  const { t } = useTranslation('auth');

  return (
    <>
      {isMobile ? (
        <button className={button} onClick={() => signOut()}>
          <Icon name="logoutMobile" />
        </button>
      ) : (
        <Tooltip title={t('signOut')}>
          <button className={button} onClick={() => signOut()}>
            <Icon name="logout" />
          </button>
        </Tooltip>
      )}
    </>
  );
}

export default LogoutButton;
