import React from 'react';
import { Ressource } from 'types/ressources';
import RessourceCard from 'domain/support/components/RessourceCard';
import styles from 'styles/domain/support/ressource-card-list.module.scss';

type Props = {
  ressources?: Ressource[];
  isWhite?: boolean;
  direction: 'column' | 'grid';
};

function RessourcesCardList({ direction, ressources, isWhite = false }: Props) {
  const { ressourceCardList, ressourceCardListGrid, ressourceCardListColumn } = styles;

  return (
    <div
      className={`${ressourceCardList} ${direction === 'column' && ressourceCardListColumn} ${
        direction === 'grid' && ressourceCardListGrid
      }`}
    >
      {ressources?.map((ressource, i) => (
        <RessourceCard ressource={ressource} key={i} isWhite={isWhite} />
      ))}
    </div>
  );
}

export default RessourcesCardList;
