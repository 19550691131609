import React from 'react';
import styles from 'styles/components/shared/checkbox.module.scss';

type Props = {
  label: string;
  isChecked: boolean;
  onChange: () => void;
};

function Checkbox({ label, isChecked, onChange }: Props) {
  return (
    <label className={styles.checkbox}>
      <input type="checkbox" checked={isChecked} onChange={onChange} />
      {label}
    </label>
  );
}

export default Checkbox;
