import axios, { AxiosResponse } from 'axios';
import { getAccessTokenWithLock, logoutUser } from 'utils/jwt';

type Method = 'get' | 'post' | 'put' | 'delete';

type ContentType = 'application/json' | 'multipart/form-data';
export const callApi = async (
  method: Method,
  serviceUrl: string,
  endpoint: string,
  body?: unknown,
  language?: string,
  contentType: ContentType = 'application/json',
) => {
  const accessToken = await getAccessTokenWithLock();

  const httpClient = axios.create({
    baseURL: serviceUrl,
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${accessToken}`,
      'x-current-language': language ?? 'fr',
    },
  });

  httpClient.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.status === 401) {
        logoutUser();
      } else {
        throw error;
      }
    },
  );

  type CallApiParams = {
    url: string;
    requestBody?: unknown;
  };

  const methodToInvokeCallApi: Record<Method, (params: CallApiParams) => Promise<AxiosResponse>> = {
    get: ({ url }) => httpClient.get(url),
    post: ({ url, requestBody }) => httpClient.post(url, requestBody),
    put: ({ url, requestBody }) => httpClient.put(url, requestBody),
    delete: ({ url }) => httpClient.delete(url),
  };

  return methodToInvokeCallApi[method]({ url: endpoint, requestBody: body });
};
