import React, { PropsWithChildren } from 'react';
import clientsItems from 'domain/myClients/stub/clientsItems';
import { GenericSectionItem } from 'types/tools';

function MockProvider({ children }: PropsWithChildren<unknown>) {
  const getClientsItems = () => Promise.resolve(clientsItems);

  return (
    <MockContext.Provider
      value={{
        getClientsItems,
      }}
    >
      {children}
    </MockContext.Provider>
  );
}

function useMockContext() {
  return React.useContext(MockContext);
}

const MockContext = React.createContext<{
  getClientsItems: () => Promise<GenericSectionItem[]>;
}>({
  getClientsItems: () => Promise.resolve([]),
});

export { MockContext, MockProvider, useMockContext };
