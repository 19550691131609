import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import titleStyle from 'styles/components/pages.module.scss';
import style from 'styles/domain/calendar/admin/calendar-event-admin-page.module.scss';
import Button from 'components/inputs/Button';
import useAddEvents from 'domain/calendar/hooks/useAddEvents';
import { MeetInputForm, meetInputFormSchema, PresenceTypeEnum } from 'types/calendar';
import TextField from 'components/forms/TextField';
import SelectField from 'components/forms/SelectField';
import useGetBusinessUnit from 'domain/businessUnit/hooks/useGetBusinessUnit';
import useGetVenues from 'domain/venue/hooks/useGetVenues';
import moment from 'moment';
import DateTimeRangeField from 'components/forms/DateTimeRangeField';
import { getCalendarEventPresences } from 'domain/calendar/components/admin/getCalendarSelectsInformation';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGetEventTypes from 'domain/calendar/hooks/useGetEventTypes';
import TextAreaField from 'components/forms/TextAreaField';
import SingleFileField from 'components/forms/SingleFileField';

const { wrapper, dateWrapper } = style;

function CalendarEventAdminPage() {
  const { t } = useTranslation('admin');

  const { mutate, isLoading } = useAddEvents();

  const { data: businessUnits } = useGetBusinessUnit();

  const allEventTypes = useGetEventTypes();

  const allEventCategories = businessUnits?.map(unit => ({
    value: unit.name,
    label: t(unit.name, { ns: 'common' }),
    publicId: unit.publicId,
  }));

  const allPresenceType = getCalendarEventPresences(t);

  const { data: venues } = useGetVenues();

  const allRegionType = venues?.map(unit => ({
    value: unit.name,
    label: t(unit.name, { ns: 'region' }),
    publicId: unit.publicId,
  }));

  const { control, handleSubmit, watch, resetField } = useForm<MeetInputForm>({
    mode: 'onChange',
    resolver: zodResolver(meetInputFormSchema),
    defaultValues: {
      dateRange: [new Date(), new Date()],
      eventCategory: { value: '', label: '', publicId: '' },
      eventType: { value: '', label: '' },
      presenceType: {
        value: PresenceTypeEnum.IN_PERSON,
        label: t('inPerson', { ns: 'common' }),
      },
      content: '',
      title: '',
      region: { value: '', label: '', publicId: '' },
      eventSrc: '',
      image: undefined as File | undefined,
    },
  });

  const watchSelectedEventType = watch('eventType');

  const isEventTypeContest = watchSelectedEventType.value === 'contest';

  React.useEffect(() => {
    const eventTypeSelected = watchSelectedEventType.value;
    if (eventTypeSelected === 'event') {
      resetField('content');
      resetField('image');
    }
  }, [watchSelectedEventType]);

  const handleAddMeetEvent: SubmitHandler<MeetInputForm> = ({
    dateRange,
    title,
    content,
    eventCategory,
    eventType,
    region,
    eventSrc,
    presenceType,
    image,
  }) => {
    const meet = {
      title,
      scheduledStartDatetimeUtc: moment(dateRange[0], 'YYYY-MM-DD HH:mm:ss').tz('UTC').format(),
      scheduledEndDatetimeUtc: moment(dateRange[1], 'YYYY-MM-DD HH:mm:ss').tz('UTC').format(),
      venueId: region.publicId,
      presenceType: presenceType.value,
      businessUnitIds: [eventCategory.publicId],
      content: content ?? '',
      externalInformationUrl: eventSrc,
      eventType: eventType.value,
      imageUrl: '',
    };
    mutate({ meet, image });
  };

  return (
    <>
      <h1 className={`${titleStyle.title} ${titleStyle.titlePadded}`}>{t('calendarAdminTitle')}</h1>
      <form onSubmit={handleSubmit(i => handleAddMeetEvent(i))} data-testid="eventForm">
        <div className={wrapper}>
          <TextField label={t('title')} control={control} name="title" data-testid="eventText" />
          <div className={dateWrapper}>
            <DateTimeRangeField control={control} name="dateRange" label={`${t('eventDuration')}*`} />
          </div>
          <SelectField label={`${t('type')}*`} options={allEventTypes} control={control} name="eventType" />
          {isEventTypeContest && <TextAreaField control={control} label={`${t('description')}*`} name="content" />}
          <SelectField
            label={`${t('category')}*`}
            options={allEventCategories}
            control={control}
            name="eventCategory"
          />
          <SelectField label={`${t('presence')}*`} options={allPresenceType} control={control} name="presenceType" />
          <SelectField label={`${t('region')}*`} options={allRegionType} control={control} name="region" />
          <TextField label={`${t('link')}*`} control={control} name="eventSrc" data-testid="eventLink" />
          {isEventTypeContest && (
            <SingleFileField
              control={control}
              name="image"
              label={`${t('image')}*`}
              fileDropMessage={t('dragDropMessage', { ns: 'sendToGc' })}
              acceptedFiles=".jpg, .jpeg, .png, .gif"
              recommendedMessage={t('eventImageRecommendation')}
            />
          )}
          <Button type="submit">{isLoading ? <LoadingSpinner isMini isWhite /> : t('add')}</Button>
        </div>
      </form>
    </>
  );
}

export default CalendarEventAdminPage;
