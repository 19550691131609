import * as React from 'react';
import type { Severity } from 'types/severity';
import { Snackbar } from 'components/feedback/Snackbar';
import styles from 'styles/contexts/snackbar-provider.module.scss';

type SnackbarRequest = {
  readonly text: string;
  readonly severity: Severity;
};

type SnackbarRequestWithKey = {
  readonly key: number;
} & SnackbarRequest;

const snackbarDuration = 3000; // ms
const dangerSnackbarDuration = 5000; // ms

function getDuration(request: SnackbarRequest) {
  return request?.severity === 'danger' ? dangerSnackbarDuration : snackbarDuration;
}

function SnackbarProvider({ children }: React.PropsWithChildren<unknown>) {
  const [requests, setRequests] = React.useState<readonly SnackbarRequestWithKey[]>([]);
  const [nextKey, setNextKey] = React.useState(1);

  function showSnackbar(request: SnackbarRequest) {
    setRequests([...requests, { key: nextKey, ...request }]);
    setNextKey(nextKey + 1);
  }

  function removeSnackbar(key: number) {
    setRequests(requests.filter(request => request.key !== key));
  }

  React.useEffect(() => {
    if (requests.length) {
      const requestToRemove = requests[0];
      const timeout = setTimeout(() => removeSnackbar(requestToRemove.key), getDuration(requestToRemove));

      return () => clearTimeout(timeout);
    }
  }, [requests]);

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <div className={styles.container}>
        {children}
        <div className={styles.listContainer}>
          {requests.map(({ key, ...request }) => (
            <Snackbar onClick={() => removeSnackbar(key)} key={key} duration={getDuration(request)} {...request} />
          ))}
        </div>
      </div>
    </SnackbarContext.Provider>
  );
}

const SnackbarContext = React.createContext<{
  readonly showSnackbar: (request: SnackbarRequest) => void;
}>({ showSnackbar: () => void 0 });

export { SnackbarContext };
export default SnackbarProvider;
