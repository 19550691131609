import React, { createContext, memo, ReactNode, useContext, useMemo } from 'react';
import { signIn } from 'domain/auth/api/signIn';
import useLocalStorage from 'hooks/useLocalStorage/useLocalStorage';
import { getUserRoleFromToken } from 'utils/jwt';
import { UserRole } from 'types/auth';

type AuthState = {
  accessToken: string;
  refreshToken: string;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  userRole?: UserRole;
};

type Props = {
  children: ReactNode;
};

function AuthProvider({ children }: Props) {
  const [accessToken, setAccessToken] = useLocalStorage('accessToken');
  const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken');

  const handleSignIn = async (email: string, password: string) => {
    const tokenResponse = await signIn(email, password);
    setAccessToken(tokenResponse.accessToken);
    setRefreshToken(tokenResponse.refreshToken);
  };

  async function handleSignOut() {
    setAccessToken(null);
  }

  const userRole = useMemo(() => {
    return accessToken ? getUserRoleFromToken(accessToken) : undefined;
  }, [accessToken]);

  const value = { accessToken, refreshToken, signIn: handleSignIn, signOut: handleSignOut, userRole };
  const { Provider } = AuthContext;

  return <Provider value={value}>{children}</Provider>;
}

const AuthContext = createContext<AuthState>({} as AuthState);

export function useAuthContext() {
  return useContext(AuthContext);
}

export default memo(AuthProvider);
