import { z } from 'zod';

export const venueSchema = z.object({
  publicId: z.string().uuid(),
  concurrencyId: z.string(),
  isVisible: z.boolean().optional(),
  name: z.string(),
  description: z.string(),
});

export type Venue = z.infer<typeof venueSchema>;
