import { useQuery, UseQueryResult } from 'react-query';
import getClassificationType from 'domain/search/api/getClassificationType';
import { ClassificationType } from 'types/classification';

export const classificationQueryKey = 'classification';

function useGetClassificationType(language: string): UseQueryResult<ClassificationType> {
  return useQuery([classificationQueryKey, language], async () => getClassificationType(language));
}

export default useGetClassificationType;
