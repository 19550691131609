import { GenericSectionItem } from 'types/tools';
import complianceImg from 'assets/img/genericItem/icon-conformité.png';
import businessDevelopmentImg from 'assets/img/genericItem/icon-developpement-affaires.png';
import businessPracticesImg from 'assets/img/genericItem/icon-pratique-affaires.png';
import contractManagementImg from 'assets/img/genericItem/icon-gestion-contractuelle.png';

const compliance: GenericSectionItem = {
  title: 'compliance',
  subSections: [
    { title: 'insurance', documentsSectionKey: 'compliance_insurance' },
    { title: 'investments', documentsSectionKey: 'compliance_investments' },
    { title: 'ufcCenter', documentsSectionKey: 'compliance_ufcCenter' },
    { title: 'organizations', documentsSectionKey: 'compliance_organizations' },
  ],
  image: complianceImg,
};

const businessDevelopment: GenericSectionItem = {
  title: 'businessDevelopment',
  subSections: [{ title: 'sellingTools', documentsSectionKey: 'businessDevelopment_sellingTools' }],
  image: businessDevelopmentImg,
};

const businessPractices: GenericSectionItem = {
  title: 'businessPractices',
  subSections: [
    { title: 'businessContinuity', documentsSectionKey: 'businessPractices_businessContinuity' },
    { title: 'technologicalSuite', documentsSectionKey: 'businessPractices_technologicalSuite' },
    { title: 'yourMarketing', documentsSectionKey: 'businessPractices_yourMarketing' },
    { title: 'general', documentsSectionKey: 'businessPractices_general' },
  ],
  image: businessPracticesImg,
};

const contractManagement: GenericSectionItem = {
  title: 'contractManagement',
  subSections: [{ title: 'remuneration', documentsSectionKey: 'contractManagement_remuneration' }],
  image: contractManagementImg,
};

const companyItems = [compliance, businessDevelopment, businessPractices, contractManagement];

export default companyItems;
