import common from 'locales/fr/common.json';
import admin from 'locales/fr/pages/admin.json';
import navigation from 'locales/fr/navigation.json';
import home from 'locales/fr/pages/home.json';
import company from 'locales/fr/pages/company.json';
import transactions from 'locales/fr/pages/transactions.json';
import support from 'locales/fr/pages/support.json';
import genericItem from 'locales/fr/pages/genericItem.json';
import myClients from 'locales/fr/pages/myClients.json';
import calendar from 'locales/fr/pages/calendar.json';
import sendToGc from 'locales/fr/pages/sendToGc.json';
import auth from 'locales/fr/auth.json';
import financialCenter from 'locales/fr/financialCenter.json';
import forms from 'locales/fr/forms.json';
import profile from 'locales/fr/pages/profile.json';
import search from 'locales/fr/search.json';
import crmDemand from 'locales/fr/crmValuesDemandType.json';
import crmTransaction from 'locales/fr/crmValuesTransactionType.json';

export const resources = {
  common,
  navigation,
  home,
  company,
  transactions,
  support,
  genericItem,
  myClients,
  calendar,
  sendToGc,
  auth,
  admin,
  financialCenter,
  forms,
  profile,
  search,
  crmDemand,
  crmTransaction,
} as const;
