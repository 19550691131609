import React, { ReactElement, useState } from 'react';
import styles from 'styles/domain/support/tabs.module.scss';

export type TabsType = {
  label: string;
  index: number;
  Component: ReactElement;
};

type Props = {
  tabs: TabsType[];
  isTabsLeftAligned?: boolean;
  selectedTabIndex?: number;
  noBorder?: boolean;
};

function Tabs({ tabs = [], isTabsLeftAligned, selectedTabIndex, noBorder = false }: Props) {
  const [selectedTab, setSelectedTab] = useState(selectedTabIndex ?? 0);
  const { Component } = tabs[selectedTab];
  const { tabsList, tabsListLeftAligned, tabsNoBorder, activeButton, tabButton } = styles;

  return (
    <div>
      <div className={`${tabsList} ${isTabsLeftAligned ? tabsListLeftAligned : ''}  ${noBorder ? tabsNoBorder : ''}`}>
        {tabs.map(({ index, label }) => (
          <button
            className={`${tabButton} ${selectedTab === index ? activeButton : ''}`}
            onClick={() => setSelectedTab(index)}
            key={index}
          >
            {label}
          </button>
        ))}
      </div>
      {Component}
    </div>
  );
}

export default Tabs;
