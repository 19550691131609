import { useMutation, useQueryClient } from 'react-query';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { useTranslation } from 'react-i18next';
import editBidAction from 'domain/sendToGc/api/editBidAction';

function useEditBidAction(reset: () => void) {
  const { t } = useTranslation('admin');
  const { showSnackbar } = useSnackbarContext();
  const queryClient = useQueryClient();

  return useMutation(editBidAction, {
    onSuccess: () => {
      reset();
      queryClient.invalidateQueries(['getBidActions']);
      showSnackbar({ text: t('editBidActionMessage'), severity: 'success' });
    },
    onError: () => showSnackbar({ text: t('failedToEditBidActionMessage'), severity: 'danger' }),
  });
}

export default useEditBidAction;
