import React from 'react';

import { ReactComponent as ArrowBackCircle } from 'assets/icons/arrow-back-circle.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/company-icon.svg';
import { ReactComponent as ClientIcon } from 'assets/icons/client-icon.svg';
import { ReactComponent as ContactUsIcon } from 'assets/icons/contactus-icon.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder-icon.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye-icon.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon.svg';
import { ReactComponent as ToolboxIcon } from 'assets/icons/toolbox-icon.svg';
import { ReactComponent as TransactionIcon } from 'assets/icons/transaction-icon.svg';
import { ReactComponent as NotificationsIcon } from 'assets/icons/notifications-icon.svg';
import { ReactComponent as NotificationsIconMobile } from 'assets/icons/notifications-icon-mobile.svg';
import { ReactComponent as DollarSignIcon } from 'assets/icons/dollar-sign-icon.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/right-arrow-icon.svg';
import { ReactComponent as RightArrowGreenIcon } from 'assets/icons/right-arrow-green-icon.svg';
import { ReactComponent as RecentIconMobile } from 'assets/icons/recent-icon-mobile.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf-icon.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link-icon.svg';
import { ReactComponent as FormIcon } from 'assets/icons/form-icon.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';
import { ReactComponent as StarMinus } from 'assets/icons/favorite-minus-icon.svg';
import { ReactComponent as StarPlus } from 'assets/icons/favorite-plus-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-icon.svg';
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite-icon.svg';
import { ReactComponent as FavoriteIconMobile } from 'assets/icons/favorite-icon-mobile.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow-icon.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email-icon.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone-icon.svg';
import { ReactComponent as UpCaretIcon } from 'assets/icons/up-caret-icon.svg';
import { ReactComponent as DownCaretIcon } from 'assets/icons/down-caret-icon.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg';
import { ReactComponent as CollapseMenuIcon } from 'assets/icons/collapse-menu-icon.svg';
import { ReactComponent as OpenMenuIcon } from 'assets/icons/open-menu-icon.svg';
import { ReactComponent as CloseMenuMobileIcon } from 'assets/icons/close-menu-mobile-icon.svg';
import { ReactComponent as HamburgerMobileIcon } from 'assets/icons/hamburger-mobile-icon.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout-icon.svg';
import { ReactComponent as LogoutMobileIcon } from 'assets/icons/logout-icon-mobile.svg';
import { ReactComponent as PhoneWhiteIcon } from 'assets/icons/phone-icon-white.svg';
import { ReactComponent as EmailWhiteIcon } from 'assets/icons/email-icon-white.svg';
import { ReactComponent as FaxIcon } from 'assets/icons/fax-icon.svg';
import { ReactComponent as FaxWhiteIcon } from 'assets/icons/fax-icon-white.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-icon.svg';
import { ReactComponent as SuccessSeverityIcon } from 'assets/icons/success-severity-icon.svg';
import { ReactComponent as WarningSeverityIcon } from 'assets/icons/warning-severity-icon.svg';
import { ReactComponent as DangerSeverityIcon } from 'assets/icons/danger-severity-icon.svg';
import { ReactComponent as InfoSeverityIcon } from 'assets/icons/info-severity-icon.svg';

type IconName =
  | 'arrowBackCircle'
  | 'eye'
  | 'company'
  | 'clients'
  | 'folders'
  | 'toolbox'
  | 'transaction'
  | 'contactUs'
  | 'calendar'
  | 'notifications'
  | 'notificationsMobile'
  | 'dollarSign'
  | 'rightArrow'
  | 'rightArrowGreen'
  | 'pdf'
  | 'link'
  | 'form'
  | 'remove'
  | 'recentIconMobile'
  | 'download'
  | 'starMinus'
  | 'starPlus'
  | 'plus'
  | 'favorite'
  | 'favoriteMobile'
  | 'leftArrow'
  | 'email'
  | 'emailWhite'
  | 'phone'
  | 'phoneWhite'
  | 'upCaret'
  | 'downCaret'
  | 'warning'
  | 'openMenu'
  | 'collapseMenu'
  | 'mobileHamburger'
  | 'closeMobileMenu'
  | 'logout'
  | 'logoutMobile'
  | 'fax'
  | 'faxWhite'
  | 'filter'
  | 'successSeverity'
  | 'warningSeverity'
  | 'dangerSeverity'
  | 'infoSeverity';

const nameToIcon: Record<IconName, React.FC> = {
  fax: FaxIcon,
  faxWhite: FaxWhiteIcon,
  emailWhite: EmailWhiteIcon,
  phoneWhite: PhoneWhiteIcon,
  arrowBackCircle: ArrowBackCircle,
  eye: EyeIcon,
  remove: RemoveIcon,
  form: FormIcon,
  link: LinkIcon,
  pdf: PdfIcon,
  company: CompanyIcon,
  clients: ClientIcon,
  folders: FolderIcon,
  toolbox: ToolboxIcon,
  transaction: TransactionIcon,
  contactUs: ContactUsIcon,
  calendar: CalendarIcon,
  notifications: NotificationsIcon,
  notificationsMobile: NotificationsIconMobile,
  dollarSign: DollarSignIcon,
  rightArrow: RightArrowIcon,
  rightArrowGreen: RightArrowGreenIcon,
  recentIconMobile: RecentIconMobile,
  download: DownloadIcon,
  starMinus: StarMinus,
  starPlus: StarPlus,
  plus: PlusIcon,
  favorite: FavoriteIcon,
  favoriteMobile: FavoriteIconMobile,
  leftArrow: LeftArrowIcon,
  email: EmailIcon,
  phone: PhoneIcon,
  downCaret: DownCaretIcon,
  upCaret: UpCaretIcon,
  warning: WarningIcon,
  openMenu: OpenMenuIcon,
  collapseMenu: CollapseMenuIcon,
  mobileHamburger: HamburgerMobileIcon,
  closeMobileMenu: CloseMenuMobileIcon,
  logout: LogoutIcon,
  logoutMobile: LogoutMobileIcon,
  filter: FilterIcon,
  successSeverity: SuccessSeverityIcon,
  warningSeverity: WarningSeverityIcon,
  dangerSeverity: DangerSeverityIcon,
  infoSeverity: InfoSeverityIcon,
};

type Props = {
  name: IconName;
};

function Icon({ name }: Props) {
  const IconForName = nameToIcon[name];
  return <IconForName />;
}

export default Icon;
