import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import addNote from 'domain/alert/api/addNote';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { noteQueryKey } from 'domain/alert/hooks/useGetNotes';

function useAddNote() {
  const queryClient = useQueryClient();
  const { t } = useTranslation('admin');
  const { showSnackbar } = useSnackbarContext();

  return useMutation(addNote, {
    onSuccess: () => {
      showSnackbar({ text: t('addNoteMessage'), severity: 'success' });
      return queryClient.invalidateQueries([noteQueryKey]);
    },
    onError: () => showSnackbar({ text: t('failedToAddNoteMessage'), severity: 'danger' }),
  });
}

export default useAddNote;
