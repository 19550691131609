import { useMutation } from 'react-query';
import sendFileToGc from 'domain/sendToGc/api/sendFileToGc';
import { useTranslation } from 'react-i18next';
import { useShowOutputMessage } from 'hooks/useShowOutputMessage';

function useSendFilesToGc(resetFormCallBack: (() => unknown) | undefined) {
  const { t } = useTranslation('sendToGc');
  const { showConfirmationModal } = useShowOutputMessage();

  return useMutation(sendFileToGc, {
    onSuccess: () =>
      showConfirmationModal({
        text: t('sendDocumentSuccessMessage'),
        severity: 'success',
        note: t('sendDocumentSuccessHeader'),
        onSubmit: resetFormCallBack,
        submitText: t('submitText'),
      }),
    onError: () =>
      showConfirmationModal({ text: t('sendDocumentErrorMessage'), severity: 'danger', submitText: t('submitText') }),
  });
}

export default useSendFilesToGc;
