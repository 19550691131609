export enum FinancialCenter {
  QUEBEC = 'quebec',
  MONTREAL = 'montreal',
  SHERBROOKE = 'sherbrooke',
  TROIS_RIVIERES = 'troisRivieres',
  OUTAOUAIS = 'outaouais',
  ST_HYACINTHE = 'stHyacinthe',
}

type FinancialCenterInformation = {
  directionUrl: string;
  address: string;
  city: string;
};

const montrealAddress: FinancialCenterInformation = {
  address: '5125, rue du Trianon, bur. 210',
  city: 'Montréal (Québec) H1M 2S5',
  directionUrl:
    'https://www.google.com/maps/dir//5125+Rue+du+Trianon,+Montr%C3%A9al,+QC+H1M+2S5/@45.7232977,-73.2877706,10z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc91dd2f8a73507:0xe80152de7004650a!2m2!1d-73.542741!2d45.591703!3e0',
};

const stHyacintheAddress: FinancialCenterInformation = {
  address: '4860 Jacques-Cartier',
  city: 'Saint-Hyacinthe (Québec) J2S 8A3',
  directionUrl:
    'https://www.google.com/maps/dir//4860+Rue+Jacques-Cartier,+Saint-Hyacinthe,+QC+J2S+8A3/@45.6046603,-73.0071791,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc84dc14f632593:0x75b4f1c38b59484e!2m2!1d-72.9721597!2d45.6046056!3e0',
};

const outaouaisAddress: FinancialCenterInformation = {
  address: '492 boulevard de l’Hôpital, bur. 100',
  city: 'Gatineau (Québec) J8V 2P4',
  directionUrl:
    "https://www.google.ca/maps/dir//492+Boulevard+de+l'H%C3%B4pital,+Gatineau,+QC/@45.5850579,-75.2164856,8z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cce1b7371565705:0x758c9ae31ad5e85e!2m2!1d-75.6885877!2d45.4926035!3e0",
};

const quebecAddress: FinancialCenterInformation = {
  address: '1165, boulevard Lebourgneuf, bur. 200',
  city: 'Québec (Québec) G2K 2C9',
  directionUrl:
    "https://www.google.ca/maps/dir/''/1165+Boulevard+Lebourgneuf,+Qu%C3%A9bec,+QC+G2K+2C9/@46.8355591,-71.2934655,16.68z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4cb897e960cb92d3:0xd1f81b6230e26c75!2m2!1d-71.2918385!2d46.8356601",
};

const sherbrookeAddress: FinancialCenterInformation = {
  address: '2144, rue King Ouest, bur. 110',
  city: 'Sherbrooke (Québec) J1J 2E8',
  directionUrl:
    "https://www.google.ca/maps/dir/''/2144+Rue+King+Ouest,+Sherbrooke,+QC/@45.3978291,-71.9608221,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4cb7b360cb31b543:0xd166233dd6c60ccd!2m2!1d-71.9258028!2d45.3978345",
};

const troisRivieresAddress: FinancialCenterInformation = {
  address: '1720, rue de la Sidbec Sud',
  city: 'Trois-Rivières (Québec) G8Z 4H1',
  directionUrl:
    'https://www.google.com/maps/place/Groupe+Cloutier/@46.3322318,-72.5920934,15.25z/data=!4m6!3m5!1s0x4cc7cf39af5bc4ed:0xcbcaa4c91de91846!8m2!3d46.3319997!4d-72.586468!16s%2Fg%2F1tr8l5gh',
};

export const financialCenterInformations: Record<FinancialCenter, FinancialCenterInformation> = {
  montreal: montrealAddress,
  quebec: quebecAddress,
  stHyacinthe: stHyacintheAddress,
  sherbrooke: sherbrookeAddress,
  outaouais: outaouaisAddress,
  troisRivieres: troisRivieresAddress,
};
