import { callApi } from 'utils/httpClient';
import { MeetEvent } from 'types/calendar';
import addImage from 'api/addImage';

const wcmServiceUrl = `${process.env.REACT_APP_WCM_SERVICE_URL}`;

type MeetWithImg = {
  meet: MeetEvent;
  image?: File;
};

async function addMeet({ meet, image }: MeetWithImg) {
  return image
    ? callApi('post', wcmServiceUrl, 'meets', { ...meet, imageUrl: await addImage(image) })
    : callApi('post', wcmServiceUrl, 'meets', meet);
}

export default addMeet;
