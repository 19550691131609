import { z } from 'zod';
import { WarningLevel } from 'components/shared/WarningBar';
import { Errors } from 'types/errors';

const WarningLevelEnum = z.nativeEnum(WarningLevel);

export const noteSchema = z.object({
  publicId: z.string().uuid().optional(),
  concurrencyId: z.string().optional(),
  expirationDatetimeUtc: z.string(),
  externalInformationUrl: z.string(),
  isVisible: z.boolean(),
  title: z.string(),
  content: z.string(WarningLevelEnum),
});

export type Note = z.infer<typeof noteSchema>;

const warningTypeSchema = z.object({ value: z.string(), label: z.string() });

export const alertAdminInputSchema = z.object({
  title: z.string().min(1, Errors.REQUIRED),
  warningType: warningTypeSchema,
  link: z.string().optional(),
  date: z.date().min(new Date(), Errors.REQUIRED),
});

export type AlertAdminInputSchema = z.infer<typeof alertAdminInputSchema>;
