import { ErrorMessage } from 'components/typography/ErrorMessage';
import RequiredMarker from 'components/typography/RequiredMarker';
import React from 'react';
import styles from 'styles/components/inputs/checkbox-input.module.scss';

type CheckboxInputProps = {
  id: string;
  label: string;
  errorMessage?: string;
  isRequired?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const CheckboxInput = React.forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ id, label, errorMessage, isRequired, ...props }, ref) => {
    return (
      <div>
        <input id={id} type="checkbox" className={styles.input} ref={ref} {...props} />
        <label htmlFor={id} className={styles.label}>
          {label} <RequiredMarker isRequired={isRequired} />
        </label>
        <ErrorMessage errorMessage={errorMessage} />
      </div>
    );
  },
);

export type { CheckboxInputProps };
export default CheckboxInput;
