import { z } from 'zod';

export const userProfileSchema = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  address: z.string(),
  zipCode: z.string(),
  phoneNumber: z.string(),
  state: z.string(),
  city: z.string(),
});

export type UserProfile = z.infer<typeof userProfileSchema>;

export const userProfileAvatarSchema = z.object({
  contentAsBase64: z.string(),
  mimeType: z.string(),
});

export type UserProfileAvatar = z.infer<typeof userProfileAvatarSchema>;
