import React, { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';

type Props = {
  children: ReactNode;
  to: To;
  className?: string;
  param?: unknown;
};

function RouterLink({ children, to, className, param }: Props) {
  return (
    <Link to={to} state={param} className={className}>
      {children}
    </Link>
  );
}

export default RouterLink;
