import React from 'react';
import styles from 'styles/components/navigation/search-filter.module.scss';
import Checkbox from 'components/shared/CheckBox';
import { useTranslation } from 'react-i18next';
import ResetAllFiltersButton from 'domain/search/components/ResetAllFiltersButton';
import { useFilterContext } from 'domain/search/contexts/FilterProvider';
import { SearchFilterType } from 'types/classification';

type Props = {
  title: string;
  filters: SearchFilterType[];
  onSelectFilter: (filter: SearchFilterType) => void;
};

function SearchFilterLanguage({ title, filters, onSelectFilter }: Props) {
  const { t } = useTranslation('common');
  const { item, titleStyle, checkWrap, inputWrapper } = styles;

  const { getCheckedFilters } = useFilterContext();

  const onChange = (filter: SearchFilterType) => {
    onSelectFilter(filter);
  };

  return (
    <div className={item}>
      <h2 className={titleStyle}>{title}</h2>
      <div className={checkWrap}>
        {filters.map((lang, i) => (
          <div className={inputWrapper} key={i}>
            <Checkbox label={t(lang.key.toLowerCase())} isChecked={lang.isChecked} onChange={() => onChange(lang)} />
          </div>
        ))}
      </div>
      {getCheckedFilters().length > 1 && <ResetAllFiltersButton />}
    </div>
  );
}

export default SearchFilterLanguage;
