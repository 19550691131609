import React from 'react';
import styles from 'styles/components/inputs/file-input.module.scss';
import FileDropMessage from 'components/inputs/fileInput/FileDropMessage';
import AddedFileList from 'components/inputs/fileInput/AddedFileList';
import { Label } from 'components/typography/Label';
import RequiredMarker from 'components/typography/RequiredMarker';

type SingleFileInputProps = {
  label: string;
  errorMessage?: string;
  isRequired?: boolean;
  fileDropMessage: string;
  value?: File;
  onChange: (file: File | undefined) => unknown;
  acceptedFiles?: string;
  recommendedMessage?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;

const SingleFileInput = React.forwardRef<HTMLInputElement, SingleFileInputProps>(
  (
    {
      label,
      errorMessage,
      isRequired,
      fileDropMessage,
      value,
      onChange,
      acceptedFiles = '.pdf, .docx',
      recommendedMessage,
      ...props
    },
    ref,
  ) => {
    function addFiles(files: FileList | null) {
      if (files?.length) {
        onChange(files[0]);
      }
    }

    function removeFile() {
      onChange(undefined);
    }

    return (
      <div className={styles.wrapper}>
        <Label>
          {label} <RequiredMarker isRequired={isRequired} />
        </Label>
        <div className={`${styles.inputWrapper} ${errorMessage ? styles.invalid : ''}`}>
          <input
            type="file"
            className={styles.input}
            onChange={e => addFiles(e.target.files)}
            accept={acceptedFiles}
            ref={ref}
            {...props}
          />
          <div className={styles.filesWrapper}>
            {!!value && <AddedFileList files={[value]} onRemove={removeFile} />}
            <FileDropMessage message={fileDropMessage} errorMessage={errorMessage} />
          </div>
        </div>
        {recommendedMessage && <p className={styles.recommendedMsg}>{recommendedMessage}</p>}
      </div>
    );
  },
);

SingleFileInput.displayName = 'SingleFileInput';

export type { SingleFileInputProps };
export default SingleFileInput;
