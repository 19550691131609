import * as React from 'react';
import type { Severity } from 'types/severity';
import styles from 'styles/components/feedback/alert.module.scss';
import Icon from 'components/icons/Icon';

type AlertProps = {
  readonly text: string;
  readonly severity: Severity;
  readonly removable?: boolean;
};

const severityToContainerStyles: Record<Severity, string> = {
  success: styles.container__success,
  info: styles.container__info,
  warning: styles.container__warning,
  danger: styles.container__danger,
};

function Alert({ text, severity, removable = false }: AlertProps) {
  return (
    <div className={`${styles.container} ${severityToContainerStyles[severity]}`}>
      <span className={styles.text}>{text}</span>
      {removable && <Icon name="remove" />}
    </div>
  );
}

export type { AlertProps };
export { Alert };
