import React, { useMemo, useState } from 'react';
import { Ressource } from 'types/ressources';
import styles from 'styles/domain/support/ressource-card.module.scss';
import Icon from 'components/icons/Icon';
import isMobile from 'utils/isMobile';
import useDeleteRessource from 'domain/support/hooks/useDeleteRessource';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { useAuthContext } from 'domain/auth/contexts/AuthProvider';
import { UserRole } from 'types/auth';
import { useTranslation } from 'react-i18next';

type Props = {
  ressource: Ressource;
  isWhite: boolean;
};

function RessourceCard({ ressource, isWhite }: Props) {
  const [hovered, setHovered] = useState(false);
  const { userRole } = useAuthContext();
  const { t } = useTranslation('support');

  const { phone, firstname, title, email, lastname, publicId, description, financialCenterKey, fax, tollFreePhone } =
    ressource;

  const { mutate: deleteRessource, isLoading } = useDeleteRessource(financialCenterKey);
  const showButtonGroup = useMemo(() => (hovered || isMobile()) && userRole === UserRole.ADMIN, [hovered]);

  const { container, nameText, infoContainer, whiteContainer, removeButton, nameContainer } = styles;

  return (
    <div
      className={`${isWhite ? whiteContainer : container}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={nameContainer}>
        <p className={nameText}>{`${firstname} ${lastname}`}</p>
        {showButtonGroup && (
          <button onClick={() => deleteRessource(publicId)} className={removeButton}>
            {isLoading ? <LoadingSpinner isMini={true} /> : <Icon name={'remove'} />}
          </button>
        )}
      </div>
      {title && <p>{title}</p>}
      <div className={infoContainer}>{description && <p>{description}</p>}</div>
      {email && (
        <div className={infoContainer}>
          <Icon name={`${isWhite ? 'emailWhite' : 'email'}`} />
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      )}

      {phone && (
        <div className={infoContainer}>
          <Icon name={`${isWhite ? 'phoneWhite' : 'phone'}`} />
          <a href={`tel:${phone}`}>{phone}</a>
        </div>
      )}

      {tollFreePhone && (
        <div className={infoContainer}>
          <Icon name={`${isWhite ? 'phoneWhite' : 'phone'}`} />
          <a href={`tel:${tollFreePhone}`}>{`${tollFreePhone} (${t('tollFree')})`}</a>
        </div>
      )}

      {fax && (
        <div className={infoContainer}>
          <Icon name={`${isWhite ? 'faxWhite' : 'fax'}`} />
          <a href={`tel:${fax}`}>{fax}</a>
        </div>
      )}
    </div>
  );
}

export default RessourceCard;
