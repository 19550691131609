import { useQuery, UseQueryResult } from 'react-query';
import { EquisoftType } from 'types/equisoftType';
import getEquisoftType from '../api/getEquisoftType';
import { useTranslation } from 'react-i18next';
import { useShowConfirmationModal } from 'hooks/useShowConfirmationModal';

function useGetEquisoftType(): UseQueryResult<EquisoftType> {
  const { t } = useTranslation('profile');
const { showConfirmationModal } = useShowConfirmationModal();
  return useQuery('equisoftType',getEquisoftType, {
    onError: () =>
      showConfirmationModal({
        message: t('errorEquisoftMessage'),
        title: t('errorEquisoftTitle'),
      })
  });
}
export default useGetEquisoftType;