import titleStyle from 'styles/components/pages.module.scss';
import styles from 'styles/domain/sendToGc/admin/send-to-gc-admin.module.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SendToGcAdminTable from 'domain/sendToGc/components/admin/SendToGcAdminTable';

function SendToGcAdminPage() {
  const { t } = useTranslation('admin');

  const { wrapper } = styles;

  return (
    <>
      <h1 className={`${titleStyle.title} ${titleStyle.titlePadded}`}>{t('sendToGcAdminTitle')}</h1>
      <div className={wrapper}>
        <SendToGcAdminTable />
      </div>
    </>
  );
}

export default SendToGcAdminPage;
