import React from 'react'
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import useGetErrorMessage from 'components/forms/hooks/useGetErrorMessage';
import CheckboxInput, { CheckboxInputProps } from 'components/inputs/CheckboxInput';

type CheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = CheckboxInputProps & UseControllerProps<TFieldValues, TName>;

function CheckBoxField<
TFieldValues extends FieldValues = FieldValues,
TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, ...props }: CheckboxProps<TFieldValues, TName>) {
    const {
        field,
        fieldState: { error },
      } = useController({ control, name });
      const getErrorMessage = useGetErrorMessage(props.label);
      const errorMessage = error ? getErrorMessage(error.message) : undefined;
  return (
    <CheckboxInput errorMessage={errorMessage} {...field} {...props} defaultChecked={field.value} />
  )
}

export default CheckBoxField;
