import axios from 'axios';
import { AuthTokens, authTokensSchema } from 'types/auth';

const endpoint = `${process.env.REACT_APP_SECURITY_SERVICE_URL}/Connect/login`;

export async function signIn(email: string, password: string): Promise<AuthTokens> {
  const body = {
    email: email,
    password: password,
  };
  const { data } = await axios.post(endpoint, body);
  return authTokensSchema.parse(data);
}
