import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbarContext } from 'hooks/useSnackbarContext';
import { ressourcesQueryKey } from 'domain/support/hooks/useGetRessources';
import { FinancialCenter } from 'types/FinancialCenter';
import addRessource from 'domain/support/api/addRessource';

function useAddRessource(financialCenterKey: FinancialCenter) {
  const { t } = useTranslation('support');
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbarContext();

  return useMutation(addRessource, {
    onSuccess: () => {
      showSnackbar({ text: t('addRessourceMessage'), severity: 'success' });
      return queryClient.invalidateQueries([`${ressourcesQueryKey}${financialCenterKey}`]);
    },
    onError: () => showSnackbar({ text: t('failedToAddRessourceMessage'), severity: 'danger' }),
  });
}

export default useAddRessource;
