import { callApi } from 'utils/httpClient';

const wcmServiceUrl = `${process.env.REACT_APP_WCM_SERVICE_URL}`;

async function addImage(image: File) {
  const assetImg = {
    title: image.name,
    filename: image.name,
    assetType: 'img',
    sectionKey: 'newsFile',
  };

  const { data: img } = await callApi('post', wcmServiceUrl, 'asset', assetImg);

  const bodyFormData = new FormData();
  bodyFormData.append('image', image);

  const { data: contentRes } = await callApi(
    'put',
    wcmServiceUrl,
    `asset/${img?.publicId}/Content`,
    bodyFormData,
    'multipart/form-data',
  );

  return contentRes.storageUrl;
}

export default addImage;
